import React from "react";

export interface CircleButtonProps {
    className?: string;
    iconClassName?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    variant?: string;
    disabled?: boolean;
    iconVariant?: string;
}

export default function CircleButton({
    iconVariant = "white",
    className = "", iconClassName, onClick = () => { }, variant, ...props }: CircleButtonProps) {
    return (
        <div onClick={props.disabled ? undefined : onClick} className={"CircleButton fa-stack " + className} style={{
            maxWidth: "2em",
        }}   {...props} >
            <style>{`
            .CircleButton_icon{
                transition: all 0.2s;
            }
                .CircleButton:hover .CircleButton_icon{
                    font-size: 110%;
                }
            `}</style>
            <i className={`CircleButton_circle fas fa-circle fa-stack-2x ${variant ? "text-" + variant : "text-primary"}`} />
            <i className={`CircleButton_icon text-${iconVariant} ${iconClassName} fa-stack-1x  `} />
        </div>);
}
