import Loader from 'components/Loader';
import { t } from 'i18next';
import { Order } from 'models/orderModel';
import ExpertEditor from 'pages/experts_manager/ExpertEditor';
import React, { FormEvent, useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useCompanyManagerStore } from 'stores/companyManagerStore';
import { useDocumentStore } from 'stores/documentStore';
import { useExpertsStore } from 'stores/expertsStore';
import { useI18nStore } from 'stores/i18nStore';
import { useOrderStore } from 'stores/orderStore';
import { useUsersStore } from 'stores/usersStore';
import { useUserStore } from 'stores/userStore';
import { generateImageUrl } from 'utils/functions';
function stringToUniqueNumber(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}
function OrdersManagerPage() {
  const orders = useOrderStore(state => state.orders);
  const ordersList = useOrderStore((state) => state.orders);
  const initOrders = useOrderStore((state) => state.fetchOrders);
  const [ordersListToShow, setOrdersListToShow] = useState(ordersList);
  const { t } = useTranslation();
  const formRef = React.createRef<HTMLFormElement>();
  useEffect(() => {
    if (ordersList === null) {
      initOrders();
    } else {
      setOrdersListToShow(ordersList);
    }
  }, [ordersList, initOrders]);

  useEffect(() => {
    useOrderStore.getState().fetchOrders();
    useUsersStore.getState().initUsers();
    useExpertsStore.getState().initExpertsStore();
    useCompanyManagerStore.getState().init();
  }, []);

  if (ordersList === null || ordersList === undefined) return (<Loader />);

  const searchOrders = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
/*     const orderId = (event.currentTarget.elements.namedItem('inputOrderId') as HTMLInputElement).value;
 */    const customerName = (event.currentTarget.elements.namedItem('inputCustomerName') as HTMLInputElement).value;
    const orderDate = (event.currentTarget.elements.namedItem('inputOrderDate') as HTMLInputElement).value;
    const orderDateLastUpdate = (event.currentTarget.elements.namedItem('inputOrderDateLastUpdate') as HTMLInputElement).value;

    const filter: {
      [key: string]: string | undefined;
      orderId?: string,
      customerName?: string,
      orderDate?: string,
    } = {};
/*     if (orderId !== '') filter.orderId = orderId;
 */    if (customerName !== '') filter.customerName = customerName;
    if (orderDate !== '') filter.orderDate = new Date(orderDate).toISOString();
    if (orderDateLastUpdate !== '') filter.dateLastUpdate = new Date(orderDateLastUpdate).toISOString();

    const filteredOrders = (ordersList ?? []).filter((order) => {
      for (const key in filter) {
        if (key === 'orderCreationDate' || key === 'dateLastUpdate') {
          if (new Date(order.orderDetail[key] ?? "").getTime() < new Date((filter[key] as string) ?? "").getTime()) return false;
        } else {
          if (key === 'customerName') {
            // check for name and surname in user profile
            const user = useUsersStore.getState().usersList?.find(user => user.userProfileId === order.orderDetail.userProfileId);
            if (!user) return false;
            if (!(user.userProfileDetail.name + ' ' + user.userProfileDetail.surname).toLowerCase().includes((filter[key] as string).toLowerCase())) return false;
          } else
            if (!(order.orderDetail[key] ?? "").toLowerCase().includes((filter[key] as string).toLowerCase())) return false;
        }
      }
      return true;
    });

    setOrdersListToShow(filteredOrders);
  };

  const clearSearch = () => {
    formRef.current?.reset();
    setOrdersListToShow(ordersList);
  }
  return (
    <section className='ExpertsPage d-flex flex-column'>
      {/* Search form */}
      <div className="card mb-3">
        <div className="card-body">
          <form className="row g-3" onSubmit={searchOrders} ref={formRef}>
            {/*             <div className="col-md-4">
              <label htmlFor="inputOrderId" className="form-label">Order ID</label>
              <input type="text" className="form-control" id="inputOrderId" name="inputOrderId" />
            </div> */}
            <div className="col-md-4">
              <label htmlFor="inputCustomerName" className="form-label">{t('forms:name')}</label>
              <input type="text" className="form-control" id="inputCustomerName" name="inputCustomerName" />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputOrderDate" className="form-label">{t('common:creation_date')}</label>
              <input type="date" className="form-control" id="inputOrderDate" name="inputOrderDate" />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputOrderDateLastUpdate" className="form-label">{t('common:update_date')}</label>
              <input type="date" className="form-control" id="inputOrderDateLastUpdate" name="inputOrderDateLastUpdate" />
            </div>


            <div className="col-12 d-flex justify-content-between">
              <button type="submit" className="btn btn-primary">
                {t('common:search')}
              </button>
              {(ordersListToShow ?? []).length !== ordersList.length && (
                <button type="button" className="btn btn-secondary" onClick={clearSearch}>
                  {t('common:clear')}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/*                       <button onClick={() => {
              useExpertsStore.getState().createDefaultExperts();
          }}>CARICA</button> */}
      {
        ordersListToShow === null ? <Loader /> : ordersListToShow.length === 0 ? <div className='text-center'>{t('experts_manager:no_experts')}<br></br>
        </div> :
          <div className=' mb-4 card'>
            <div className="card-body d-flex flex-column">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>N° ORDINE</th>
                    <th>Utente</th>
                    <th>Compagnia</th>
                    <th>Categoria</th>
                    <th>Soggetto</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Creazione</th>
                    <th>Aggiornamento</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(ordersListToShow) && ordersListToShow.map((order) => <OrderListTile key={order.orderId} order={order} />)}
                </tbody>
              </table>
            </div>
          </div>
      }
      {/*           <Modal id="createExpertModal" onHide={() => setShowCreateExpert(false)} show={showExpertGoal} scrollable>
              <ModalHeader closeButton>
                  <ModalTitle>{t('experts_manager:create_expert')}</ModalTitle>
              </ModalHeader>
              <ModalBody>
                  <ExpertEditor expert={newExpert} handleExpertChange={setNewExpert} setExpertImage={setExpertImage} expertImage={null} />
              </ModalBody>
              <ModalFooter>
                  <button className="btn btn-primary" onClick={() => createNewExpert()}>{t('common:save')}</button>
              </ModalFooter>
          </Modal> */}
    </section>
  )
}

/* -------------------------------------------------------------------------- */
/*                            OrderListTile                                 */
/* -------------------------------------------------------------------------- */

function OrderListTile(props: { order: Order }) {
  const order = props.order;
  const { t } = useTranslation();
  const getTranslation = useI18nStore(state => state.getTranslation);
  const companies = useCompanyManagerStore(state => state.companyList);
  const orderCompany = (companies ?? []).find(company => company.companyId === order.orderDetail.companyId);
  const users = useUsersStore(state => state.usersList);
  const orderUser = (users ?? []).find(user => user.userProfileId === order.orderDetail.userProfileId);
  const experts = useExpertsStore(state => state.expertsList);
  const orderExpert = (experts ?? []).find(expert => expert.expertId === order.orderDetail.targetIds[0].value);
  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'bg-warning';
      case 'CLOSED':
        return 'bg-success';
      case 'CANCELLED':
        return 'bg-danger';
      case 'PAID':
        return 'bg-primary';
      default:
        return 'bg-secondary';
    }
  }
  const getTypeBadgeClass = (type: string) => {
    switch (type) {
      case 'CHARGEABLE':
        return 'bg-success';
      case 'FREE':
        return 'bg-primary';
      default:
        return 'bg-secondary';
    }
  }
  return (
    <>
      <tr className="align-middle">
        <td>
          <small>{stringToUniqueNumber(order.orderId!)}</small>
        </td>
        <td>{orderUser?.userProfileDetail.name + ' ' + orderUser?.userProfileDetail.surname}</td>
        <td>{orderCompany?.companyDetail.company}</td>
        <td>
          <div className="d-flex flex-row flex-wrap">
            {order.orderDetail.targetIds.length > 0 && (
              <span className="badge bg-primary rounded-pill me-1 mb-1">
                {order.orderDetail.targetIds[0].type}
              </span>
            )}
          </div>
        </td>
        <td>{orderExpert?.expertDetail.name}</td>
        <td>
          <span className={`badge ${getStatusBadgeClass(order.orderDetail.status)}`}>
            {t('orders:status.' + order.orderDetail.status)}
          </span>
        </td>
        <td>
          <span className={`badge ${getTypeBadgeClass(order.orderDetail.type)}`}>
            {t('orders:type.' + order.orderDetail.type)}
          </span>
        </td>
        <td>{order.orderDetail.orderCreationDate ? new Date(order.orderDetail.orderCreationDate).toLocaleDateString() : ''}</td>
        <td>{order.orderDetail.dateLastUpdate ? new Date(order.orderDetail.dateLastUpdate).toLocaleDateString() : ''}</td>
        <td>
          <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => {
            if (window.confirm(t('experts_manager:delete_expert_confirm'))) {
              toast.promise(useOrderStore.getState().deleteOrder(order), {
                loading: t('common:loading'),
                success: t('common:deleted'),
                error: (e) => t('common:error') + e.message
              });
            }
          }}>
            <i className="fa-duotone fa-trash"></i>
          </button>
        </td>
      </tr>
    </>
  )
}
export default OrdersManagerPage