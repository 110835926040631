import React, { useEffect } from 'react'
import Loader from 'components/Loader';
import { useExpertsStore } from 'stores/expertsStore';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'utils/functions';
import { AnimatePresence, motion } from 'framer-motion';
import { Expert } from 'models/expertModel';
import ExpertsCard from './ExpertsCard';
import TutorialTour from 'components/TutorialTour';

function ExpertsPage() {
    const [categories, setCategories] = React.useState<string[]>([]);
    const [filters, setFilter] = React.useState<string[]>([]);


    const [filteredExperts, setFilteredExperts] = React.useState<Expert[]>([]);
    const init = useExpertsStore(state => state.initExpertsStore);
    const { t } = useTranslation();
    const expertsList = useExpertsStore(state => state.expertsList);
    // useEffect for initShop
    useEffect(() => {
        if (expertsList === null) {
            init();
        } else {
            if (categories.length === 0) {
                // Appiattire l'array di array di stringhe in un unico array di stringhe
                const flattenedCategories = expertsList.flatMap(expert => expert.expertDetail.category);
                // Rimuovere i duplicati
                const uniqueCategories = Array.from(new Set(flattenedCategories));
                setCategories(uniqueCategories);
                setFilter([]);
            }
        }
    }, [expertsList, init, categories]);

    useEffect(() => {
        if (expertsList) {
            if (filters.length === 0) {
                setFilteredExperts([...expertsList]);
                return;
            }
            const filteredExperts = [];
            for (const expert of expertsList) {
                if (expert.expertDetail.category.some(category => filters.includes(category))) {
                    filteredExperts.push(expert);
                }
            }
            setFilteredExperts([...filteredExperts]);
        }
    }, [filters, expertsList]);

    useTitle(t('experts:title'));

    if (!expertsList) { return (<Loader />); }

    const variantContainer = {
        "animate": {
            transition: {
                duration: 0.15,
                staggerChildren: 0.05,
                type: "spring",
                bounce: 0.5,
            }
        },
        "initial": {
            transition: {
                duration: 0.1,
            }
        }
    };
    const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedFilter = e.currentTarget.value;
        // Se il filtro selezionato è già presente nell'array filter, lo rimuovo
        if (filters.includes(selectedFilter)) {
            setFilter(filters.filter(f => f !== selectedFilter));
        } else {
            setFilter([...filters, selectedFilter]);
        }
    }
    return (
        <motion.section
            className={`ExpertsPage d-flex flex-column`}>
            <TutorialTour tutorialName={'experts'} />
            <h5>
                {t('experts:subtitle')}
            </h5>
            <div className="nav my-3 ExpertsPageNav">
                {
                    categories
                        .map((category, index) => (
                            <button key={index} className="btn btn-outline-primary rounded-pill me-2 mb-2"
                                style={{
                                    backgroundColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-white)",
                                    color: filters.includes(category) ? "var(--bs-white)" : "var(--bs-tertiary-color)",
                                    borderColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-tertiary-color)",
                                }}
                                onClick={handleFilter} value={category}>
                                {category}
                            </button>
                        ))
                }
            </div>
            <AnimatePresence mode='popLayout'>
                <motion.div
                    key={filters.length > 0 ? "expertsContainer" : filteredExperts.length}
                    variants={variantContainer}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    transition={{
                        staggerChildren: 0.1,
                        duration: 0.2,
                    }}
                    className="row row-cols-xxxl-5 row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 g-3 mb-4"
                >
                    {
                        filteredExperts.map(
                            (expert, index) =>
                                <ExpertsCard
                                    expert={expert}
                                    key={expert.expertId}
                                />)}
                </motion.div>
            </AnimatePresence>
        </motion.section >

    )
}

export default ExpertsPage