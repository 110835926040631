import { AsyncChat, ChatMessage } from "models/chatModels";
import React, { useEffect, useRef } from "react"
import finn from "assets/images/logo-FinAi.svg";
import CircleButton from "../CircleButton";
import { Trans, useTranslation } from "react-i18next";
import { Button, Card } from "react-bootstrap";
import { useChatStore } from "stores/chatStore";
import Loader from "../Loader";
import { useUserStore } from "stores/userStore";
import { useAuthStore } from "stores/authStore";
import newMessagePopSound from "assets/audio/pop.mp3";
import { useDocumentStore, useFetchDocument } from "stores/documentStore";
import Markdown from 'marked-react';
import { useSystemModalStore } from "stores/systemModalStore";
import { motion, AnimatePresence, useAnimate } from "framer-motion";
import { toast } from "react-hot-toast";
import { marked } from "marked";
import '@mdxeditor/editor/style.css'
import { MDXEditorMethods } from '@mdxeditor/editor'
import { useIsMobile } from "stores/appStore";
import { useI18nStore } from "stores/i18nStore";
import FinLogo from "../FinLogo";
import { markdownGuide, markdownGuide_IT } from "./markdownGuide";
import exp from "constants";
import { useChatMessengerStore } from "stores/chatMessengerStore";


const ChatInitialView: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="ChatMessenger__empty d-flex flex-column justify-content-start align-items-center text-center mx-0 flex-fill"
            style={{}}>
            <div className="bg-light rounded-circle p-3 d-flex align-items-center justify-content-center">
                <FinLogo width="64" />
            </div>
            <h3 className="my-4 px-3">
                {t('chat:how_can_i_help_you', { name: useUserStore.getState().user?.userProfileDetail.name ?? "Utente senza nome" })}
            </h3>
            <p className="mb-4 px-4">
                <Trans i18nKey="chat:greeting" />
            </p>
            <div className="row g-3">
                <ChatStartCard title="chat:prompt1" icon="fa-file-invoice" prompt="prompt1" />
                <ChatStartCard title="chat:prompt2" icon="fa-file-user" prompt="prompt2" />
                {!isMobile &&
                    <>
                        <ChatStartCard title="chat:prompt3" icon="fa-file-invoice" prompt="prompt3" />
                        <ChatStartCard title="chat:prompt4" icon="fa-file-user" prompt="prompt4" />
                    </>}
            </div>
        </motion.div>
    );
};

function ChatStartCard({ title, icon, prompt: type }: { title: string, icon: string, prompt: string }) {
    const { t } = useTranslation();
    return (
        <div className="ChatStartCard col-6 col-xl-3">
            <div className="card h-100 card text-start align-self-stretch cursor-pointer" onClick={
                async () => {
                    await useChatMessengerStore.getState().openNewChat(t('chat:' + type));
                    /* TODO */
                }}>
                <div className="card-body d-flex justify-content-end align-items-start flex-column-reverse">
                    <div className="text-md-start" >
                        {t(title)}
                    </div>
                    <div
                        className="mb-2"
                        style={{
                            marginLeft: "-0.5rem",
                        }}>
                        <i className="fa-stack" style={{
                            fontSize: "26px",
                        }}>
                            <i className="fas fa-circle fa-stack-2x text-light"></i>
                            <i className={"fa-duotone fa-stack-1x text-primary " + icon}></i>
                        </i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatInitialView;