import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";
import { useAuthStore } from "./authStore";

export interface GemsStore {
    currentGemsData: any;
    getUserGems: () => Promise<any>;
    refreshGems: (expectedGems?: number) => Promise<any>;
}




export const useGemsStore = create<GemsStore>()((set, get, __) => {
    return {
        currentGemsData: null,
        getUserGems: async () => {
            if (get().currentGemsData === null) {
                await get().refreshGems();
            } else {
                return get().currentGemsData;
            }
        },
        // Call the refreshGems() function to update the gems of the user. 
        // Provide a expectedGems parameter to set the gems to a specific value before the API call is made.
        //  This is useful when you want to update the gems of the user before waiting for the API call to complete and the expectedGems are known.
        refreshGems: async (expectedGems) => {
            if (useAuthStore.getState().isUser) {
                if (expectedGems !== undefined) {
                    set({ currentGemsData: { gemDetail: { currentGems: expectedGems } } });
                }
                try {
                    let gemsResponse = await ApiService.gems.getGemsByUserProfileId(useUserStore.getState().user?.userProfileId);
                    set({ currentGemsData: gemsResponse });
                    return get().currentGemsData;
                }
                catch (error) {
                    console.error(error);
                    set({ currentGemsData: { gemDetail: { currentGems: 0 } } });
                    return null;
                }
            }
        },

    };
});