/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'stores/authStore';
import Config, { appThemes } from 'utils/config';
import LanguageSelector from './LanguageSelector';
import { useUserStore } from 'stores/userStore';
import Mark from './Mark';
import { getRoleColorVariant } from 'utils/functions';
import DarkModeSelector from './DarkModeSelector';
import { useThemeStore } from 'stores/themeStore';
import FunnifinLogo from './FunnifinLogo';
import mark from 'assets/images/F-mark-wh.svg';
import { useChatStore } from 'stores/chatStore';
import AppThemed from './AppThemed';
import PartnerLogo from './PartnerLogo';
import { routes } from 'Routes';
/// Displays the main menu
/// Used in: src\components\MainLayout.js
/// Use a FloatingMenuHeading component to display a heading in the menu
export default function FloatingMenu() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useUserStore((state) => state.user);
    const isAdmin = useAuthStore((state) => state.isAdmin);
    const isSystem = useAuthStore((state) => state.isSystem);
    const isHr = useAuthStore((state) => state.isHR);
    const isUser = useAuthStore((state) => state.isUser);
    const unreadChats = useChatStore((state) => state.unreadChats);
    if (!user) return null;
    return (
        <ul className="FloatingMenu nav flex-column ">
            <style>
                {`

                .FloatingMenu{
                    height: min-content;
                }
                .FloatingMenu, .sidebarOpen, .sidebarClosed, .FloatingMenuNavIcon,.LanguageSelectorLabel, .FloatingMenuNavText, .FloatingMenuLogo, .FloatingMenuHeading, .FloatingMenuUserCard, .FloatingMenuUserCardBody, .FloatingUserCardData, .FloatingUserCardChevron, .LanguageSelectorLabel, .contentClosed, .contentOpen{
                    transition:  all 0.4s ease-out, background-color 0.6s ease-in-out, color 0.1s ease-in-out;
                    opacity: 1;
                }
                /* LOGO */
                .FloatingMenuLogo{
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 3rem;

                }
                .FloatingMenuLogoPartner{
                margin-top: 2.5rem;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 64px !important;
                    filter: grayscale(100%);
                    opacity: 0.5;
                }
                .FloatingMenuLink {
                    padding-left: 1.7rem !important;
                }
                .FloatingMenuLogoPartner:hover{
                    filter: none !important;
                }
                .sidebarOpen .FloatingMenuLogo{
                    margin-top: 2.5rem;
                    margin-bottom: 2.5rem;
                    height: 3rem;
                    max-width: 6rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                .sidebarClosed .FloatingMenuLogo{
                    display: none;
                }
                .FloatingMenuLogoSmall{
                    display: none;
                }

                .sidebarClosed .FloatingMenuLogoSmall{
                    display: block;
                }
                    
                 .sidebarClosed .FloatingMenuLogoPartner{
                    display: none;
                }


                /* HEADINGS */
                .sidebarClosed .FloatingMenuHeading{
                    justify-content: center;
                }
                .sidebarClosed .FloatingMenuHeadingText{
                display: none;
                }
                .sidebarClosed .accordion-button {
                    display: none;
                }
                /* LINKS */
                .sidebarClosed .FloatingMenuNavIcon{
                    font-size: 1.5rem;
                    margin: 0rem !important;
                    padding: 0rem !important;
                    text-align: center;
                }
                .sidebarClosed .nav-item{
                    padding-bottom: 1rem;
                }
                .sidebarClosed .FloatingMenuLink{
                    text-align: center;
                }
                .sidebarClosed .FloatingMenuNavText{
                    opacity: 0;
                    width: 0rem !important;
                    overflow: hidden;
                    height: 0rem !important;
                }
                /* GENERAL SIZE */
                div.sidebarClosed {
                    width: 6rem !important;
                }
                div.sidebarOpen {
                    width: 17rem !important;
                    max-width: 17rem !important;
                }
                /* LANGUAGE */
                .sidebarClosed .LanguageSelectorLabel{
                    width: 0rem !important;
                }
                /* USER CARD */
                .sidebarClosed .FloatingMenuUserCard{
                    border: 0;
                }

                .sidebarClosed .FloatingMenuUserCardBody{
                    justify-content: center !important;
                    padding: 0.3rem !important;
                }
                .sidebarClosed .FloatingUserCardChevron, .sidebarClosed .FloatingUserCardData{
                    width: 0rem !important;
                    margin: 0rem !important;
                    overflow: hidden;

                    opacity: 0;
                    display: none !important;
                }
                    .sidebarClosed .FloatingUserCardData{
                    transition: all 0.8s ease-in-out;
                    }
                    .sidebarClosed .FloatingUserCardData * {
                    height: 0rem !important;
                    width: 0rem !important;
                    overflow: hidden;
                    }
                .sidebarClosed .FloatingUserCard {
                    border: 0 !important;
                }

                /* SOCIAL */
                .sidebarClosed .FloatingMenuSocial{
                    width: 0rem !important;
                    overflow: hidden;
                    margin: 0 !important;
                    opacity: 0;
                }
                .active .fa-duotone{
                    color: var(--bs-primary) !important;
                }
                .active small {
                    color: var(--bs-primary) !important;
                }
                .sidebarClosed a{
                    padding: 0;
                    padding-left: 0 !important;
                    padding-bottom: 0.5rem;
                    justify-content: center;
                }
                .sidebarClosed .accordion-collapse{
                    display: flex !important;
                    justify-content: center;
                }
                .sidebarClosed .FloatingMenuHeading .FloatingMenuHeadingIcon{
                    font-size: 1.5rem;
                    padding-bottom: 0.5rem;
                }
                .sidebarClosed .FloatingMenuContacts{
                    flex-direction: column;
                }
                .sidebarClosed .FloatingMenuContacts{
                    display: none !important;
                }
                .sidebarClosed .FloatingUserCard{
                    border-color: var(--bs-border-color) !important;
                    border-bottom: 2px solid var(--bs-border-color) !important;
                    border-radius: 0 !important;
                    padding-bottom: 1rem !important;
                    margin-bottom: 0.5rem !important;
                    background-color: transparent !important;
                }
                .dotContainer{
                    position: absolute;
                    top: 0;
                    left: 2.8rem;
                }
                .sidebarClosed .dotContainer{
                    top: -10px;
                }
                .sidebarClosed .noveltyContainer{
                    display: none;}
                `}
            </style>

            {/* HEADER */}
            <div className='w-100 d-flex'
                style={{
                    minHeight: "78.08px",
                    maxHeight: "78.08px",
                }}>
                <img src={mark}
                    alt="logo"
                    width='100%'
                    className='FloatingMenuLogoSmall'
                ></img>
                <AppThemed themes={[appThemes.SIDEBARS]}>
                    <FunnifinLogo
                        onClick={() => navigate(routes.dashboard)}
                        className="FloatingMenuLogo  cursor-pointer mb-3 mt-3 mx-auto"
                        width='100%'
                        style={{ maxWidth: "7rem" }}
                    /></AppThemed>
                <AppThemed themes={[appThemes.SCHOOL]}>
                    <PartnerLogo
                        className="FloatingMenuLogo  cursor-pointer mb-3 mt-3 mx-auto"
                        onClick={() => navigate(routes.eduquizManager)}
                    />
                </AppThemed>
            </div>
            {/* USER CARD */}
            <div className="flex-row d-flex align-items-center mb-3 w-100">
                <div className="card border w-100 cursor-pointer FloatingUserCard" onClick={() => navigate(routes.profile)}>
                    {/* LOGO */}
                    <div className='card-body d-flex justify-content-between align-items-center FloatingMenuUserCardBody'>
                        <div className={`d-flex align-items-center justify-content-center bg-body-tertiary rounded-circle btn text-primary p-2 cursor-pointer bg-body`} style={{ width: '2rem', height: '2rem' }}>
                            <i className={`align-items-center d-flex justify-content-center fa-duotone text-${isAdmin ? 'warning' : isSystem ? 'danger' : 'primary'} fa-${isAdmin ? 'user-tie' : isSystem ? 'user-gear' : 'user'}`} aria-hidden="true" style={{ width: '1.3rem', height: '1.3rem' }} />
                        </div>
                        <div className="d-flex flex-column ps-2 FloatingUserCardData " style={{ fontSize: "0.8rem" }}>
                            <div className='d-flex align-items-center'>
                                <small className="fw-bold text-truncate display-inline-block" >{user.userProfileDetail.name} {user.userProfileDetail.surname}</small>

                            </div>
                            {isAdmin && <div className={'pe-2 fw-bold small text-' + getRoleColorVariant()}>ADMIN</div>}
                            {isSystem && <div className={'pe-2 fw-bold small text-' + getRoleColorVariant()}>SYSTEM</div>}
                            {isHr && <div className={'pe-2 fw-bold small text-' + getRoleColorVariant()}>HR</div>}
                            <small className="fw-bold text-truncate display-inline-block" style={{
                                width: "9.6rem",
                                fontSize: "0.55rem",
                            }}>{user.userProfileDetail.email}</small>
                        </div>
                        <div className='FloatingUserCardChevron'>
                            <i className="fa-duotone fa-chevron-right ms-auto text-primary small" aria-hidden="true" />
                        </div>
                    </div>
                    {/* LOGO */}
                </div>
            </div>
            {/* LINKS */}
            {/* ADMIN */}
            {
                isAdmin && <>
                    <FloatingMenuHeading>{t('main_menu:education')}</FloatingMenuHeading>
                    <FloatingMenuNavLink icon="home" to='dashboard'>{t('main_menu:dashboard')}</FloatingMenuNavLink>
                    {Config.enabledFeatures.userManagement && <>
                        <AppThemed themes={[appThemes.SCHOOL]}>

                            <FloatingMenuNavLink icon="user" to='users'>{t('main_menu:users_school')}</FloatingMenuNavLink>
                        </AppThemed>
                        <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                            <FloatingMenuNavLink icon="users" to='users'>{t('main_menu:users')}</FloatingMenuNavLink>
                        </AppThemed>
                    </>}
                    {Config.enabledFeatures.quizManagement && <>
                        <AppThemed themes={[appThemes.SCHOOL]}>
                            <FloatingMenuNavLink icon="chalkboard-teacher" to='eduquizManager'>{t('eduquiz_manager:school.title')}</FloatingMenuNavLink>
                        </AppThemed>
                        <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                            <FloatingMenuNavLink icon="university" to='eduquizManager'>{t('main_menu:eduquiz_manager')}</FloatingMenuNavLink>
                        </AppThemed>
                        <FloatingMenuNavLink icon="robot" to='eduquiz'>{t('main_menu:eduquiz_simulator')}</FloatingMenuNavLink>
                    </>}
                    {(Config.enabledFeatures.shopManagement || Config.enabledFeatures.partnerManagement || Config.enabledFeatures.languageManagement) && <FloatingMenuHeading>{t('main_menu:engagement')}</FloatingMenuHeading>}
                    {Config.enabledFeatures.shopManagement && <FloatingMenuNavLink icon="shop" to='shopmanager'>{t('main_menu:shop_manager')}</FloatingMenuNavLink>}
                    {Config.enabledFeatures.partnerManagement && <FloatingMenuNavLink icon="ad" to='partnerManager'>{t('main_menu:partner_manager')}</FloatingMenuNavLink>}
                    {Config.enabledFeatures.languageManagement && <FloatingMenuNavLink icon="flag" to='languageManager'>{t('main_menu:language_manager')}</FloatingMenuNavLink>}
                    {/* TODO remove comment after deploy to prod */}
                    {/* Config.enabledFeatures.learningMaterialsManagement && */ <FloatingMenuNavLink icon="book" to='/admin/learning_materials_manager'>{t('main_menu:learning_materials')}</FloatingMenuNavLink>}
                </>
            }
            {/* SYSTEM */}
            {
                isSystem && <>
                    {/* <FloatingMenuHeading>{t('main_menu:system')}</FloatingMenuHeading> */}
                    <FloatingMenuNavLink icon="home" to='/system/dashboard'>{t('main_menu:dashboard')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="user" to='users'>{t('main_menu:users')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="building" to='/system/company_manager'>{t('main_menu:company_manager')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="shop" to='/system/shopmanager'>{t('main_menu:shop_manager')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="messages" to='/system/chat_manager'>{t('main_menu:chat_manager')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="user-tie" to='/system/experts_manager'>{t('main_menu:experts_manager')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="video" to='/system/video_manager'>{t('main_menu:video_manager')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="book" to='/system/learning_materials_manager'>{t('main_menu:learning_materials')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="file-invoice" to='/system/orders_manager'>{t('main_menu:orders')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="bars-progress" to='/system/progression_manager'>{t('main_menu:progression_manager')}</FloatingMenuNavLink>
                </>
            }
            {/* HR */}
            {
                isHr && <>
                    <FloatingMenuHeading>{t('main_menu:hr')}</FloatingMenuHeading>
                    <FloatingMenuNavLink icon="home" to='hr/dashboard'>{t('main_menu:dashboard')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="messages" to='chat_manager'>{t('main_menu:chat_manager')}</FloatingMenuNavLink>
                </>
            }
            {/* USER */}
            {
                isUser && <>
                    <FloatingMenuNavLink showDot={unreadChats.length > 0} to='/welfare/dashboard' icon="home" showNovelty >{t('main_menu:home')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink to='/welfare/eduquiz' icon="chalkboard-user">{t('main_menu:eduquiz')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="film" to='/welfare/playlists' showNovelty>{t('main_menu:videos')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="user-tie" to='/welfare/experts'>{t('main_menu:our_experts')}</FloatingMenuNavLink>
                    <FloatingMenuNavLink icon="tags" to='/welfare/shop'>{t('main_menu:shop')}</FloatingMenuNavLink>
                    {/* {Config.enabledFeatures.progression && <FloatingMenuNavLink to='progression' icon="bars-progress">{t('main_menu:progression')}</FloatingMenuNavLink>}
                    {Config.enabledFeatures.ranking && <FloatingMenuNavLink to='ranking' icon="ranking-star">{t('main_menu:ranking')}</FloatingMenuNavLink>} */}
                </>
            }
            <AppThemed themes={[appThemes.SIDEBARS]}>
                {
                    Config.partnerLogo && <div className="FloatingMenuLogoPartner cursor-pointer mb-3" onClick={() => {
                        window.open(Config.partnerUrl, "_blank");
                    }} style={{ backgroundImage: `url('${Config.partnerLogo}')` }}></div>
                }
            </AppThemed>
        </ul >
    )
}
function FloatingMenuHeading(props: { to?: string, icon?: string, children: any, ref?: any }) {
    const isAdmin = useAuthStore((state) => state.isAdmin);
    const isSystem = useAuthStore((state) => state.isSystem);
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === props.to;
    return (
        <div className={'FloatingMenuHeading fw-bold my-2 d-flex align-items-center text-' + getRoleColorVariant()} ref={props.ref}>
            {props.icon && <i
                onClick={
                    () => {
                        if (props.to) {
                            navigate(props.to);
                        }
                    }
                }
                className={'FloatingMenuHeadingIcon fa-fw fa-duotone cursor-pointer text-' + ((isActive) ? getRoleColorVariant() : 'secondary') + ' fa-' + props.icon + " "} />}

            {props.to && <NavLink to={props.to} className="FloatingMenuHeadingText ms-2 text-decoration-none text-reset">{props.children}</NavLink>}
            {!props.to && <div
                className='cursor-pointer ms-2 FloatingMenuHeadingText'
            >{props.children}</div>}
        </div>
    )
}

function FloatingMenuNavLink(props: { to: string, icon?: string, children: any, showDot?: boolean, showNovelty?: boolean }) {
    const { t } = useTranslation();
    return (
        <li className="nav-item my-md-2 position-relative" id={props.to + "-nav-link"}>

            <NavLink to={props.to} className={({ isActive, isPending }) =>
                isPending ? "nav-link FloatingMenuLink  d-flex " : isActive ? "active fw-bold FloatingMenuLink nav-link d-flex FloatingMenuLink" : " d-flex nav-link FloatingMenuLink"
            }>


                {props.icon && <div style={{ "width": "2rem" }}><i className={'FloatingMenuNavIcon fa-duotone fa-fw me-3 mb-1 text-' + getRoleColorVariant() + ' fa-' + props.icon + " "} /></div>
                }                <small className='text-body FloatingMenuNavText  d-flex align-items-center'>
                    {props.children}
                    {props.showDot && <div className='ms-2 me-2'>
                        <i className='fas fa-circle text-danger'
                            style={{
                                fontSize: "0.5rem"
                            }}></i>
                    </div>}
                </small>
                {
                    props.showNovelty && <div className='noveltyContainer text-uppercase text-primary ms-2 fw-normal small ms-auto'>
                        {t('main_menu:novelty')}
                    </div>
                }
            </NavLink>
        </li >

    )
}

