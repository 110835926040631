import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";
import { ExperiencePoint, Goal, GoalDetail } from "models/progressionModels";

export interface ProgressionStore {
    currentExperiencePointData: ExperiencePoint | null;
    eduquizStatistics: any;
    completedModules: any;
    goalList: Goal[] | null;
    getUserExperiencePoints: () => Promise<any>;
    getUserQuizStatistics: (userId: string) => Promise<any>;
    getGoalList: () => Promise<any>;
    refreshExperiencePoints: (expectedExperiencePoints: number | null) => Promise<any>;

    deleteGoal: (goalId: string) => Promise<void>;
    createGoal: (goal: GoalDetail) => Promise<void>;
    updateGoal: (goal: Goal) => Promise<void>;
}


//* This store is used to manage the progression page
//* 
export const useProgressionStore = create<ProgressionStore>()((set, get) => {
    return {
        currentExperiencePointData: null,
        eduquizStatistics: null,
        completedModules: null,
        goalList: null,
        getUserExperiencePoints: async () => {
            if (get().currentExperiencePointData === null) {
                await get().refreshExperiencePoints(null);
            } else {
                return get().currentExperiencePointData;
            }
        },
        getUserQuizStatistics: async (userId: string) => {
            try {
                let quizArguments = await ApiService.education.getUserArgumentsWithUnits(userId);
                // loop through the arguments 
                let argumentsCompleted = 0;
                let modulesCompleted = 0;
                let unitsCompleted = 0;
                for (let i = 0; i < quizArguments.length; i++) {
                    if (quizArguments[i].argumentDetail.userArgument!.userArgumentDetail.percentageCompletion === 100) {
                        argumentsCompleted++;
                    }
                    for (let j = 0; j < (quizArguments[i].argumentDetail.modules ?? []).length; j++) {
                        if ((quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.userModule!.userModuleDetail.percentageCompletion === 100) {
                            modulesCompleted++;
                        }
                        for (let k = 0; k < (quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.units!.length; k++) {
                            if ((quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k]!.unitDetail.userUnit!.userUnitDetail.percentageCompletion === 100) {
                                unitsCompleted++;
                            }
                        }
                    }
                }
                set({
                    eduquizStatistics: {
                        argumentsCompleted: argumentsCompleted, modulesCompleted: modulesCompleted,
                        unitsCompleted: unitsCompleted, argumentsTotal: quizArguments.length,
                        modulesTotal: quizArguments.reduce((acc, argument) => acc + argument.argumentDetail.modules!.length, 0),
                        unitsTotal: quizArguments.reduce((acc, argument) => acc + argument.argumentDetail.modules!.reduce((acc2, module) => acc2 + module.moduleDetail.units!.length, 0), 0)
                    }
                });
                return { argumentsCompleted: argumentsCompleted, modulesCompleted: modulesCompleted, unitsCompleted: unitsCompleted };
            }
            catch (e) {
                console.error('Error getting user quiz statistics', e);
                return null;
            }
        },
        getGoalList: async () => {
            try {
                let goalList = await ApiService.goal.getGoalList();
                if (goalList) {
                    set({ goalList: [...goalList] });
                } else {
                    set({ goalList: [] });
                }
            } catch (e) {
                console.log('Error fetching goal list', e);
                set({ goalList: [] });
            }
        },
        refreshExperiencePoints: async (expectedExperiencePoints) => {
            if (expectedExperiencePoints !== null) {
                set({
                    currentExperiencePointData: {
                        experiencePointDetail: {
                            currentExperiencePoints: expectedExperiencePoints!
                        },
                        experiencePointId: "",
                    }
                });
            }
            let experiencepointResponse = await ApiService.experiencePoints.getExperiencePointsByUserProfileId(useUserStore.getState().user?.userProfileId);
            set({ currentExperiencePointData: experiencepointResponse });
            return get().currentExperiencePointData;
        },
        deleteGoal: async (goalId: string) => {
            try {
                await ApiService.goal.delete(goalId);
                await get().getGoalList();
            } catch (e) {
                console.error('Error deleting goal', e);
            }
        },
        createGoal: async (goal: GoalDetail) => {
            try {
                await ApiService.goal.create(goal);
                await get().getGoalList();
            } catch (e) {
                console.error('Error creating goal', e);
            }
        },
        updateGoal: async (goal: Goal) => {
            try {
                await ApiService.goal.update(goal);
                await get().getGoalList();
            } catch (e) {
                console.error('Error updating goal', e);
            }
        },
    };
});