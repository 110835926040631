import AppThemed from 'components/AppThemed'
import FunnifinLogo from 'components/FunnifinLogo'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Config, { appThemes } from 'utils/config'

function EmptyLayout(props) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='container-fluid container-md py-4 flex-fill'>
                <Outlet />
            </div>
        </div>


    )
}

export default EmptyLayout