import CircleButton from "components/CircleButton";
import HeaderAdmin from "components/HeaderAdmin";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { useDocumentStore } from "stores/documentStore";
import LearningMaterialEditor from "./LearningMaterialEditor";
import { useI18nStore } from "stores/i18nStore";
import { generateImageUrl, useTitle } from "utils/functions";
import { useLearningMaterialsManagerStore } from "stores/learningMaterialsManagerStore";
import { LearningMaterial, useLearningMaterialsStore } from "stores/learningMaterialsStore";
import { useUserStore } from "stores/userStore";
import LearningMaterialsCard from "./LearningMaterialsCard";
import { useCompanyManagerStore } from "stores/companyManagerStore";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";
import { useAuthStore } from "stores/authStore";

const learningMaterialInitialData: LearningMaterial = {
  id: "",
  title: "",
  multiLanguageTitle: { en: "", it: "" },
  description: { en: "", it: "" },
  url: "",
  category: "",
  img: "",
  icon: "",
  companyId: "",
};

function LearningMaterialsManagerPage() {
  const learningMaterialsList = useLearningMaterialsManagerStore((state) => state.learningMaterials);
  const { t } = useTranslation();
  const [showLearningMaterialModal, setShowLearningMaterialModal] = useState(false);
  const [newLearningMaterial, setNewLearningMaterial] = useState<LearningMaterial>(learningMaterialInitialData);
  const [learningMaterialImage, setLearningMaterialImage] = useState<File | null>(null);
  const [learningMaterialFile, setLearningMaterialFile] = useState<File | null>(null);
  const [companyFilter, setCompanyFilter] = useState("");
  const initCompanies = useCompanyManagerStore(state => state.init);
  const companyList = useCompanyManagerStore(state => state.companyList);

  useEffect(() => {
    if (learningMaterialsList === null) {
      useLearningMaterialsManagerStore.getState().fetchLearningMaterials();
    }
    if (companyList === null) {
      initCompanies();
    }
  }, [learningMaterialsList, companyList, initCompanies]);

  useTitle(t("learningMaterialsManager:title"));

  const createNewLearningMaterial = async () => {
    setShowLearningMaterialModal(false);
    let newImage = null;
    if (learningMaterialImage != null) {
      newImage = await useDocumentStore.getState().uploadDocument({
        format: learningMaterialImage.type,
        title: learningMaterialImage.name,
        ownerId: useUserStore.getState().user?.userProfileId ?? "",
        type: "learning_material_image",
        file: learningMaterialImage as File,
      });
    }
    if (newImage != null) {
      newLearningMaterial.img = generateImageUrl(newImage)!;
    }
    newLearningMaterial.companyId = companyFilter === "" ? undefined : companyFilter;
    toast
      .promise(useLearningMaterialsManagerStore.getState().uploadLearningMaterial(newLearningMaterial), {
        loading: t("common:loading"),
        success: t("common:created"),
        error: (e) => t("common:error") + e.message,
      })
      .then(() => {
        setNewLearningMaterial(learningMaterialInitialData);
        setLearningMaterialImage(null);
      });
  };
  // FILTER LEARNING MATERIALS BY COMPANY
  const filteredMaterials = learningMaterialsList?.filter((material) => material.companyId === companyFilter);

  return (
    <section className="LearningMaterialsManagerPage d-flex flex-column">
      <HeaderWidgetPortal>
        <>
          {/*       <CircleButton
        key={"showDuplicates" + showDuplicates}
        className="ms-auto CircleButtonForManagerPage"
        iconClassName={"fas fa-clone"}
        variant={showDuplicates ? "primary" : "secondary"}
        onClick={showTheDuplicates}
      /> */}
          {/* Select for company filter */}
          {
            useAuthStore.getState().isSystem &&
            <select className="form-select w-auto ms-auto" onChange={(e) => setCompanyFilter(e.target.value)}>
              <option value="">{t("common:view_all")}</option>
              {companyList?.map((company: any) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.companyDetail.company}
                </option>
              ))}
            </select>
          }
          <CircleButton
            className="ms-3"
            iconClassName={"fas fa-plus"}
            onClick={() => {
              setShowLearningMaterialModal(true);
            }}
          />
        </>
      </HeaderWidgetPortal>
      {learningMaterialsList === null ? (
        <Loader />
      ) : learningMaterialsList.length === 0 ? (
        <div className="text-center my-3">
          {t("learningMaterialsManager:no_learning_materials")}
          <br />
          <br />
          <Button variant="primary" onClick={() => useLearningMaterialsStore.getState().createInitialLearningMaterials()}>
            {t("learningMaterialsManager:fetch_default_learning_materials")}
          </Button>
        </div>
      ) : (
        <div className="mb-4 card">
          <div className="card-body d-flex flex-column">
            <ul className="list-group list-group-flush">
              {Array.isArray(filteredMaterials) &&
                (
                  companyFilter ? filteredMaterials : learningMaterialsList
                ).map((learningMaterial) => (
                  <LearningMaterialListTile key={learningMaterial.id} learningMaterial={learningMaterial} companyList={companyList} />
                ))}
            </ul>
          </div>
        </div>
      )}
      <Modal id="createLearningMaterialModal" onHide={() => setShowLearningMaterialModal(false)} show={showLearningMaterialModal} scrollable>
        <ModalHeader closeButton>
          <ModalTitle>{t("learningMaterialsManager:create_learning_material")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <LearningMaterialEditor
            learningMaterial={newLearningMaterial}
            handleLearningMaterialChange={setNewLearningMaterial}
            setLearningMaterialImage={setLearningMaterialImage}
            learningMaterialImage={learningMaterialImage}
            setLearningMaterialFile={setLearningMaterialFile}
            learningMaterialFile={learningMaterialFile}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={() => createNewLearningMaterial()}>
            {t("common:save")}
          </button>
        </ModalFooter>
      </Modal>
    </section>
  );
}

/* -------------------------------------------------------------------------- */
/*                            LearningMaterialListTile                        */
/* -------------------------------------------------------------------------- */

function LearningMaterialListTile(props: { learningMaterial: LearningMaterial, companyList: any }) {
  const learningMaterial = props.learningMaterial;
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [learningMaterialImage, setLearningMaterialImage] = useState<File | null>(null);
  const uploadDocument = useDocumentStore((state) => state.uploadDocument);
  const [learningMaterialToEdit, setLearningMaterialEdit] = useState({
    id: learningMaterial.id,
    title: learningMaterial.title,
    multiLanguageTitle: learningMaterial.multiLanguageTitle,
    description: learningMaterial.description,
    url: learningMaterial.url,
    category: learningMaterial.category,
    img: learningMaterial.img,
    icon: learningMaterial.icon,
    storagePath: learningMaterial.storagePath,
    companyId: learningMaterial.companyId,
  });

  const getTranslation = useI18nStore((state) => state.getTranslation);
  const [learningMaterialFile, setLearningMaterialFile] = useState<File | null>(null);
  const updateLearningMaterial = async () => {
    setShowEditModal(false);
    let newImage = null;
    if (learningMaterialImage != null) {
      newImage = await uploadDocument({
        format: learningMaterialImage.type,
        title: learningMaterialImage.name,
        ownerId: useUserStore.getState().user?.userProfileId ?? "",
        type: "learning_material_image",
        file: learningMaterialImage as File,
      });
    }
    if (newImage != null) {
      learningMaterialToEdit.img = generateImageUrl(newImage)!;
    }
    toast
      .promise(useLearningMaterialsManagerStore.getState().updateLearningMaterial(learningMaterialToEdit), {
        loading: t("common:loading"),
        success: t("common:saved"),
        error: (e) => t("common:error") + e.message,
      });
  };

  const companyName = props.companyList?.find((company: any) => company.companyId === learningMaterial.companyId)?.companyDetail.company;

  return (
    <>
      <li className="LearningMaterialListTile list-group-item d-flex flex-row align-items-center px-0">
        {/* <img src={learningMaterial.img} alt="learning material cover" className="rounded object-fit-cover me-3" style={{ width: "150px", height: "80px" }} /> */}
        <div style={{
          width: "340px",
          height: "278px",
          minWidth: "340px",
        }}>
          <LearningMaterialsCard learningMaterial={learningMaterial} />
        </div>
        <h6 className="mb-0 ms-3 d-flex flex-column align-items-start" style={{ width: "250px" }}>
          <span>{learningMaterial.title}</span>
          <span className="small text-muted">{learningMaterial.category}</span>

          <a className="small text-muted mt-3" href={learningMaterial.url}>{learningMaterial.url}</a>
        </h6>
        <span className="small fw-bold mx-3">{companyName}</span>
        <div className="ms-auto d-flex align-items-center">
          {learningMaterial.storagePath && <button
            className="btn btn-sm btn-outline-secondary me-2"
            onClick={() => {
              //download learning material file
              useDocumentStore.getState().downloadDocument(learningMaterial.id);
            }}
          >
            <i className="fa-duotone fa-download"></i>
          </button>}
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <i className="fa-duotone fa-pencil"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger ms-2"
            onClick={() => {
              if (window.confirm(t("learningMaterialsManager:delete_learning_material_confirm"))) {
                toast
                  .promise(useLearningMaterialsManagerStore.getState().deleteLearningMaterial(learningMaterial.id), {
                    loading: t("common:loading"),
                    success: t("common:deleted"),
                    error: (e) => t("common:error") + e.message,
                  })
                  .then(() => {
                    setLearningMaterialImage(null);
                    setLearningMaterialFile(null);
                  });
              }
            }}
          >
            <i className="fa-duotone fa-trash"></i>
          </button>
        </div>
      </li>
      {/* EDIT MODAL */}
      <Modal id="editLearningMaterialModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
        <ModalHeader closeButton>
          <ModalTitle>{t("common:edit")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <LearningMaterialEditor
            learningMaterial={learningMaterialToEdit}
            handleLearningMaterialChange={setLearningMaterialEdit}
            setLearningMaterialImage={setLearningMaterialImage}
            learningMaterialImage={learningMaterialImage}
            setLearningMaterialFile={setLearningMaterialFile}
            learningMaterialFile={learningMaterialFile} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary rounded-pill" onClick={() => updateLearningMaterial()}>
            {t("common:save")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LearningMaterialsManagerPage;
