import Config from "utils/config";
import { useAuthStore } from "./authStore";
import { create } from "./createStore";
import { useUserStore } from "./userStore";

const ACCESS_COUNT_THRESHOLD = 10;
const COMPLETED_UNITS_THRESHOLD = 4;
const FEEDBACK_DELAY = 4000;
const PLATFORM_FEEDBACK_INTERVAL = 3 * 30 * 24 * 60 * 60 * 1000;
export interface FeedbackStore {
    isFeedbackModalVisible: boolean;
    currentFeedback: string | null;
    feedbackData: string | null;
    checkUnitFeedback: (unitId: string, unitTitle: string) => void;
    checkVideoFeedback: (playlistId: string, videoId: string, title: string) => void;
    checkPlatformFeedback: () => void;
    checkCourseFeedback: (playlistId: string, title: string) => void;
    onFeedbackSubmit: (feedback: string) => void;
}

const feedbackIds = {
    unit: 'yKplVTLP',
    course: 'NSzMVtfR',
    video: 'rGuYhYU7',
    platform: 'YpgUVzOo'
}

function areFeedbacksEnabled() {
    return Config.production && Config.enabledFeatures.feedback;
}

export const useFeedbackStore = create<FeedbackStore>()((set, get, _) => ({
    isFeedbackModalVisible: false,
    currentFeedback: null,
    feedbackData: null,
    checkCourseFeedback: async (playlistId: string) => {

        if (!areFeedbacksEnabled()) return;

        // Check if playlist has received feedback
        let playlistHasFeedback = window.localStorage.getItem('playlistFeedback' + playlistId + "." + useUserStore.getState().user?.userProfileId);
        if (!playlistHasFeedback) {
            set({
                isFeedbackModalVisible: true,
                currentFeedback: feedbackIds.course,
                feedbackData: "Course: " + playlistId
            });
            window.localStorage.setItem('playlistFeedback' + playlistId + "." + useUserStore.getState().user?.userProfileId, 'true');
        }
    },
    checkUnitFeedback: async (unitId: string, unitTitle: string) => {
        if (!areFeedbacksEnabled()) return;

        // CHECK HOW many accesses the user has made
        // we want to proceed with feedback if the user has made a multiple of 10 accesses
        // after the multiple is reached, we want to check if the unit has been completed at least 4 times
        // if so, show feedback then reset the count and wait for the next multiple of 10 accesses to unlock the next feedback  phase
        let accessCount = useUserStore.getState().user?.userProfileDetail.accesses?.length ?? 0;
        let previousAccessCount = window.localStorage.getItem('previousAccessCount.' + useUserStore.getState().user?.userProfileId) ?? '0';
        if (Math.floor(accessCount / ACCESS_COUNT_THRESHOLD) >= parseInt(previousAccessCount)) {
            //get completed unit count
            const completedUnits = window.localStorage.getItem('completedUnits.' + useUserStore.getState().user?.userProfileId) ?? '0';
            // if 4 units have been completed
            if (parseInt(completedUnits) + 1 >= COMPLETED_UNITS_THRESHOLD) {
                set({
                    isFeedbackModalVisible: true,
                    currentFeedback: feedbackIds.unit,
                    feedbackData: "Unit: " + unitTitle + " (ID: " + unitId + ")"
                });
                //restart unit count
                window.localStorage.setItem('completedUnits.' + useUserStore.getState().user?.userProfileId, '0');
                // increment previous access count
                window.localStorage.setItem('previousAccessCount.' + useUserStore.getState().user?.userProfileId, (parseInt(previousAccessCount) + 1).toString());
            } else {
                // if 4 units have not been completed, update count
                window.localStorage.setItem('completedUnits.' + useUserStore.getState().user?.userProfileId, (parseInt(completedUnits) + 1).toString());
            }
        }
    },
    checkVideoFeedback: async (playlistId: string, videoId: string, title: string) => {
        if (!areFeedbacksEnabled()) return;

        // Check if playlist has received feedback
        let playlistHasFeedback = window.localStorage.getItem('feedbackPlaylist-' + playlistId + "." + useUserStore.getState().user?.userProfileId);
        if (!playlistHasFeedback || parseInt(playlistHasFeedback) < 3) {
            // Get video count for playlist
            const videoCount = window.localStorage.getItem('feedbackPlaylist-' + playlistId + "." + useUserStore.getState().user?.userProfileId) ?? '0';
            // if 3 videos have been watched
            if (parseInt(videoCount) === 2) {
                setTimeout(() => {
                    set({ isFeedbackModalVisible: true, currentFeedback: feedbackIds.video, feedbackData: "Video: " + title + " (ID: " + videoId + ")" });
                    window.localStorage.setItem('feedbackPlaylist-' + playlistId + "." + useUserStore.getState().user?.userProfileId, (parseInt(videoCount) + 1).toString());
                }, FEEDBACK_DELAY);
            } else {
                // if 4 videos have not been watched, update count
                window.localStorage.setItem('feedbackPlaylist-' + playlistId + "." + useUserStore.getState().user?.userProfileId, (parseInt(videoCount) + 1).toString());
            }
        }
    },
    checkPlatformFeedback: async () => {
        if (!areFeedbacksEnabled() || !useAuthStore.getState().isUser) return;
        const accesses = useUserStore.getState().user?.userProfileDetail.accesses;
        if (!accesses) return;
        if (accesses.length < 10) return;
        // check last time feedback was given
        let lastFeedback = window.localStorage.getItem(feedbackIds.platform + "." + useUserStore.getState().user?.userProfileId);
        if (!lastFeedback) {
            let lastFeedbackTmp = new Date();
            lastFeedbackTmp.setFullYear(lastFeedbackTmp.getFullYear() - 1);
            lastFeedback = lastFeedbackTmp.toISOString();
            window.localStorage.setItem(feedbackIds.platform + "." + useUserStore.getState().user?.userProfileId, lastFeedback);
        }
        let lastFeedbackDate = new Date(lastFeedback);
        let currentDate = new Date();
        // if more than 3 months have passed since last feedback
        if ((currentDate.getTime() - lastFeedbackDate.getTime()) > PLATFORM_FEEDBACK_INTERVAL) {
            setTimeout(() => {
                set({
                    isFeedbackModalVisible: true,
                    currentFeedback: feedbackIds.platform,
                    feedbackData: "Platform"
                });
                window.localStorage.setItem(feedbackIds.platform + "." + useUserStore.getState().user?.userProfileId, currentDate.toISOString());
            }, FEEDBACK_DELAY);
        }
    },
    onFeedbackSubmit: async (feedback: string) => {
        switch (feedback) {
            case feedbackIds.unit:
            case feedbackIds.course:
            default:
                break;
        }
        set({ isFeedbackModalVisible: false });
    }
}));