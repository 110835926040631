import QuizProgressIcon from "components/QuizProgressIcon";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import esp from "../../assets/images/xp.png";
import coriandoli from "../../assets/images/bg-coriandoli.png";

import { useEduquizStore } from "stores/eduquizStore";
import { usePartnerStore } from "stores/partnerStore";
import PartnerCard from "components/PartnerCard";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { useI18nStore } from "stores/i18nStore";
import { AmplitudeService } from "services/amplitudeService";
import { useFeedbackStore } from "stores/feedbackStore";
import Config from "utils/config";
export default function EduQuizEndView({ ...props }) {

    const selectedUnit = useEduquizStore(state => state.selectedUnit);
    const terminateQuiz = useEduquizStore(state => state.terminateQuiz);
    const partnerList = usePartnerStore(state => state.partnerList);
    const initPartnerStore = usePartnerStore(state => state.initPartnerStore);

    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);

    useEffect(() => {
        if (partnerList === null) {
            initPartnerStore();
        }
    }, [partnerList, initPartnerStore]);

    // on close, terminate the quiz
    React.useEffect(() => {
        return () => {
            terminateQuiz();
        }
    }, [terminateQuiz]);

    useEffect(() => {
        useFeedbackStore.getState().checkUnitFeedback(selectedUnit!.unitId, selectedUnit!.unitDetail.title.it);
    }, [selectedUnit]);

    const navigate = useNavigate();
    // get index of the current unit
    const unitIndex = selectedUnit!.unitDetail.module!.moduleDetail.units!.findIndex(unit => unit.unitId === selectedUnit!.unitId);
    // get the next unit
    const nextUnit = selectedUnit!.unitDetail.module!.moduleDetail.units![unitIndex + 1];


    useEffect(() => {
        AmplitudeService.logEvent("Unit completed", {
            unitId: selectedUnit!.unitId,
            moduleId: selectedUnit!.unitDetail.module!.moduleId,
            unitTitle: selectedUnit!.unitDetail.title.it,
            moduleTitle: selectedUnit!.unitDetail.module!.moduleDetail.title.it,
        });
        // if no nexts unit, module is completed
        if (!nextUnit) {
            AmplitudeService.logEvent("Module completed", {
                moduleId: selectedUnit!.unitDetail.module!.moduleId,
                moduleTitle: selectedUnit!.unitDetail.module!.moduleDetail.title.it,
            });
        }
    }, [nextUnit, selectedUnit]);




    const navigateToNextUnit = () => {
        // If there is no next unit, navigate to the module page
        if (!nextUnit) {
            navigate(-1);
            return;
        }
        navigate(`/welfare/eduquiz/module/${selectedUnit!.unitDetail.module!.moduleId}/unit/${nextUnit.unitId}`);
    }

    const color = (selectedUnit!.unitDetail.module!.moduleDetail.color ?? "").replace("0xFF", "#");
    if (!partnerList) return <Loader />;
    return (/*    <section className={`EduQuizEndView d-flex flex-column`}> */
        <section className={`EduQuizLearnView custom-height d-flex flex-column h-100 position-relative`}>
            <div className="d-flex align-items-center mb-4 mt-3 z-3">
                <i className="fa-duotone fa-times fa-2x me-4 cursor-pointer" onClick={() => navigate(-1)} />
                <h4 className="mb-0 h5 text-truncate">
                    {getTranslation(selectedUnit?.unitDetail?.title)}
                </h4>
            </div>

            <div className="row mb-3 mt-md-5 z-3" >
                <div className="col-12 col-md-6">
                    <div className="h-100">
                        <div className="card-body d-flex flex-column justify-content-evenly align-items-center align-items-md-start  mb-md-5 mb-md-0 mt-md-4">
                            <div className="ll mx-auto mb-3 d-block d-md-none">
                                <QuizProgressIcon color={color} percentage={100} icon={selectedUnit!.unitDetail.fontIcon} iconUrl={selectedUnit!.unitDetail.iconId} completed={selectedUnit!.unitDetail.userUnit!.userUnitDetail.finishedCorrectlyOnce} width={10} />
                            </div>
                            {nextUnit && <><h1 className="pe-md-5 display-4 text-md-start text-center mb-4">
                                {t('eduquiz:doing_great')} <br />{t('eduquiz:ready_for_next_unit')}
                            </h1>
                            </>}
                            {!nextUnit && <> <h1 className="pe-md-5 display-4 text-md-start text-center mb-3">
                                {t('eduquiz:module_completed')}
                            </h1>
                            </>}

                            <i className="fa-duotone fa-graduation-cap text-primary fa-6x d-none d-md-bock" style={{ fontSize: "19vw" }} />
                        </div>
                    </div>
                </div>


                <div className="col-12 col-md-6">
                    <div className="d-flex flex-column h-100">

                        <div className="flex-grow-md-1 d-flex flex-column">


                            <div className="card mb-3 flex-grow-md-0">
                                <div className="card-body pt-4">
                                    <div className="flex-grow-md-1 flex-shrink-0 position-relative " >
                                        <div className="ll mx-auto mb-3 d-none d-md-block">
                                            <QuizProgressIcon color={color} textIconClass="fs-1" percentage={100} icon={selectedUnit!.unitDetail.fontIcon} iconUrl={selectedUnit!.unitDetail.iconId} completed={selectedUnit!.unitDetail.userUnit!.userUnitDetail.finishedCorrectlyOnce} width={10} />
                                        </div>
                                    </div>
                                    <div className="card mb-3 ">
                                        <div className="card-body d-flex fw-bold align-items-center h4">
                                            <span>
                                                {t('eduquiz:rewards')}
                                            </span>
                                            <span className="fs-5 ms-auto" style={{
                                                // text outline effect
                                                WebkitTextStroke: "1px var(--bs-dark)",
                                                //font shadow black
                                                textShadow: "1px 1px 1px #000000",
                                                color: "var(--bs-white)"
                                            }}>
                                                +{selectedUnit!.unitDetail.userUnit!.userUnitDetail.gemsPrize}

                                                <img
                                                    src={Config.coinImage}
                                                    className="img-fluid ms-2 me-1"
                                                    alt="coin"
                                                    style={{ height: "1.4rem" }}
                                                ></img>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card fw-bold">
                                        <div className="card-body fw-bold d-flex align-items-center h4">
                                            <span>
                                                {t('common:xp')}
                                            </span>
                                            <span className="ms-auto fs-5"
                                                style={{
                                                    // text outline effect
                                                    WebkitTextStroke: "1px var(--bs-dark)",
                                                    //font shadow black
                                                    textShadow: "1px 1px 1px #000000",
                                                    color: "var(--bs-white)"
                                                }}
                                            >
                                                +{selectedUnit!.unitDetail.userUnit!.userUnitDetail.experiencePoints}
                                                {/* TODO: put Beewise xp counter if beewise theme */}
                                                <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 28 28"
                                                    className="img-fluid ms-2 me-1"
                                                    style={{ height: "1.4rem" }}
                                                >
                                                    <g id="cont-2">
                                                        <g id="icons-cont-2">
                                                            <g id="sack-dollar-duotone-2">
                                                                <rect id="Rettangolo_1962-2" data-name="Rettangolo 1962-2" x="0" width="24" height="24" style={{ fill: "none", strokeWidth: "0" }} />
                                                                <g id="XP"> 
                                                                    <path id="Xp_-_Contorno" data-name="Xp - Contorno" d="M15.1,21.1c-.5,0-1-.2-1.3-.5-.4-.3-.6-.8-.5-1.3v-10.7c0-.5.2-1,.5-1.3.4-.3.8-.5,1.3-.5.5,0,1,.2,1.3.5,0,0,.2.2.2.3.2-.1.3-.2.5-.3.6-.3,1.4-.5,2.1-.5.9,0,1.7.2,2.5.7.7.5,1.3,1.2,1.7,2,.4.9.6,1.8.6,2.8,0,1-.2,1.9-.6,2.8-.4.8-1,1.5-1.7,1.9-.7.5-1.6.7-2.5.7-.7,0-1.4-.2-2.1-.5,0,0-.1,0-.2-.1v2.2c0,.5-.2,1-.5,1.3-.4.3-.8.5-1.3.5ZM15.1,8.2c-.1,0-.3,0-.4.1,0,0-.1.2-.1.3v10.7c0,.1,0,.2.1.3.2.2.6.2.8,0,0,0,0,0,0-.3v-6.4c0-.2,0-.5,0-.7,0-.9.2-1.9.8-2.6,0-.1.2-.2.3-.3l-1-.2v-.5c0-.1,0-.2-.1-.3-.1,0-.2-.1-.4-.1ZM17.2,15.6c.2.2.4.3.6.5.4.2.9.3,1.4.3.6,0,1.2-.2,1.8-.5.5-.3.9-.8,1.2-1.4.3-.7.5-1.4.5-2.2,0-.8-.1-1.5-.5-2.2-.3-.6-.7-1.1-1.2-1.4-.5-.3-1.1-.5-1.8-.5-.5,0-1,.1-1.5.3-.2.1-.4.3-.6.5.4-.2.9-.3,1.4-.3.9,0,1.7.4,2.3,1,.5.8.8,1.7.8,2.6,0,.9-.2,1.8-.8,2.6-.6.7-1.4,1-2.3,1-.5,0-1,0-1.4-.3ZM18.6,10c-.5,0-.9.2-1.2.5-.3.5-.5,1.2-.5,1.8,0,.6.1,1.2.5,1.8.3.4.7.6,1.2.5.5,0,.9-.2,1.2-.5.3-.5.5-1.1.5-1.7,0-.6-.1-1.3-.5-1.8-.3-.4-.7-.6-1.2-.5h0ZM11.1,17.7c-.5,0-1-.2-1.3-.6h0s-3.3-4.1-3.3-4.1l-3.3,4.1c-.3.4-.8.6-1.3.6-.5,0-.9-.2-1.3-.5C.2,16.9,0,16.4,0,15.9c0-.4.2-.8.4-1.1l3.8-4.6L.6,5.8c-.3-.3-.5-.7-.4-1.2,0-.5.2-.9.5-1.3.3-.3.8-.5,1.3-.5.5,0,1,.2,1.3.6h0s3.1,3.9,3.1,3.9l3.1-3.9c.3-.4.8-.7,1.3-.7.5,0,.9.2,1.3.5.4.3.6.8.6,1.3,0,.4-.2.8-.4,1.2l-3.6,4.4,3.7,4.6c.3.3.4.7.5,1.1,0,.5-.2.9-.6,1.3-.3.3-.8.5-1.3.5ZM10.8,16.2c.2.2.3.2.3.2.1,0,.2,0,.3-.1,0,0,.1-.2.1-.3,0-.1,0-.2-.1-.3h0s-4.4-5.4-4.4-5.4l4.3-5.3c0,0,.1-.2.1-.3,0-.1,0-.2-.1-.3,0,0-.2-.1-.3-.1,0,0-.1,0-.3.2l-4.2,5.2L2.3,4.4c-.2-.2-.3-.2-.3-.2-.1,0-.2,0-.3.1,0,0-.1.2-.1.3,0,0,0,.2.1.3h0s4.3,5.3,4.3,5.3L1.5,15.6c0,0-.1.2-.1.3,0,.1,0,.2.1.3,0,0,.2.1.3.1.1,0,.2,0,.3-.2l4.4-5.4,4.3,5.4Z" style={{
                                                                        "fill": "#000",
                                                                        "strokeWidth": "0px"
                                                                    }} />
                                                                    <path id="p" d="M12.3,15.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.3-.3,0-.6-.2-.8-.4l-3.8-4.7-3.8,4.7c-.2.3-.5.4-.8.4-.3,0-.6-.1-.8-.3-.2-.2-.4-.5-.3-.8,0-.3.1-.5.3-.7l4.1-5L1.1,5.4c-.2-.2-.3-.5-.3-.7,0-.3.1-.6.3-.8.2-.2.5-.3.8-.3.3,0,.6.2.8.4l3.7,4.5,3.7-4.5c.2-.3.5-.4.8-.4.3,0,.6.1.8.3.2.2.3.5.3.8,0,.3,0,.5-.3.7l-3.9,4.8,4.1,5c.2.2.3.4.3.7" style={{
                                                                        fill: "var(--bs-primary)",
                                                                        stroke: "var(--bs-primary)",
                                                                        strokeWidth: "0px"
                                                                    }} />
                                                                    <path id="x" d="M21.4,8.1c.6.4,1.1,1,1.5,1.7.4.8.5,1.7.5,2.5,0,.9-.2,1.7-.5,2.5-.6,1.4-2,2.3-3.6,2.2-.6,0-1.2-.1-1.8-.4-.5-.3-.9-.7-1.2-1.1v3.8c0,.3-.1.6-.3.8-.2.2-.5.3-.8.3-.3,0-.6,0-.9-.3-.2-.2-.3-.5-.3-.8v-10.7c0-.3,0-.6.3-.8.2-.2.5-.3.8-.3.3,0,.6,0,.8.3.2.2.3.5.3.8v.5c.3-.5.7-.9,1.2-1.2.5-.3,1.2-.4,1.8-.4.7,0,1.5.2,2.1.6M20.4,14.5c.4-.6.7-1.4.6-2.2,0-.8-.2-1.6-.6-2.2-.4-.5-1.1-.8-1.7-.8-.7,0-1.3.3-1.7.8-.4.6-.7,1.4-.6,2.2,0,.8.2,1.5.6,2.2.4.5,1.1.8,1.7.8.7,0,1.3-.2,1.7-.8" style={{
                                                                        fill: "var(--bs-primary)",
                                                                        stroke: "var(--bs-primary)",
                                                                        strokeWidth: "0px"
                                                                    }} />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!nextUnit && <div style={{ marginBottom: "250px" }}>
                                <PartnerCard partner={partnerList[0]/* partnerList.find(partner => partner.partnerId === selectedUnit!.unitDetail.module.moduleDetail.description) */} className="mx-auto  d-block d-md-none" />
                            </div>}

                        </div>
                        <div className="flex-grow-md-0 pb-5 pb-md-0">
                            <div className="card d-none">
                                {nextUnit && <div className="card-body">
                                    <h5 className="mb-3">
                                        {t('eduquiz:next_unit')} {getTranslation(nextUnit?.unitDetail?.title)}
                                    </h5>
                                    <div className="d-flex align-items-center mt-auto d-none">
                                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto  " onClick={() => navigateToNextUnit()}>
                                            {t('common:continue')}
                                        </button>
                                    </div>
                                </div>}
                                {!nextUnit && <div className="card-body">
                                    <h5 className="mb-3">
                                        {t('eduquiz:module_completed')}
                                    </h5>
                                    <div className="d-flex align-items-center mt-auto d-none">
                                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto" onClick={() => navigateToNextUnit()}>
                                            {t('eduquiz:next_argument')}
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* SLIDE PAGE CONTROLS */}
            <div className="EduQuizEndViewSlideControls d-block d-md-flex z-3 flex-md-column justify-content-between align-items-start mt-auto w-100 py-4 fixed-bottom container-lg" style={{ bottom: "0" }}>
                {/* Page indicator */}
                {nextUnit && <h5 className="mb-4">
                    {t('eduquiz:next_unit')} {getTranslation(nextUnit?.unitDetail?.title)}
                </h5>}
                {!nextUnit && <h5 className="mb-4">
                    {t('eduquiz:module_completed')}
                </h5>}
                <div className="w-100 flex-md-row d-block d-md-flex flex-column align-items-center justify-content-between">
                    <div className=" d-flex card rounded-pill p-4 align-items-center flex-row justify-content-between ">
                        {/*Progress bar */}
                        <div className="W-100 col progress rounded-pill" style={{ width: "10rem", height: "1rem" }}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }} ></div>
                        </div>
                        <div className="ms-3 ">
                            {
                                t('eduquiz:questions_completed', { currentUnits: selectedUnit!.unitDetail.questions!.length, totalUnits: selectedUnit!.unitDetail.questions!.length })
                            }
                        </div>
                    </div>
                    <div className="d-grid gap-2 d-md-block mt-3 mt-md-0">
                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto btn-block" onClick={() => navigateToNextUnit()}>
                            {t('common:continue')}
                        </button></div>
                </div>
            </div>

            <img
                src={coriandoli}
                className="d-none d-md-block img-fluid top-0 start-0 position-fixed opacity opacity-50"
                alt="coin"

            ></img>

            <img src={coriandoli} className="h-50 img-fluid object-fit-cover position-fixed start-0 top-0 d-block d-md-none opacity-50" alt="coin" style={{ objectPosition: 'left' }} />




        </section >
    );
}