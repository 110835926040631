import React from 'react'

function SiteMaintenancePage() {
    return (
        <section className="section-border border-primary">

            <div className="container d-flex flex-column">

                <div className="row align-items-center justify-content-center gx-0 min-vh-100">

                    <div className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">




                        <img src="assets/img/illustrations/illustration-1.png" alt="..." className="img-fluid" />



                    </div>

                    <div className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">




                        <h1 className="display-3 fw-bold text-center">

                            Oooops...

                        </h1>




                        <p className="mb-5 text-center text-body-secondary">

                            ... è successo qualcosa di strano, prova a ricaricare la pagina...

                        </p>




                        <div className="text-center">

                            <a className="btn btn-primary rounded-pill" href="/">

                                Vai indietro

                            </a>

                        </div>



                    </div>

                </div>
            </div>
        </section>
    )
}

export default SiteMaintenancePage