import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useI18nStore } from 'stores/i18nStore';
import { useTranslation } from 'react-i18next';
import { useExpertsStore } from 'stores/expertsStore';
import StringParser from 'components/StringParser';
import { AmplitudeService } from 'services/amplitudeService';
import SecondaryHeader from 'components/SecondaryHeader';
import { useOrderStore } from 'stores/orderStore';
import { routes } from 'Routes';

function ExpertDetail() {
    const navigate = useNavigate();
    let { expertId } = useParams();
    const expertsList = useExpertsStore(state => state.expertsList);
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const orders = useOrderStore(state => state.orders);
    /*     useTitle(t('experts:title')); */
    const currentOrder = (orders ?? []).find(order => order.orderDetail.targetIds.filter(
        targetID => targetID.type === 'EXPERT' && targetID.value === expertId).length > 0);
    const hasFreeOrder = useOrderStore(state => state.hasFreeOrderForExpert);
    const isOrderPaid = currentOrder?.orderDetail.status === 'PAID';

    useEffect(() => {
        if (expertsList === null) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);

    useEffect(() => {
        if (!orders) {
            useOrderStore.getState().fetchOrders();
        } else {

        }
    }, [orders]);

    if (!expertsList) { return (<Loader />); }

    const expert = expertsList.find(expert => expert.expertId === expertId);
    if (!expert) {
        return (<Loader />);
    }
    return (
        <section className={`ExpertDetail d-flex flex-column mb-5 pb-5`}>
            {/* HEADER */}
            <SecondaryHeader title={
                getTranslation(expert.expertDetail.descriptionBrief)
            } /* backTarget=".." */ />
            <div className="nav mb-3">
                {
                    expert.expertDetail.category
                        .map((category, index) => (
                            <button key={index} className="btn btn-outline-primary border-0 rounded-pill me-2 mb-2"
                                style={{
                                    fontSize: "0.8rem",
                                    backgroundColor: "var(--bs-primary-subtle)",
                                    color: "var(--bs-primary)",
                                }}
                                    /* onClick={handleFilter}  */ value={category}>
                                {category}
                            </button>
                        ))
                }
            </div>
            {/* BODY */}
            <div className='card mb-5 '>
                <div className='card-body row'>
                    {/* BANNER IMAGE */}
                    <div className="col-12 col-md-3 mb-md-0 mb-4">
                        <img src={expert.expertDetail.img} className="img-fluid rounded-3 bg-primary-subtle" alt="Sponsor" style={{ width: "auto", height: "100%", maxHeight: "528px", objectFit: "cover" }} />
                    </div>
                    {/* DESCRIPTION */}
                    <div className="col-12 col-md-9 d-flex flex-column">
                        <h3 className='mb-2'>{t('shop:informations')}</h3>
                        <p className='mb-0'>
                            <StringParser text={getTranslation(expert.expertDetail.description)} />
                        </p>
                        <span className='text-end font-fancy text-primary fs-4 mt-auto'>{expert.expertDetail.name}
                        </span>
                    </div>
                </div>
            </div>
            {/* SPACER */}
            {/* FIXED MENU */}
            <div className="position-fixed d-flex flex-column justify-content-end align-items-center 
            bottom-0 start-0 w-100" style={{ zIndex: "3" }}>
                <div className={" card w-100 "}>
                    <div className="container-lg d-flex flex-row justify-content-center align-items-start card-body p-4">
                        <p className={"mb-3 fw-bold d-none"}></p>

                        {/* {logo && <img src={logo} alt="logo" className="mb-3" /> */}
                        {/*               {!expert.expertDetail.url.it.includes('ciaoelsa.com') &&
                            <PopupButton
                                url={getTranslation(expert.expertDetail.url) + "?primary_color=1cafb9"}
                                className={"btn btn-primary btn-block rounded-pill px-5 ms-auto"}
                                rootElement={document.getElementById("root")!}

                                prefill={{
                                    name: (useUserStore.getState().user?.userProfileDetail.name || '') + ' ' + (useUserStore.getState().user?.userProfileDetail.surname || ''),
                                    email: useUserStore.getState().user?.userProfileDetail.email || '',
                                }}
                                text={t('experts:book_now') + (remainingFreeBookings > 0 && " GRATIS")
                                } ></PopupButton>} */}
                        {/* {expert.expertDetail.url.it.includes('ciaoelsa.com') && <button onClick={() => {
                            AmplitudeService.logEvent('Expert Booking Started', { expertId: expert.expertId });
                            // open link in new tab
                            window.open(getTranslation(expert.expertDetail.url), '_blank');
                        }} className={"ms-auto btn btn-primary btn-block rounded-pill px-5 "}>{t('experts:book_now')}
                            {
                                remainingFreeBookings > 0 && "GRATIS"
                            }
                        </button>} */}
                        <button onClick={() => {
                            AmplitudeService.logEvent('Expert Booking Started', { expertId: expert.expertId });
                            // open link in new tab
                            /* window.open(getTranslation(expert.expertDetail.url), '_blank'); */
                            navigate(routes.expertBooking(expert.expertId));
                        }} className={"ms-auto btn btn-primary btn-block rounded-pill px-5 "}>{t(
                            isOrderPaid ? 'experts:book_now_pending' :
                                (hasFreeOrder(expert.expertId)
                                    ? 'experts:book_now_free' :
                                    'experts:book_now')
                        )}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExpertDetail