import exp from "constants";
import i18next, { use } from "i18next";
import toast from "react-hot-toast";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";
import { generateImageUrl } from "utils/functions";
import { useEffect, useState } from "react";
import { useCompanyStore } from "./companyStore";

export interface Document {
    format?: string;
    title: string;
    ownerId?: string;
    type: string;
    file?: File;
    category?: string;
    creationDate?: string;
    description?: {
        [key: string]: string;
    };
    image?: string;
    link?: string;
    multiLanguageTitle?: {
        [key: string]: string;
    };
    storagePath?: string;
    url?: string;
}
export interface DocumentObject {
    document: {
        category?: string;
        companyId?: string;
        creationDate?: string;
        description?: {
            [key: string]: string;
        };
        format?: string;
        image?: string;
        link?: string;
        multiLanguageTitle?: {
            [key: string]: string;
        };
        ownerId?: string;
        storagePath?: string;
        title?: string;
        type?: string;
        url?: string;
    };
    documentId: string;
}
export interface DocumentsStore {
    documents: { [key: string]: DocumentObject };
    getDocument: (documentId: string) => Promise<DocumentObject | null>;
    uploadDocument: (document: Document, companyId?: string) => Promise<DocumentObject>;
    downloadDocument: (documentId: string) => Promise<void>;
    searchDocuments: (ownerId?: string, type?: string) => Promise<DocumentObject[]>;
    deleteDocument: (documentId: string) => Promise<void>;
    updateDocument: (document: DocumentObject) => Promise<void>;
}

export const useDocumentStore = create<DocumentsStore>()((set, get, __) => ({
    documents: {},
    getDocument: async (documentId) => {
        const document = await ApiService.documents.getDocument(documentId);
        if (document != null) {
            set({
                documents: {
                    ...get().documents,
                    [documentId]: document
                }
            });
        }
        return document;
    },
    downloadDocument: async (documentId) => {
        try {
            var documentData = get().documents[documentId] ?? await get().getDocument(documentId);
            var result = await ApiService.documents.download(documentId);
            // download the result Blob
            const url = window.URL.createObjectURL(result);
            const a = document.createElement('a');
            a.href = url;
            //detect the filetype from blob
            a.download = documentData.document.title ?? "";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);


            /*             const doc = await get().getDocument(documentId);
                        if (doc == null) {
                            throw new Error('Document not found');
                        }
                      let a =  document.createElement('a'); 
                        let url = generateImageUrl(doc)!;
                        console.log('Downloading document', url);
                        a.href = url;
                        a.download = document.title;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url); */
        } catch (error) {
            console.log('Error downloading document', error);
            toast.error(`${i18next.t('documents:documentDownloadError')} ${error}`);
            throw error;
        }
    },
    uploadDocument: async (document, companyId) => {
        console.log('Uploading document', document);
        try {
            var loadToastId = toast.loading(i18next.t('documents:uploadingDocument'));
            const documentData = await ApiService.documents.postDocument({
                'format': document.format,
                'title': document.title,
                'ownerId': (useUserStore.getState().user?.userProfileId ?? ''),
                'type': document.type,
                'category': document.category,
                'creationDate': document.creationDate,
                'description': document.description,
                'image': document.image,
                'link': document.link,
                'multiLanguageTitle': document.multiLanguageTitle,
                'storagePath': document.storagePath,
                'url': document.url,
                'companyId': companyId ?? useUserStore.getState().user?.userProfileDetail?.companyId
            });
            if (documentData != null) {
                console.log('Document created', documentData)
                const documentId = documentData.documentId;
                // Upload the file to the document
                if (document.file == null) {
                    toast.dismiss(loadToastId);
                    const document = await ApiService.documents.getDocument(documentId);
                    if (document != null) {
                        set({
                            documents: {
                                ...get().documents,
                                [documentId]: document
                            }
                        });
                    }
                    return;
                }
                const fileUploadResult = await ApiService.documents.uploadFileToDocument(documentId, document.file);
                if (fileUploadResult != null) {
                    // then make it permanent
                    const makePermanentResult = await ApiService.documents.makeDocumentPermanent(useUserStore.getState().user!.userProfileId, documentId);
                    if (makePermanentResult != null) {
                        // fetch the new document from the server
                        const newDocument = await ApiService.documents.getDocument(documentId);
                        if (newDocument != null) {
                            toast.dismiss(loadToastId);
                            toast.success(i18next.t('documents:documentUploaded'));
                            get().documents[documentId] = newDocument;
                            set({
                                documents: get().documents
                            });
                            return newDocument;
                        }
                    } else {
                        throw new Error('Document make permanent failed');
                    }
                } else {
                    throw new Error('Document upload failed');
                }
            } else {
                throw new Error('Document creation failed');
            }
        } catch (error) {
            toast.dismiss();
            console.log('Error uploading document', error);
            toast.error(`${i18next.t('documents:documentUploadError')} ${error}`);
            throw error;
        }
    },
    searchDocuments: async (ownerId?: string, type?: string) => {
        const documents = await ApiService.documents.search({
            ownerId: ownerId,
            type: type
        });
        if (documents != null) {
            for (const document of documents) {
                get().documents[document.documentId] = document;
            }
            set({
                documents: get().documents
            });
        }
        return documents;
    },
    deleteDocument: async (documentId) => {
        try {
            const result = await ApiService.documents.deleteDocument(documentId);
            if (result != null) {
                delete get().documents[documentId];
                set({
                    documents: get().documents
                });
            } else {
                throw new Error('Document deletion failed');
            }
        } catch (error) {
            toast.dismiss();
            console.log('Error deleting document', error);
            toast.error(`${i18next.t('documents:documentDeleteError')} ${error}`);
            throw error;
        }
    },
    updateDocument: async (document) => {
        // Update documents metadata
        const updatedDocument = await ApiService.documents.putDocument(document);
        if (updatedDocument != null) {
            get().documents[document.documentId] = updatedDocument;
            set({
                documents: get().documents
            });
        }
    }
}));
// Custom hook for fetching data
export function useFetchDocument(documentId?: string) {
    const [document, setDocument] = useState<DocumentObject>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        if (documentId == null) {
            setLoading(false);
            return;
        }
        useDocumentStore.getState().getDocument(documentId).then((document) => {
            if (document == null) {
                throw new Error('Document not found');
            }
            setDocument(document);
            setLoading(false);
        }).catch((error) => {
            setError(error);
            setLoading(false);
        });

    }, [documentId]);

    return { document, loading, error };
}
