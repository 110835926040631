import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import Config, { appThemes } from "utils/config";
export interface QuizProgressIconProps {
    percentage: number;
    color: string | null;
    iconUrl?: string | null;
    icon?: string | null;
    width?: number;
    textIconClass?: string;
    completed?: boolean;
}
export default function QuizProgressIcon({ percentage, color, iconUrl, icon, textIconClass, width = 3, completed }: QuizProgressIconProps) {
    function getRgbFromHexColor(hexcolor = "#000000") {
        /*         if (hexcolor.length === 0) hexcolor = "FFFFFF"; */
        hexcolor = hexcolor.replace('#', '');
        var r = parseInt(hexcolor.substring(0, 2), 16);
        var g = parseInt(hexcolor.substring(2, 4), 16);
        var b = parseInt(hexcolor.substring(4, 6), 16);
        return 'rgba(' + r + ',' + g + ',' + b + ',';
    }
    // useState for showpercentage
    const [showPercentage, setShowPercentage] = React.useState(false);
    //useeffect for showpercentage, becomes true after half a second
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setShowPercentage(true);
        }, 10);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="rounded-circle p-1 position-relative" style={{ width: width + "rem", height: width + "rem", /* backgroundColor: quizModule.moduleDetail.color ? quizModule.moduleDetail.color.replace('0xFF', '#') : selectedArgument.argumentDetail.color.replace('0xFF', '#') */ }}>
            {/*   <CircularProgressBar>
            <div className="fs-3 bg-light rounded-circle p-1" style={{ width: "3rem", height: "3rem" }}>
                {quizModule.moduleDetail.fontIcon}
            </div>
        </CircularProgressBar> */}

            <CircularProgressbarWithChildren value={showPercentage ? percentage : 0} background={false} strokeWidth={10} styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.5,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Text size
                textSize: '52px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.35,


                // Colors
                pathColor: `${getRgbFromHexColor(color ?? "")} 1)`,
                backgroundColor: `${getRgbFromHexColor(color ?? "")} 1)`,
                trailColor: Config.appTheme === appThemes.SCHOOL ? 'rgb(255, 255, 255, 1)' : 'rgb(248, 249, 250, 1)',
            })}>
                {iconUrl && <img src={iconUrl} alt="icon" className="img-fluid w-75 p-2 object-fit-contain" />}
                {!iconUrl && <span className={textIconClass ? textIconClass : ""}
                    style={{
                        fontSize: (width / 3) + "rem",
                    }}>{icon}</span>}
            </CircularProgressbarWithChildren>

            {completed != null && <div className="position-absolute text-white fw-bold bg-light rounded-circle d-flex align-items-center justify-content-center " style={{
                bottom: "-15%",
                fontSize: (width / 6) + "rem",
                width: (width / 3.5) + "rem",
                height: (width / 3.5) + "rem",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}>
                <i className={"fas fa-star " + (completed ? "" : "text-muted")} style={{
                    color: (completed && color) ? color : "",
                }} />
            </div>}
        </div>
    );
}

export function QuizIcon({ iconUrl, icon, width }: { iconUrl?: string, icon?: string, width?: string }) {
    return (
        <div className="rounded-circle p-2 position-relative bg-body-tertiary d-flex align-items-center justify-content-center"
            style={{
                width: width ?? "3rem", height: width ?? "3rem"
                /* backgroundColor: quizModule.moduleDetail.color ? quizModule.moduleDetail.color.replace('0xFF', '#') : selectedArgument.argumentDetail.color.replace('0xFF', '#') */
            }}>
            {iconUrl && <img src={iconUrl} alt="icon" className="img-fluid object-fit-contain" />}
            {!iconUrl && <span className="fs-3">{icon}</span>}
        </div>
    );
}