import InfoButton from "components/InfoButton"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import ScrollContainer from "react-indiana-drag-scroll";
import SectionTitle from "components/SectionTitle";
import LearningMaterialsCard from "./LearningMaterialsCard";
import { useLearningMaterialsStore } from "stores/learningMaterialsStore";
import CircleButton from "components/CircleButton";
import { useTitle } from "utils/functions";
import SecondaryHeader from "components/SecondaryHeader";
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { useExpertsStore } from 'stores/expertsStore';
import { useI18nStore } from 'stores/i18nStore';
import PageTitle from 'components/PageTitle';
import { AnimatePresence, motion } from 'framer-motion';
import { Expert } from 'models/expertModel';
import { useIsMobile } from 'stores/appStore';

function LearningMaterialsPage() {
    const navigate = useNavigate();
    const [categories, setCategories] = React.useState<string[]>([]);
    const [filters, setFilter] = React.useState<string[]>([]);
    const isMobile = useIsMobile();
    const learningMaterials = useLearningMaterialsStore(state => state.learningMaterialsList);

    const [filteredMaterials, setFilteredMaterials] = React.useState<any[]>([]);
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    // useEffect for initShop
    useEffect(() => {
        if (learningMaterials === null) {
            useLearningMaterialsStore.getState().initLearningMaterialsStore();
        } else {
            if (categories.length === 0) {
                console.log(learningMaterials);
                // Appiattire l'array di array di stringhe in un unico array di stringhe
                const flattenedCategories = learningMaterials.flatMap((lm: any) => lm.category);
                // Rimuovere i duplicati
                const uniqueCategories = Array.from(new Set(flattenedCategories));
                setCategories(uniqueCategories as string[]);
                setFilter([]);
            }
        }
    }, [learningMaterials, categories]);

    useEffect(() => {
        if (learningMaterials) {
            if (filters.length === 0) {
                setFilteredMaterials([...learningMaterials]);
                return;
            }
            const filteredExperts = [];
            for (const lm of learningMaterials) {
                if (filters.includes(lm.category)) {
                    filteredExperts.push(lm);
                }
            }
            setFilteredMaterials([...filteredExperts]);
        }
    }, [filters, learningMaterials]);

    useTitle(t('experts:title'));

    if (!learningMaterials) { return (<Loader />); }


    const variantContainer = {
        "animate": {
            transition: {
                duration: 0.15,
                staggerChildren: 0.05,
                type: "spring",
                bounce: 0.5,
            }
        },
        "initial": {
            transition: {
                duration: 0.1,
            }
        }
    };
    const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedFilter = e.currentTarget.value;
        // Se il filtro selezionato è già presente nell'array filter, lo rimuovo
        if (filters.includes(selectedFilter)) {
            setFilter(filters.filter(f => f !== selectedFilter));
        } else {
            setFilter([...filters, selectedFilter]);
        }
    }
    return (
        <motion.section
            className={`ExpertsPage d-flex flex-column`}>
            <SecondaryHeader title={t('learningMaterials:title')} backTarget="/welfare/eduquiz">
                <InfoButton id={'videoPageInfo'} title={t('learningMaterials:info_modal_title')}>
                    <p>{t('learningMaterials:info_modal_text')}</p>
                </InfoButton>
            </SecondaryHeader>
            <h5>
                {t('learningMaterials:info_modal_text')}
            </h5>
            <div className="nav my-3">
                {
                    categories
                        .map((category, index) => (
                            <button key={index} className="btn btn-outline-primary rounded-pill me-2 mb-2"
                                style={{
                                    backgroundColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-white)",
                                    color: filters.includes(category) ? "var(--bs-white)" : "var(--bs-tertiary-color)",
                                    borderColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-tertiary-color)",
                                }}
                                onClick={handleFilter} value={category}>
                                {category}
                            </button>
                        ))
                }
            </div>
            <AnimatePresence mode='popLayout'>
                <motion.div
                    key={filters.length > 0 ? "expertsContainer" : filteredMaterials.length}
                    variants={variantContainer}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    transition={{
                        staggerChildren: 0.1,
                        duration: 0.2,
                    }}
                    className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1  g-3 mb-4" data-masonry="{`percentPosition`: true }"
                >
                    {
                        filteredMaterials && filteredMaterials.map(
                            (lm, index) =>
                                <LearningMaterialsCard learningMaterial={lm} key={lm.documentId} />
                        )}
                </motion.div>
            </AnimatePresence>
        </motion.section >

    )
}
export default LearningMaterialsPage
