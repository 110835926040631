
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import cloneDeep from "clone-deep";
import { useI18nStore } from "./i18nStore";
import { UserProfile } from "models/userModels";
interface UserStoreState {
    user: UserProfile | null;
    eduquizStatistics: any;
    initUser: (userAuthProfileId: string) => Promise<any | null>;
    getUserQuizStatistics: (userId?: string) => Promise<UserProgressionData | null>;
    editUserProfileDetail: (userProfileDetail: any) => Promise<void>;
}
export interface UserProgressionData {
    argumentsCompleted: number;
    modulesCompleted: number;
    unitsCompleted: number;
    argumentsTotal?: number;
    modulesTotal?: number;
    unitsTotal?: number;
    averageScore?: number;
}
export const useUserStore = create<UserStoreState>()((set, get, __) => ({
    user: null,
    eduquizStatistics: null,
    initUser: async (userAuthProfileId) => {
        try {
            let userResponse = await ApiService.user.getUserByAuthProfileId(userAuthProfileId);
            if (userResponse !== null) {
                set({ user: userResponse });
                if (!userResponse.userProfileDetail.locale) {
                    get().editUserProfileDetail({ locale: useI18nStore.getState().currentLanguage || 'en' });
                }
                if (userResponse.userProfileDetail.expertMeets === null || userResponse.userProfileDetail.expertMeets === undefined) {
                    get().editUserProfileDetail({ expertMeets: 1 });
                }
                return userResponse;
            } else {
                return null;
            }
        } catch (e) {
            console.log('Error fetching user', e);
            throw e;
        }
    },
    getUserQuizStatistics: async (userId?: string) => {
        try {
            let quizArguments = await ApiService.education.getUserArgumentsWithUnits(userId ?? get().user!.userProfileId);
            // loop through the arguments 
            let argumentsCompleted = 0;
            let modulesCompleted = 0;
            let unitsCompleted = 0;
            let averageScore = 0;
            for (let i = 0; i < quizArguments.length; i++) {
                if (quizArguments[i].argumentDetail.userArgument!.userArgumentDetail.percentageCompletion === 100) {
                    argumentsCompleted++;
                }
                for (let j = 0; j < (quizArguments[i].argumentDetail.modules ?? []).length; j++) {
                    if ((quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.userModule!.userModuleDetail.percentageCompletion === 100) {
                        modulesCompleted++;
                    }
                    for (let k = 0; k < (quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.units!.length; k++) {
                        if ((quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k]!.unitDetail.userUnit!.userUnitDetail.percentageCompletion === 100) {
                            unitsCompleted++;
                        }
                        if ((quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k]) {
                            const unit = (quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k];
                            const userUnit = unit!.unitDetail.userUnit;
                            const totalQuestions = unit?.unitDetail.questions?.length;
                            const correctQuestions = userUnit?.userUnitDetail.questionsCorrect.length;
                            if (totalQuestions && correctQuestions) {
                                averageScore += correctQuestions / totalQuestions;
                            }
                        }
                    }
                }
            }
            set({
                eduquizStatistics: {
                    argumentsCompleted: argumentsCompleted, modulesCompleted: modulesCompleted,
                    unitsCompleted: unitsCompleted,
                    argumentsTotal: quizArguments.length,
                    modulesTotal: quizArguments.reduce((acc, argument) => acc + argument.argumentDetail.modules!.length, 0),
                    unitsTotal: quizArguments.reduce((acc, argument) => acc + argument.argumentDetail.modules!.reduce((acc2, module) => acc2 + module.moduleDetail.units!.length, 0), 0),
                    averageScore: unitsCompleted ? averageScore / unitsCompleted : 0
                }
            });
            return get().eduquizStatistics as UserProgressionData;
        }
        catch (e) {
            console.error('Error getting user quiz statistics', e);
            return null;
        }
    },
    editUserProfileDetail: async (userProfileDetail) => {
        try {
            let newUser = get().user;
            if (newUser === null) {
                return;
            }
            newUser.userProfileDetail = { ...newUser.userProfileDetail, ...userProfileDetail };

            await ApiService.user.putUser(newUser);
            set({ user: cloneDeep(newUser) });
        } catch (e) {
            console.error('Error editing user profile detail', e);
        }
    }
}));
