import Lottie from "lottie-react";
import React, { useEffect } from 'react';
import loader from 'assets/lotti/load.json';
import lineloader from 'assets/lotti/lineloader.json';
import { AuthStates, useAuthStore } from "stores/authStore";
import Config, { appThemes } from "utils/config";
import FunnifinLogo from "./FunnifinLogo";
import AppThemed from "./AppThemed";
import PartnerLogo from "./PartnerLogo";

function Loader() {
    return (
        <div className='d-flex flex-column justify-content-center h-100 w-100 flex-fill align-items-center' style={{ overflow: "hidden" }}>
            <Lottie animationData={loader} loop={true} className="w-25 h-25" style={{ maxWidth: "80px", maxHeigh: "80px" }} />
        </div>
    )
}

/* Fixed loader display the loader as full screen, covering what is below. Meant to be used during initial page load */
export function FixedLoader() {
    const authState = useAuthStore((state) => state.authenthicationState);
    const [showLoader, setShowLoader] = React.useState(false);
    useEffect(() => {
        if ((authState !== AuthStates.Initial
            && authState !== AuthStates.LoggingOut
        ) && showLoader) {
            setTimeout(() => {
                document.getElementById("root-loader").classList.add("out");
                setTimeout(() => {
                    setShowLoader(false);
                }, 800);
            }, 10);
        } else {
            if ((authState === AuthStates.Initial || authState === AuthStates.LoggingOut
            ) && !showLoader) {
                setShowLoader(true);
            }
        }
    }, [authState, showLoader]);
    if (!showLoader) {
        return null;
    }
    return (
        <div className='d-flex flex-column justify-content-center h-100 w-100 align-items-center position-fixed z-5' style={{
            overflow: "hidden", top: "0",
            zIndex: "500000"
        }}>
            <style>
                {`

                @keyframes fadeIn {
                from {
                    opacity: 0.01;
                }

                to {
                    opacity: 1;
                }
                }

                @keyframes fadeOut {
                from { 
                    opacity: 1;
                }
                
                to { 
                    opacity: 0;
                }
                }

                @keyframes slideIn {
                from {
                    transform: translateY(20px);
                }

                to {
                    transform: translateY(0);
                }
                }

                @keyframes slideOut {
                from { 
                    transform: translateY(0);
                
                }

                to { 
                    transform: translateY(-20px);
                }
                }

                #close-screen {
                    height: 100%;
                    width: 100%;
                }

                #root-loader {
                height: 100%;
                width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 500000;
                background-color: var(--bs-body-bg);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                }

                .root-loader-inner {
                position: relative;
                }

                #root-loader.out {
                animation: 400ms ease-in-out 400ms 1 forwards fadeOut;
                opacity: 1;
                }

                #loader-bar {
                height: auto;
                margin-top: 16px; 
                }

                #loader-logo {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                transform: translateY(20px);
                position: absolute;
                bottom: 20px;
                animation: 400ms ease-in-out 1 forwards slideIn;
                }

                #root-loader.out #loader-logo {
                animation: 300ms ease-in-out 400ms 1 forwards slideOut;
                transform: translateY(0);
                }

                .renderer-in {
                animation: 400ms ease-in-out 800ms 1 normal forwards running fadeIn;
                opacity: 1;
                }
  
                `}
            </style>

            {/* <Lottie animationData={loader} loop={true} className="fixed-loader" style={{width: "80px" , heigh:"80px" }} /> */}
            <div id="root-loader">
                <div className="root-loader-inner mt-5  pt-5">
                    <AppThemed
                        themes={[
                            appThemes.CLASSIC,
                            appThemes.SCHOOL
                        ]}
                    >
                        <PartnerLogo className="loader-logo mt-5 mb-5" width="140px" />
                    </AppThemed>
                    <AppThemed
                        themes={[
                            appThemes.SIDEBARS
                        ]}
                    >
                        <FunnifinLogo className="loader-logo mt-5 mb-5" width="140px" />
                    </AppThemed>
                </div>
                <Lottie animationData={lineloader} loop={true} style={{
                    width: "190px",
                    marginTop: "-86px"
                }} />
            </div>
        </div>
    )
}

export default Loader