import { format } from "path";
import { create } from "./createStore";
import { useDocumentStore } from "./documentStore";
import { LearningMaterial } from "./learningMaterialsStore";
import { title } from "process";
import { ApiService } from "services/apiService";
import { useCompanyManagerStore } from "./companyManagerStore";
import { useAuthStore } from "./authStore";
import { useCompanyStore } from "./companyStore";

export interface LearningMaterialsManagerStore {
  learningMaterials: LearningMaterial[] | null;
  fetchLearningMaterials: () => Promise<void>;
  uploadLearningMaterial: (material: LearningMaterial, image?: File) => Promise<void>;
  updateLearningMaterial: (updatedMaterial: LearningMaterial) => Promise<void>;
  deleteLearningMaterial: (id: string) => Promise<void>;
}

export const useLearningMaterialsManagerStore = create<LearningMaterialsManagerStore>()((set) => ({
  learningMaterials: null,
  fetchLearningMaterials: async () => {
    try {
      const documents = await useDocumentStore.getState().searchDocuments(undefined, "learning_material");
      const learningMaterials = (documents ?? []).map((doc: any) => ({
        id: doc.documentId,
        title: doc.document.title,
        multiLanguageTitle: doc.document.multiLanguageTitle,
        description: doc.document.description,
        url: doc.document.url,
        category: doc.document.category,
        img: doc.document.image,
        icon: doc.document.icon,
        companyId: doc.document.companyId
      }));
      set({ learningMaterials });
    } catch (e) {
      console.log('Error fetching learning materials', e);
    }
  },
  uploadLearningMaterial: async (material, file) => {
    try {
      const documentData = {
        type: "learning_material",
        multiLanguageTitle: material.multiLanguageTitle,
        url: material.url,
        category: material.category,
        image: material.img,
        icon: material.icon,
        title: material.title,
        format: file?.type ?? "",
        companyId: material.companyId,
        file: file // Placeholder file, replace with actual file if needed
      };
      if (useAuthStore.getState().isSystem && !documentData.companyId) {
        let companies = useCompanyManagerStore.getState().companyList;
        if (!companies) {
          companies = await useCompanyManagerStore.getState().init();
        }
        for (const company of companies ?? []) {
          await useDocumentStore.getState().uploadDocument(documentData, company.companyId);
        }
      } else
        if (useAuthStore.getState().isSystem && documentData.companyId) {
          await useDocumentStore.getState().uploadDocument(documentData, documentData.companyId);
        } else {
          documentData.companyId = useCompanyStore.getState().currentCompany?.companyId;
          await useDocumentStore.getState().uploadDocument(documentData, documentData.companyId);
        }
      await useLearningMaterialsManagerStore.getState().fetchLearningMaterials();
    } catch (e) {
      console.log('Error uploading learning material', e);
    }
  },
  updateLearningMaterial: async (updatedMaterial) => {
    const oldFile = await ApiService.documents.downloadContent(updatedMaterial.storagePath);
    // make the oldfile blob into a file
    const oldFileBlob = new Blob([oldFile], { type: updatedMaterial.format });
    const actulOldFile = new File([oldFileBlob], updatedMaterial.title, { type: updatedMaterial.format });
    await useDocumentStore.getState().deleteDocument(updatedMaterial.id);
    try {
      const documentData = {
        type: "learning_material",
        multiLanguageTitle: updatedMaterial.multiLanguageTitle,
        url: updatedMaterial.url,
        category: updatedMaterial.category,
        image: updatedMaterial.img,
        icon: updatedMaterial.icon,
        format: actulOldFile.type,
        file: actulOldFile,
        title: updatedMaterial.title,
        companyId: updatedMaterial.companyId
      };

      await useDocumentStore.getState().uploadDocument(documentData, updatedMaterial.companyId);

      await useLearningMaterialsManagerStore.getState().fetchLearningMaterials();
    } catch (e) {
      console.log('Error updating learning material', e);
    }
  },
  deleteLearningMaterial: async (id) => {
    try {
      // First delete image if exists
      /*       const material = useLearningMaterialsManagerStore.getState().learningMaterials?.find((m) => m.id === id);
            if (material?.img) {
              await useDocumentStore.getState().deleteDocument(material.img);
            } */
      await useDocumentStore.getState().deleteDocument(id);
      await useLearningMaterialsManagerStore.getState().fetchLearningMaterials();
    } catch (e) {
      console.log('Error deleting learning material', e);
    }
  }
}));
