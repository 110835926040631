import React, { } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import { useSurveyStore } from 'stores/surveyStore';
import { Widget } from '@typeform/embed-react'
import { Button } from 'react-bootstrap';
import { useCompanyStore } from 'stores/companyStore';
import { useUserStore } from 'stores/userStore';
import { useQuery } from 'utils/functions';
import Config from 'utils/config';

function SurveyPage() {

    const onSurveyCompleted = useSurveyStore((state) => state.onSurveyCompleted);
    const hasDoneSurvey = useSurveyStore((state) => state.hasDoneMainSurvey);
    const currentCompany = useCompanyStore((state) => state.currentCompany);
    const userId = useUserStore((state) => state.user?.userProfileId);
    const query = useQuery();
    if (hasDoneSurvey) {
        if (query.get('redirect') === 'profile') {
            return <><Navigate to='/welfare/profile'></Navigate></>
        } else {
            return <><Navigate to={Config.userHomepagePath}></Navigate></>
        }
    }

    return <div className='h-100 w-100 bg-light d-flex flex-column'>
        <Widget id="t8WwZDWd"
            className="flex-fill"
            onClose={() => onSurveyCompleted('main')}
            onSubmit={() => onSurveyCompleted('main')}
            onEndingButtonClick={() => onSurveyCompleted('main')}
            hubspot={true}
            hidden={{
                company_id: currentCompany?.companyId ?? 'NO_COMPANY_ID',
                user_id: userId ?? 'NO_USER_ID'
            }}

        />
        <div className='pe-4 pb-3 d-flex w-100 d-none'>
            <Button className='ms-auto' onClick={() => onSurveyCompleted('main')}>Skip</Button>
        </div>
    </div>
}

export default SurveyPage;