import xp from "assets/images/xp.svg";
import React, { forwardRef } from 'react'
import { useExperiencePointsStore } from "stores/experiencePointsStore";

const XpCounter = forwardRef<HTMLDivElement>((props, ref) => {
  const currentExperiencePointData = useExperiencePointsStore(state => state.currentExperiencePointData);
  // init xp if currentExperiencePointData is null
  React.useEffect(() => {
    if (currentExperiencePointData === null) {
      useExperiencePointsStore.getState().getUserExperiencePoints();
    }
  }, [currentExperiencePointData]);

  return (
    <div ref={ref} className="XpCounter mx-2 d-flex">
      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ height: "1.5rem" }} className="me-1">
        <g id="cont-2">
          <g id="icons-cont-2">
            <g id="sack-dollar-duotone-2">
              <rect id="Rettangolo_1962-2" data-name="Rettangolo 1962-2" x="0" width="24" height="24" style={{ fill: "none", strokeWidth: "0" }} />
              <g id="XP">
                <path id="Xp_-_Contorno" data-name="Xp - Contorno" d="M15.1,21.1c-.5,0-1-.2-1.3-.5-.4-.3-.6-.8-.5-1.3v-10.7c0-.5.2-1,.5-1.3.4-.3.8-.5,1.3-.5.5,0,1,.2,1.3.5,0,0,.2.2.2.3.2-.1.3-.2.5-.3.6-.3,1.4-.5,2.1-.5.9,0,1.7.2,2.5.7.7.5,1.3,1.2,1.7,2,.4.9.6,1.8.6,2.8,0,1-.2,1.9-.6,2.8-.4.8-1,1.5-1.7,1.9-.7.5-1.6.7-2.5.7-.7,0-1.4-.2-2.1-.5,0,0-.1,0-.2-.1v2.2c0,.5-.2,1-.5,1.3-.4.3-.8.5-1.3.5ZM15.1,8.2c-.1,0-.3,0-.4.1,0,0-.1.2-.1.3v10.7c0,.1,0,.2.1.3.2.2.6.2.8,0,0,0,0,0,0-.3v-6.4c0-.2,0-.5,0-.7,0-.9.2-1.9.8-2.6,0-.1.2-.2.3-.3l-1-.2v-.5c0-.1,0-.2-.1-.3-.1,0-.2-.1-.4-.1ZM17.2,15.6c.2.2.4.3.6.5.4.2.9.3,1.4.3.6,0,1.2-.2,1.8-.5.5-.3.9-.8,1.2-1.4.3-.7.5-1.4.5-2.2,0-.8-.1-1.5-.5-2.2-.3-.6-.7-1.1-1.2-1.4-.5-.3-1.1-.5-1.8-.5-.5,0-1,.1-1.5.3-.2.1-.4.3-.6.5.4-.2.9-.3,1.4-.3.9,0,1.7.4,2.3,1,.5.8.8,1.7.8,2.6,0,.9-.2,1.8-.8,2.6-.6.7-1.4,1-2.3,1-.5,0-1,0-1.4-.3ZM18.6,10c-.5,0-.9.2-1.2.5-.3.5-.5,1.2-.5,1.8,0,.6.1,1.2.5,1.8.3.4.7.6,1.2.5.5,0,.9-.2,1.2-.5.3-.5.5-1.1.5-1.7,0-.6-.1-1.3-.5-1.8-.3-.4-.7-.6-1.2-.5h0ZM11.1,17.7c-.5,0-1-.2-1.3-.6h0s-3.3-4.1-3.3-4.1l-3.3,4.1c-.3.4-.8.6-1.3.6-.5,0-.9-.2-1.3-.5C.2,16.9,0,16.4,0,15.9c0-.4.2-.8.4-1.1l3.8-4.6L.6,5.8c-.3-.3-.5-.7-.4-1.2,0-.5.2-.9.5-1.3.3-.3.8-.5,1.3-.5.5,0,1,.2,1.3.6h0s3.1,3.9,3.1,3.9l3.1-3.9c.3-.4.8-.7,1.3-.7.5,0,.9.2,1.3.5.4.3.6.8.6,1.3,0,.4-.2.8-.4,1.2l-3.6,4.4,3.7,4.6c.3.3.4.7.5,1.1,0,.5-.2.9-.6,1.3-.3.3-.8.5-1.3.5ZM10.8,16.2c.2.2.3.2.3.2.1,0,.2,0,.3-.1,0,0,.1-.2.1-.3,0-.1,0-.2-.1-.3h0s-4.4-5.4-4.4-5.4l4.3-5.3c0,0,.1-.2.1-.3,0-.1,0-.2-.1-.3,0,0-.2-.1-.3-.1,0,0-.1,0-.3.2l-4.2,5.2L2.3,4.4c-.2-.2-.3-.2-.3-.2-.1,0-.2,0-.3.1,0,0-.1.2-.1.3,0,0,0,.2.1.3h0s4.3,5.3,4.3,5.3L1.5,15.6c0,0-.1.2-.1.3,0,.1,0,.2.1.3,0,0,.2.1.3.1.1,0,.2,0,.3-.2l4.4-5.4,4.3,5.4Z" style={{
                  "fill": "#000",
                  "strokeWidth": "0px"
                }} />
                <path id="p" d="M12.3,15.9c0,.3-.1.6-.3.8-.2.2-.5.3-.8.3-.3,0-.6-.2-.8-.4l-3.8-4.7-3.8,4.7c-.2.3-.5.4-.8.4-.3,0-.6-.1-.8-.3-.2-.2-.4-.5-.3-.8,0-.3.1-.5.3-.7l4.1-5L1.1,5.4c-.2-.2-.3-.5-.3-.7,0-.3.1-.6.3-.8.2-.2.5-.3.8-.3.3,0,.6.2.8.4l3.7,4.5,3.7-4.5c.2-.3.5-.4.8-.4.3,0,.6.1.8.3.2.2.3.5.3.8,0,.3,0,.5-.3.7l-3.9,4.8,4.1,5c.2.2.3.4.3.7" style={{
                  fill: "var(--bs-primary)",
                  stroke: "var(--bs-primary)",
                  strokeWidth: "0px"
                }} />
                <path id="x" d="M21.4,8.1c.6.4,1.1,1,1.5,1.7.4.8.5,1.7.5,2.5,0,.9-.2,1.7-.5,2.5-.6,1.4-2,2.3-3.6,2.2-.6,0-1.2-.1-1.8-.4-.5-.3-.9-.7-1.2-1.1v3.8c0,.3-.1.6-.3.8-.2.2-.5.3-.8.3-.3,0-.6,0-.9-.3-.2-.2-.3-.5-.3-.8v-10.7c0-.3,0-.6.3-.8.2-.2.5-.3.8-.3.3,0,.6,0,.8.3.2.2.3.5.3.8v.5c.3-.5.7-.9,1.2-1.2.5-.3,1.2-.4,1.8-.4.7,0,1.5.2,2.1.6M20.4,14.5c.4-.6.7-1.4.6-2.2,0-.8-.2-1.6-.6-2.2-.4-.5-1.1-.8-1.7-.8-.7,0-1.3.3-1.7.8-.4.6-.7,1.4-.6,2.2,0,.8.2,1.5.6,2.2.4.5,1.1.8,1.7.8.7,0,1.3-.2,1.7-.8" style={{
                  fill: "var(--bs-primary)",
                  stroke: "var(--bs-primary)",
                  strokeWidth: "0px"
                }} />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <h5 className="mb-0">{currentExperiencePointData?.experiencePointDetail.currentExperiencePoints ?? "   "}</h5>
    </div>
  )
})

export default XpCounter