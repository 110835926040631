import React from 'react'
interface MarkProps {
    className?: string
    background?: string
    borderColor?: string
    style?: React.CSSProperties,
    children: React.ReactNode
}

function Mark(props: MarkProps) {
    return (
        <div className={"Mark align-items-center justify-content-center fw-bolder border border-1 d-flex text-dark text-uppercase " 
        + (props.className ?? "") + " " 
        + (props.background ?? "bg-light") + " "
        + (props.borderColor ?? "border-dark")

        } style={{ borderRadius: '4px', height: "24px", padding: '0 9px', fontSize: '10px', lineHeight: "10px", display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.style }}>
            <span className="fw-bolder">
                {props.children}
            </span>
        </div>
    )
}

export default Mark