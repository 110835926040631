import { use } from 'i18next'
import React from 'react'
import { CloseButton } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { appThemes } from 'utils/config';
import AppThemed from './AppThemed';
import CircleButton from './CircleButton';
import InfoButton from './InfoButton'

function SecondaryHeader({ children = null, title = '', backTarget }: { children?: React.ReactNode, title: string, backTarget?: string }) {
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigateUp = () => {
        if (backTarget) {
            navigate(backTarget);
        } else {
            const pathSegments = location.pathname.split('/').filter(Boolean);
            if (pathSegments.length > 1) {
                const newPath = '/' + pathSegments.slice(0, -1).join('/');
                navigate(newPath);
            } else {
                navigate('/');
            }
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            (backTarget ? navigate(backTarget) : handleNavigateUp());
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='d-flex align-items-center mb-3' style={{
            minHeight: "48px",
        }}>
            <CloseButton onClick={() => { (backTarget ? navigate(backTarget) : handleNavigateUp()) }} />
            <h3 className="w-auto mb-0 ms-3 me-auto">{title}</h3>
            {children}
        </div>
    )
}

export default SecondaryHeader