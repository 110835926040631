import Loader from "components/Loader";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useI18nStore } from "stores/i18nStore";
import { useVideoStore } from "stores/videoStore";
import CircleButton from "components/CircleButton";
import { useTitle } from "utils/functions";
import VideoPlayer from "./VideoPlayer";
import { Playlist, PlaylistHistory, Video, VideoHistory } from "models/videoModels";
import { Badge, Card, Row } from "react-bootstrap";
import { useFeedbackStore } from "stores/feedbackStore";
import SecondaryHeader from "components/SecondaryHeader";
import { useIsMobile } from "stores/appStore";
import Vimeo from '@u-wave/react-vimeo'; // eslint-disable-line import/no-unresolved
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

export default function PlaylistPage() {
    let { playlistId } = useParams();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const playlists = useVideoStore(state => state.playlists);
    const { t } = useTranslation();
    const [currentVideo, setCurrentVideo] = React.useState<Video | null>(null);
    const [showModal, setShowModal] = React.useState(false);
    const [videoReady, setVideoReady] = React.useState(false);
    const userVideo = useVideoStore(state => state.userVideo);
    // get the playlist from the store
    useEffect(() => {
        if (!playlists) {
            useVideoStore.getState().initVideoStore();
        }
    }, [playlists, playlistId]);


    const playlist = useMemo(() => {
        return playlists?.find(p => p.playlistId === playlistId);
    }, [playlists, playlistId]);

    useTitle(t('videos:title'));
    const minutesOfPlaylist = (currentPlaylist: Playlist) => currentPlaylist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0);

    const lastVideoWatched = useMemo(() => {
        if (userVideo && playlist && userVideo.userVideoDetail.videoHistories[0]) {
            return userVideo.userVideoDetail.videoHistories[0].videoId;
        } else {
            return playlist?.playlistDetail.videos[0].videoId;
        }
    }, [userVideo, playlist]);

    const playlistHistory =
        playlist ? useVideoStore.getState().getPlaylistHistory(playlist.playlistId) : null;


    const wasWatchingPlaylist = useMemo(() => {
        return playlistHistory ? playlistHistory.watching : false;
    }, [playlistHistory]);

    if (!playlist) {
        return <Loader />
    }

    return (
        <section className="PlaylistPage d-flex flex-column pb-5">
            <SecondaryHeader title={""} backTarget="/welfare/playlists">
                {/* SHARE BUTTON */}
                <CircleButton
                    iconClassName="fas fa-share-from-square"
                    className="ms-auto"
                    iconVariant="secondary"
                    variant="transparent"
                    onClick={() => {
                        // Share the page
                        if (navigator.share) {
                            navigator.share({
                                title: getTranslation(playlist.playlistDetail.title),
                                text: getTranslation(playlist.playlistDetail.description),
                                url: window.location.href
                            })
                                .then(() => console.log('Successful share'))
                                .catch((error) => console.log('Error sharing', error));
                        } else {
                            // Fallback: copy the title to clipboard
                            navigator.clipboard.writeText(getTranslation(playlist.playlistDetail.title));
                            toast.success(t('common:copied_to_clipboard'));
                        }
                    }} />
            </SecondaryHeader>
            <div className="card ">
                <div className="card-body p-4">
                    <Row className="gx-0">
                        <div className="col-12 col-md-5 col-xl-4 ps-md-4 mb-4 mb-md-0 d-flex flex-column order-2 order-md-1">
                            <h3 className="pt-3">
                                {getTranslation(playlist?.playlistDetail?.title)}
                            </h3>
                            <h4 className="font-fancy mb-3">
                                by {getTranslation(playlist?.playlistDetail?.metadata)}
                            </h4>
                            <div>
                                {playlist && <div className='text-uppercase mb-4'>
                                    {playlist.playlistDetail.videos.length} {t('videos:lessons')} | {minutesOfPlaylist(playlist)} MIN
                                </div>}
                            </div>
                            <style>
                                {`.removePMargin p {
                                                        margin: 0;
                                                    }`}
                            </style>
                            <div className="removePMargin flex-fill flex-grow-1 mb-4 text-truncate-3" dangerouslySetInnerHTML={{ __html: getTranslation(playlist?.playlistDetail.description) }}>

                            </div>
                            <button className="btn btn-primary rounded-pill align-self-baseline" onClick={() => {
                                setShowModal(true);
                                setCurrentVideo(
                                    playlist.playlistDetail.videos.find(v => v.videoId === lastVideoWatched) ||
                                    playlist.playlistDetail.videos[0]
                                );
                            }}>
                                {t(wasWatchingPlaylist ? 'videos:continue_course' : 'videos:start_course')}
                            </button>
                        </div>
                        <div className="d-none d-md-block col-1 col-xl-2 order-2"></div>
                        <div className="col-12 col-md-6 order-1 order-md-3 mb-3 mb-md-0">
                            <Vimeo
                                video={playlist?.playlistDetail.videos[0].videoDetail.uri}
                                autoplay={true}
                                volume={0}
                                responsive
                                className='rounded-3'
                                style={{
                                    clipPath: "border-box",
                                    display: videoReady ? 'block' : 'none',

                                }}
                                onReady={() => { setVideoReady(true) }}
                            />
                            {!videoReady &&
                                <Skeleton height={"320px"} className='rounded-3'></Skeleton>}
                        </div>
                    </Row>
                    {/* PLAYLIST LIST */}
                    <Card className="bg-body-tertiary mt-5 border-0">
                        <Card.Body className="p-4">
                            <div className="text-uppercase pb-4">
                                {t('videos:all_lessons')}
                            </div>
                            <div className="d-flex flex-column remove-margin-bottom-last">
                                {playlist?.playlistDetail.videos?.map((video, index) => {
                                    const videoHistory = useVideoStore.getState().getVideoHistory(video.videoId);
                                    const playlistHistory = useVideoStore.getState().getPlaylistHistory(playlist.playlistId);
                                    return (<VideoComponent
                                        key={video.videoId}
                                        watched={videoHistory ? videoHistory.alreadyCompleted : false}
                                        videoHistory={videoHistory}
                                        playlistHistory={playlistHistory}
                                        video={video} showModal={showModal} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo} setShowModal={setShowModal} playlist={playlist} index={index} />);
                                })}
                                {/*                         {!playlist.playlistDetail.units && <div className="w-50 mx-auto"><Loader /></div>}
                        {playlist.playlistDetail.units && <TutorialTour tutorialName="modulePage" />} */}
                            </div>
                        </Card.Body>
                    </Card>

                </div>
            </div>
        </section >
    )
}
interface VideoProps {
    video: Video;
    showModal: boolean;
    currentVideo: Video | null;
    setCurrentVideo: (video: any) => void;
    setShowModal: (show: boolean) => void;
    playlist: Playlist;
    index: number;
    playlistHistory: PlaylistHistory;
    videoHistory: VideoHistory;
    watched: boolean;
}

const VideoComponent: React.FC<VideoProps> = ({
    video,
    showModal,
    currentVideo,
    setCurrentVideo,
    setShowModal,
    playlist,
    videoHistory,
    playlistHistory,
    watched,
    index
}) => {
    const isMobile = useIsMobile();
    const getTranslation = useI18nStore(state => state.getTranslation);
    /*     const [playlistHistory, setPlaylistHistory] = React.useState<PlaylistHistory | null>(null); */
    useEffect(() => {
        return () => {
            if (videoHistory && !showModal && videoHistory.watching) {
                videoHistory.watching = false;
                useVideoStore.getState().updateVideoHistory(videoHistory);
                console.log('videoHistory SECONDARY END - REMOVE WATCHING');
                console.log(JSON.stringify(videoHistory));  
            }
        }
    }, [showModal, videoHistory]);
    return (
        <div key={video.videoId} className={"d-flex flex-column cursor-pointer flex-md-row w-100 justify-content-start align-items-start mb-5 position-relative"}>
            <VideoPlayer video={video}
                showPlayer={showModal && currentVideo?.videoId === video.videoId}
                closePlayer={() => setShowModal(false)}
                author={playlist.playlistDetail.metadata?.it}
                onVideoPlay={() => {
                    if (videoHistory) {
                        videoHistory.watching = true;
                        useVideoStore.getState().updateVideoHistory(videoHistory);
                    }
                    if (playlistHistory) {
                        playlistHistory.watching = true;
                        useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                    }
                }}
                onVideoIsPlaying={async (data) => {
                    if (videoHistory) {
                        videoHistory.watching = true;
                        videoHistory.percentage = data.percentage;
                        videoHistory.seconds = Math.floor(data.seconds);
                        await useVideoStore.getState().updateVideoHistory(videoHistory);
                        console.log(JSON.stringify(videoHistory));
                    }
                    if (playlistHistory) {
                        playlistHistory.watching = true;
                        await useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                    }
                }}
                onNextVideoRequested={
                    playlist.playlistDetail.videos.length > index + 1 ?
                        () => {
                            const index = playlist.playlistDetail.videos.findIndex(v => video.videoId === v.videoId);
                            if (index < playlist.playlistDetail.videos.length - 1) {
                                setCurrentVideo(playlist.playlistDetail.videos[index + 1]);
                            } else {
                                setCurrentVideo(playlist.playlistDetail.videos[0]);
                                setShowModal(false);
                            }
                        } : undefined
                }
                onVideoEnd={(videoId) => {
                    if (videoHistory) {
                        const watchedSeconds = videoHistory.seconds;
                        videoHistory.alreadyCompleted = true;
                        videoHistory.watching = false;
                        videoHistory.seconds = 0;
                        videoHistory.percentage = 1;
                        videoHistory.totalWatchTime = (videoHistory.totalWatchTime ?? 0) + watchedSeconds;
                        console.log(JSON.stringify(videoHistory));
                        useVideoStore.getState().updateVideoHistory(videoHistory);
                    }
                    if (playlistHistory) {
                        if (playlistHistory.videoIds.indexOf(videoId) === -1) {
                            playlistHistory.videoIds.push(videoId);
                        }
                        if (playlistHistory.videoIds.length === playlist.playlistDetail.videos.length) {
                            playlistHistory.alreadyCompleted = true;
                        }
                        /* console.log('playlistHistory', playlistHistory); */
                        useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                    }
                    const index = playlist.playlistDetail.videos.findIndex(video => video.videoId === videoId);
                    if (index < playlist.playlistDetail.videos.length - 1) {
                        setCurrentVideo(playlist.playlistDetail.videos[index + 1]);
                    } else {
                        useFeedbackStore.getState().checkCourseFeedback(playlist.playlistId, playlist.playlistDetail.title.it);
                        setCurrentVideo(playlist.playlistDetail.videos[0]);
                        setShowModal(false);
                    }
                }}
            >
                {
                    watched &&
                    <Badge className="position-absolute top-0 start-0 mt-5 ms-5" bg="primary" style={{ zIndex: 1000 }}>
                        GUARDATO
                    </Badge>}
                <img
                    onClick={() => {
                        setCurrentVideo(video);
                        setShowModal(true);
                    }}
                    src={video.videoDetail.thumbnail} alt="thumbnail" className="me-3 mb-3 mb-md-0 img-fluid rounded-3 border border-body " style={{
                        width: "auto",
                        objectFit: "cover",
                        objectPosition: "left",
                        height: isMobile ? "100%" : "83px",
                    }} />

                <div onClick={() => {
                    setCurrentVideo(video);
                    setShowModal(true);
                }} className="d-flex flex-column justify-content-between align-items-start p-md-2 flex-fill">
                    <div className="d-flex justify-content-between w-100">
                        <h5 className='mb-0'>{getTranslation(video.videoDetail.title)}</h5>
                        <small style={{
                            minWidth: "46px",
                            textAlign: "right"
                        }}>
                            {video.videoDetail.minutes} min
                        </small>
                    </div>
                    <div className='m-0 mb-3 text-truncate-1' dangerouslySetInnerHTML={{ __html: getTranslation(video.videoDetail.description) }}></div>
                    <div className="W-100 progress rounded-pill bg-light" style={{ width: "100%", height: "0.5rem" }}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{
                            width: videoHistory ? (
                                (videoHistory.alreadyCompleted && !videoHistory.watching) ? "100%" :
                                    videoHistory.percentage * 100 + "%") :
                                "0%"
                        }} ></div>
                    </div>
                </div>
            </VideoPlayer>
        </div>
    );
};