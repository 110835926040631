import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";
import { useLifepointsStore } from "./lifepointsStore";
import { useExperiencePointsStore } from "./experiencePointsStore";
import { useGemsStore } from "./gemsStore";
import cloneDeep from "clone-deep";
import { useCompanyStore } from "./companyStore";
import { AmplitudeService } from "services/amplitudeService";
import { QuizAnswer, QuizArgument, QuizModule, QuizUnit } from "models/eduquizModels";
import Config, { appThemes } from "utils/config";

export interface EduquizStore {
    argumentList: null | QuizArgument[];
    quizState: any;
    currentQuestionIndex: number;
    currentSlideIndex: number;
    isInitInProgress: boolean;
    initEduquiz: (companyId: string | null) => Promise<any>;
    selectedArgument: QuizArgument | null;
    setSelectedArgument: (argument: QuizArgument) => void;
    selectedModule: QuizModule | null;
    setSelectedModule: (module: any) => void;
    selectedUnit: QuizUnit | null;
    setSelectedUnit: (unit: QuizUnit) => void;
    getModule: (moduleId: string) => Promise<any>;
    getUnit: (unitId: string) => Promise<any>;
    initGame: () => Promise<void>;
    startGame: () => void;
    nextSlide: () => void;
    prevSlide: () => void;
    nextQuestion: () => void;
    prevQuestion: () => void;
    giveAnswer: (answer: QuizAnswer) => Promise<void>;
    saveSlideRead: () => Promise<void>;
    terminateQuiz: () => void;
    isAnswerCorrect: (answer: QuizAnswer) => boolean;
    setSlide: (index: number) => void;
    handleFirstInit: () => Promise<void>;
}

export const EduQuizGameStates = {
    learning: Symbol("learning"),
    gaming: Symbol("gaming"),
    ending: Symbol("ending"),
    gameOver: Symbol("gameOver"),
    initial: Symbol("initial"),
    ready: Symbol("ready")
}
export const useEduquizStore = create<EduquizStore>()((set, get, __) => ({
    argumentList: null,
    quizState: EduQuizGameStates.initial,
    currentQuestionIndex: 0,
    currentSlideIndex: 0,
    isInitInProgress: false,
    initEduquiz: async () => {
        try {
            if (useUserStore.getState().user === null) {
                console.error('🎰 User not logged in');
                return null;
            }

            // Check if initialization is already in progress
            if (get().isInitInProgress) {
                return;
            }
            const firstInit = get().argumentList === null /* false */;
            // Set flag to indicate that initialization is in progress
            set({ isInitInProgress: true });

            //Get data in lifepoint store
            useLifepointsStore.getState().refreshLifePoints();
            // Get data in experiencepoint store
            useExperiencePointsStore.getState().refreshExperiencePoints();
            // Refresh data in gems store
            useGemsStore.getState().refreshGems();

            if (firstInit) {
                // If this is the first initialization, call handleFirstInit
                await get().handleFirstInit();
            } else {
                console.log('🎰 not first init, doing nothing');
            }

            /*             // Get the list of arguments from the API
                        let argumentList = await ApiService.education.getUserArguments(useUserStore.getState().user!.userProfileId!, 1, firstInit ? 3 : 99999);
                        // If the result is not null, set the state of the argumentList to the result
                        if (argumentList !== null) {
                            set({ argumentList: argumentList, selectedArgument: argumentList[0] });
            
                            // Return the result
                            return argumentList;
                            // If the result is null, return null
                        } else {
                            return null;
                        } */
            // If the API call fails, log the result to the console and return null
        } catch (e) {
            console.log('🎰 ', e)
            throw Error('Error in initEduquiz');
        } finally {
            // Set flag to indicate that initialization is complete
            set({
                isInitInProgress: false,
                quizState: EduQuizGameStates.ready
            });
        }
    },
    handleFirstInit: async () => {
        if (!useUserStore.getState().user) {
            throw Error('User not logged in');
        }
        if (useUserStore.getState().user?.userProfileId === null) {
            throw Error('User profile id is null');
        }

        //first we get all arguments, without details
        return ApiService.education.getUserArguments(useUserStore.getState().user!.userProfileId!, 0, 99999).then(async (arguList) => {
            set({ selectedArgument: arguList[0] ?? null, argumentList: (arguList) });
            for (const arg of arguList) {
                const arguData = await ApiService.education.getUserArgument(arg.argumentId, useUserStore.getState().user!.userProfileId!);
                set({ argumentList: cloneDeep(get().argumentList?.map((arg) => arg.argumentId === arguData.argumentId ? arguData : arg)) });
                if (!get().selectedArgument) {
                    const initialArgument = {
                        ...arguList[0],
                        ...arguData
                    };
                    set({ selectedArgument: initialArgument, argumentList: (arguList) });
                }
            }
            set({ isInitInProgress: false });
            /* 
                        ApiService.education.searchUserArguments(useUserStore.getState().user!.userProfileId!, 0, 3).then(async (args) => {
            
                            // merge the argument list by id
                            set({
                                selectedArgument: initialArgument, argumentList: (arguList ?? []).map((arg: QuizArgument) => {
                                    const foundArg = args.find((a: QuizArgument) => a.argumentId === arg.argumentId);
                                    return foundArg ? foundArg : arg;
                                })
                            });
                            let index = 3;
                            do {
                                let res = await ApiService.education.searchUserArguments(useUserStore.getState().user!.userProfileId!, index, 1)
                                set({ argumentList: get().argumentList?.map((arg) => arg.argumentId === res[0].argumentId ? res[0] : arg) });
                                index++;
                            } while (index < arguList.length);
                        }); */
        });
        /*         await ApiService.education.searchUserArguments(useUserStore.getState().user!.userProfileId!, 0, 1).then((args) => {
        
                }); */
        /* 
                // Get the list of arguments from the API, with an increasing limit of 3 until null result
                // loop calls to getUserArguments increasing start and limit until null result
                let start = 4;
                let result = null;
                do {
                    result = await ApiService.education.getUserArguments(useUserStore.getState().user!.userProfileId!, start, 1);
                    if (result.length > 0) {
                        start += 3;
                        set({ argumentList: [...get().argumentList ?? [], ...result] });
                    }
                } while (result.length > 0); */
    },
    selectedArgument: null,
    setSelectedArgument: (argument) => set({ selectedArgument: cloneDeep(argument) }),
    selectedModule: null,
    setSelectedModule: (module) => set({ selectedModule: module }),
    selectedUnit: null,
    setSelectedUnit: (unit) => set({ selectedUnit: Object.assign({}, unit) }),
    getModule: async (moduleId) => {
        var module = await ApiService.education.getUserModule(moduleId, useUserStore.getState().user?.userProfileId!);
        set({ selectedModule: module });
        return module;
    },
    getUnit: async (unitId) => {
        var unit = await ApiService.education.getUserUnit(unitId, useUserStore.getState().user?.userProfileId!);
        set({ selectedUnit: Object.assign({}, unit) });
        get().initGame();
        return unit;
    },
    initGame: async () => {
        if (get().selectedUnit === null) {
            throw Error('No unit selected');
        }

        // if game state is initial, init Eduquiz
        /*         if (get().quizState === EduQuizGameStates.initial) {
                    await get().initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? null);
                } */

        // if lifepoints are 0, game over
        if (useLifepointsStore.getState().currentLifepointData?.lifePointDetail?.currentLifePoints <= 0 && Config.appTheme !== appThemes.SCHOOL) {
            set({ quizState: EduQuizGameStates.gameOver })
            return;
        }
        console.log(get().selectedUnit!.unitDetail.module!.moduleDetail.argument);
        get().setSelectedArgument(get().selectedUnit!.unitDetail.module!.moduleDetail.argument!);
        if (get().selectedUnit!.unitDetail.questions![0].questionDetail.userQuestion!
            .userQuestionDetail.answered) {
            // find the first not answered question
            let firstNotAnsweredQuestionIndex = get().selectedUnit!.unitDetail.questions!.findIndex(question => {
                return !question.questionDetail.userQuestion!.userQuestionDetail.answered;
            });
            if (firstNotAnsweredQuestionIndex === -1) {
                firstNotAnsweredQuestionIndex = get().selectedUnit!.unitDetail.questions!.length;
            }
            AmplitudeService.logEvent('Question Read',
                {
                    unitId: get().selectedUnit!.unitId, questionId: get().selectedUnit!.unitDetail.questions![firstNotAnsweredQuestionIndex].questionDetail.unitId,
                    unitTitle: get().selectedUnit!.unitDetail.title.en,
                    questionTitle: get().selectedUnit!.unitDetail.questions![firstNotAnsweredQuestionIndex].questionDetail.title.it
                });
            // game starts
            set({ quizState: EduQuizGameStates.gaming, currentQuestionIndex: firstNotAnsweredQuestionIndex });
        } else {
            // if first slide is not completed, start at first slide
            if (!get().selectedUnit!.unitDetail.slides![0].slideDetail.userSlide!.userSlideDetail.completed) {
                AmplitudeService.logEvent('Slide Read',
                    {
                        unitId: get().selectedUnit!.unitId, slideId: get().selectedUnit?.unitDetail?.slides![0]?.slideId,
                        unitTitle: get().selectedUnit?.unitDetail?.title?.it,
                        slideTitle: get().selectedUnit!.unitDetail.slides![0]?.slideDetail?.title?.it ?? 'Slide Iniziale'
                    });
                set({ quizState: EduQuizGameStates.learning, currentSlideIndex: 0 });
            } else {
                // else get index of the first not completed slide
                let firstNotCompletedSlideIndex = get().selectedUnit!.unitDetail.slides!.findIndex(slide => {
                    return !slide.slideDetail.userSlide!.userSlideDetail.completed;
                });
                if (firstNotCompletedSlideIndex === -1) {
                    firstNotCompletedSlideIndex = get().selectedUnit!.unitDetail.slides!.length;
                }
                AmplitudeService.logEvent('Slide Read',
                    {
                        unitId: get().selectedUnit!.unitId, slideId: get().selectedUnit?.unitDetail?.slides![firstNotCompletedSlideIndex]?.slideId,
                        unitTitle: get().selectedUnit?.unitDetail?.title?.it,
                        slideTitle: get().selectedUnit!.unitDetail.slides![firstNotCompletedSlideIndex]?.slideDetail?.title?.it ?? 'Slide Finale'
                    });

                set({ quizState: EduQuizGameStates.learning, currentSlideIndex: firstNotCompletedSlideIndex });
            }
        }
    },
    /* 
        SLIDE CONTROLS
    */
    nextSlide: () => {
        if (get().currentSlideIndex < get().selectedUnit!.unitDetail.slides!.length) {
            if (get().currentSlideIndex !== get().selectedUnit!.unitDetail.slides!.length) {
                // if the current slide is not completed:
                if (!get().selectedUnit!.unitDetail.slides![get().currentSlideIndex].slideDetail.userSlide!.userSlideDetail.completed) {
                    // set the current slide to completed
                    get().saveSlideRead()
                }
            }
            set({ currentSlideIndex: get().currentSlideIndex + 1 });
            AmplitudeService.logEvent('Slide Read',
                {
                    unitId: get().selectedUnit!.unitId, slideId: get().selectedUnit?.unitDetail?.slides![get().currentSlideIndex]?.slideId,
                    unitTitle: get().selectedUnit?.unitDetail?.title?.it,
                    slideTitle: get().selectedUnit!.unitDetail.slides![get().currentSlideIndex]?.slideDetail?.title?.it ?? 'Slide Finale'
                });
        } else {
            // TODO
            console.log('🎰 start quiz');
        }
    },
    prevSlide: () => {
        if (get().currentSlideIndex > 0) {
            set({ currentSlideIndex: get().currentSlideIndex - 1 });
        }
    },
    setSlide: (index) => {
        set({ currentSlideIndex: index });
        AmplitudeService.logEvent('Slide Read',
            {
                unitId: get().selectedUnit!.unitId, slideId: get().selectedUnit?.unitDetail?.slides![get().currentSlideIndex]?.slideId,
                unitTitle: get().selectedUnit?.unitDetail?.title?.it,
                slideTitle: get().selectedUnit!.unitDetail.slides![get().currentSlideIndex]?.slideDetail?.title?.it ?? 'Slide Finale'
            });
    },
    /* 
        Called on start quiz click from endslide only
        TODO: maybe make it unified start point of quiz
    */
    startGame: () => {
        AmplitudeService.logEvent('Question Read',
            {
                unitId: get().selectedUnit!.unitId, questionId: get().selectedUnit!.unitDetail.questions![0].questionDetail.unitId,
                unitTitle: get().selectedUnit!.unitDetail.title.en,
                questionTitle: get().selectedUnit!.unitDetail.questions![0].questionDetail.title.it
            });
        set({ quizState: EduQuizGameStates.gaming, currentQuestionIndex: 0 });
    },
    /* 
    QUESTION CONTROLS
    */
    prevQuestion: () => {
        if (get().currentQuestionIndex > 0) {
            set({ currentQuestionIndex: get().currentQuestionIndex - 1 });
        }
    },
    nextQuestion: () => {
        if (get().currentQuestionIndex < get().selectedUnit!.unitDetail.questions!.length - 1) {
            set({ currentQuestionIndex: get().currentQuestionIndex + 1 });
            AmplitudeService.logEvent('Question Read',
                {
                    unitId: get().selectedUnit!.unitId, questionId: get().selectedUnit!.unitDetail.questions![get().currentQuestionIndex].questionDetail.unitId,
                    unitTitle: get().selectedUnit!.unitDetail.title.en,
                    questionTitle: get().selectedUnit!.unitDetail.questions![get().currentQuestionIndex].questionDetail.title.it
                });
        }
    },
    /* 
        Called on answer click
        Saves the answer to the API
    */
    giveAnswer: async (answer) => {
        let newArgument = cloneDeep(get().selectedArgument)!;
        // get index of get().selectedUnit!.unitDetail.module!.moduleId
        let moduleIndex = newArgument.argumentDetail.modules!.findIndex((mod) => mod!.moduleId === get().selectedUnit!.unitDetail.module!.moduleId);
        // refactor the argument by adding the module to it and the unit
        newArgument.argumentDetail.modules = [Object.assign({}, newArgument.argumentDetail.modules![moduleIndex])];
        newArgument.argumentDetail.modules[0].moduleDetail.units = [cloneDeep(get().selectedUnit)!];
        // get reference to the unit
        let newUnit = newArgument.argumentDetail.modules![0].moduleDetail.units![0];
        //if not last question
        if (get().currentQuestionIndex !== get().selectedUnit!.unitDetail.questions!.length - 1) {
            // Set the unit to be completed
            newArgument!.argumentDetail.userArgument!.userArgumentDetail.unitNotCompletedId = get().selectedUnit!.unitId;
        } else {
            // clear the pending unit
            newArgument!.argumentDetail.userArgument!.userArgumentDetail.unitNotCompletedId = null;
        }
        // remove unnecessary data
        delete newUnit.unitDetail.module;
        delete newUnit.unitDetail.slides;
        // Set the question to answered
        newUnit!.unitDetail.userUnit!.userUnitDetail.lastQuestionId = get().selectedUnit!.unitDetail.questions![get().currentQuestionIndex].questionDetail.unitId;
        // get regerence to the question
        let newQuestion = newUnit.unitDetail.questions![get().currentQuestionIndex];
        newUnit.unitDetail.questions = [newQuestion];
        // set answer id to lastAnswerIds in userQuestionDetail
        newQuestion.questionDetail.userQuestion!.userQuestionDetail.lastAnswerIds = [answer.answerId];
        // get referenceto answer
        let newAnswer = Object.assign({}, answer);
        // set userLastChoice to true
        newAnswer.answerDetail.userAnswer!.userAnswerDetail.userLastChoice = true;
        newQuestion.questionDetail.answers = [newAnswer];
        AmplitudeService.logEvent('Question Answered',
            {
                unitId: get().selectedUnit!.unitId, questionId: get().selectedUnit!.unitDetail.questions![get().currentQuestionIndex].questionDetail.unitId,
                unitTitle: get().selectedUnit!.unitDetail.title.it,
                questionTitle: get().selectedUnit!.unitDetail.questions![get().currentQuestionIndex].questionDetail.title.it,
                answerId: answer.answerId, correct: get().isAnswerCorrect(answer),
                userAnswer: answer.answerDetail.title.it
            });
        // if is the last question
        if (get().currentQuestionIndex === get().selectedUnit!.unitDetail.questions!.length - 1) {
            // wait for save before proceeding
            await ApiService.education.putArgument(newArgument!);
            // wait 0.3 seconds (to ensure proper save)
            await new Promise(resolve => setTimeout(resolve, 300));
            // wait the new unit data
            let newUnitData = await ApiService.education.getUserUnit(get().selectedUnit!.unitId, useUserStore.getState().user?.userProfileId!);
            //Get data in lifepoint store
            if (Config.appTheme !== appThemes.SCHOOL) {
                await useLifepointsStore.getState().refreshLifePoints(get().isAnswerCorrect(answer) ? useLifepointsStore.getState().currentLifepointData.lifePointDetail.currentLifePoints : useLifepointsStore.getState().currentLifepointData.lifePointDetail.currentLifePoints - 1);
                await useGemsStore.getState().refreshGems();
            }
            // Get data in experiencepoint store
            await useExperiencePointsStore.getState().refreshExperiencePoints();
            // Refresh data in gems store
            // End the game
            const freshNewArgument = await ApiService.education.getUserArgument(newUnitData.unitDetail.module!.moduleDetail.argumentId, useUserStore.getState().user?.userProfileId!);
            const freshModule = freshNewArgument.argumentDetail.modules?.find((mod) => mod?.moduleId === newUnitData.unitDetail.module!.moduleId);
            console.log('🎰 end game', freshNewArgument, freshModule, newUnitData);
            set({ quizState: EduQuizGameStates.ending, selectedUnit: newUnitData, selectedModule: freshModule, selectedArgument: freshNewArgument!, argumentList: get().argumentList?.map((arg) => arg.argumentId === freshNewArgument.argumentId ? freshNewArgument : arg) });
        } else {
            if (Config.appTheme !== appThemes.SCHOOL) {
                useLifepointsStore.getState().refreshLifePoints(get().isAnswerCorrect(answer) ? useLifepointsStore.getState().currentLifepointData.lifePointDetail.currentLifePoints : useLifepointsStore.getState().currentLifepointData.lifePointDetail.currentLifePoints - 1);
            }
            ApiService.education.putArgument(newArgument!).then(async () => {
                const freshNewArgument = await ApiService.education.getUserArgument(newArgument.argumentId, useUserStore.getState().user?.userProfileId!);
                set({ selectedArgument: freshNewArgument, argumentList: get().argumentList?.map((arg) => arg.argumentId === freshNewArgument.argumentId ? freshNewArgument : arg) });
                //Get data in lifepoint store
                if (Config.appTheme !== appThemes.SCHOOL) {
                    await useLifepointsStore.getState().refreshLifePoints();
                    // if lifepoints are now zero, set game over
                    if (useLifepointsStore.getState().currentLifepointData.lifePointDetail.currentLifePoints <= 0) {
                        set({ quizState: EduQuizGameStates.gameOver });
                    }
                    // Refresh data in gems store
                    useGemsStore.getState().refreshGems();
                }
                // Get data in experiencepoint store
                useExperiencePointsStore.getState().refreshExperiencePoints();
            });
        }

    },
    saveSlideRead: async () => {
        let newArgument = cloneDeep(get().selectedArgument);
        // get index of get().selectedUnit!.unitDetail.module!.moduleId
        let moduleIndex = newArgument!.argumentDetail.modules!.findIndex((module) => module!.moduleId === get().selectedUnit!.unitDetail.module!.moduleId);
        // refactor the argument by adding the module to it and the unit
        newArgument!.argumentDetail.modules = [newArgument!.argumentDetail.modules![moduleIndex]];
        newArgument!.argumentDetail.modules[0].moduleDetail.units = [cloneDeep(get().selectedUnit)!];
        // get reference to the unit
        let newUnit = newArgument!.argumentDetail.modules[0].moduleDetail.units[0];
        // Set the unit to completed
        newArgument!.argumentDetail.userArgument!.userArgumentDetail.unitNotCompletedId = get().selectedUnit!.unitId;
        // Set the question to answered
        // get reference to the slide
        let newSlide = newUnit.unitDetail.slides![get().currentSlideIndex];
        newUnit.unitDetail.slides = [newSlide];
        delete newUnit.unitDetail.module;
        delete newUnit.unitDetail.questions;
        // set slide as completed
        newSlide.slideDetail.userSlide!.userSlideDetail.completed = true;

        await ApiService.education.putArgument(newArgument!);
        ApiService.education.getUserUnit(get().selectedUnit!.unitId, useUserStore.getState().user?.userProfileId!).then((unit) => {
            set({ selectedUnit: unit });
        });
    },
    terminateQuiz: () => {
        get().initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? null);
        set({ quizState: EduQuizGameStates.initial, selectedUnit: null, });
    },
    isAnswerCorrect: (answer) => {
        if (!answer) return false;
        return (get().selectedUnit?.unitDetail.questions ?? [])[get().currentQuestionIndex]?.questionDetail.correctAnswerIds.includes(answer?.answerId) ?? false;
    }
}));
