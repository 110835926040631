import React, { useState } from "react";
import { Alert, Form, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CompanyDetail } from "models/companyModel";
import Config from "utils/config";
import { CompanyUserCreationData, useCompanyManagerStore } from "stores/companyManagerStore";
import { toast } from "react-hot-toast";
import { useDocumentStore } from "stores/documentStore";
import { StepControl } from "./WizardStepControl";
import CompanyManageAdmin from "./CompanyManageAdmin";
import CompanyManageUsersRecap from "./CompanyManageUsersRecap";
import CompanyManageUsers from "./CompanyManageUsers";
import CompanyManageFeatures from "./CompanyManageFeatures";
import CompanyManageConfig from "./CompanyManageConfig";
import CompanyManageStyle from "./CompanyManageStyle";
import { useSystemModalStore } from "stores/systemModalStore";
interface CreateCompanyWizardProps {
    onClose: () => void;
    showModal: boolean;
}

function CreateCompanyWizard({ onClose, showModal }: CreateCompanyWizardProps) {
    const [step, setStep] = useState(1);
    const [partnerLogoImage, setBannerImage] = useState<File | null>(null);
    const createNewCompany = useCompanyManagerStore(state => state.createNewCompany);
    const uploadDocument = useDocumentStore(state => state.uploadDocument);
    const [company, setCompany] = useState({
        languages: ['en', 'it'],
        isDefault: false,
        enabledFeatures: {
            "quiz": true,
            "shop": true,
            "shopManagement": true,
            "quizManagement": true,
            "partnerManagement": true,
            "userManagement": true,
            "languageManagement": true,
            "investingTools": true,
            "progression": true,
            "ranking": true,
            "videos": true,
            "materials": true,
            "companyManagement": false,
            "amplitude": true,
            "chat": true,
            "selfRegistration": false,
            "feedback": true,
            "initialSurvey": true,
            "tutorials": true,
            "learningMaterialsManagement": false
        },
        dashboardPreferences: Config.defaultDashboardPreferences ?? [],
        title: {
            en: "",
            it: ""
        },
        description: {
            en: "",
            it: ""
        },
        baseUrl: Config.baseUrl,
        fileStorageBaseUrl: Config.defaultFileStorageBaseUrl!,
        passwordRegex: Config.passwordRegex.toString(),
        emailRegex: Config.emailRegex.toString(),
        amplitudeUrl: Config.amplitudeUrl,
        amplitudeApiKey: Config.amplitudeApiKey,
        maxSlidesPerUnit: 8
    } as CompanyDetail);
    const [admins, setAdmins] = useState([] as CompanyUserCreationData[]);
    const [usersToImport, setUsersToImport] = useState([] as CompanyUserCreationData[]);

    const { t } = useTranslation();

    const handleNextStep = async () => {
        if (step === 8) {
            onClose();
            setStep(1);
            toast.loading(t('company:creatingCompany'));
            try {
                const logoDocument = await uploadDocument({
                    format: "image",
                    title: "Company logo",
                    ownerId: "",
                    type: "companyLogo",
                    file: partnerLogoImage as File
                });
                if (logoDocument == null) {
                    toast.error(t('company:companyCreationError'));
                    return;
                }
                company.companyLogo = company.fileStorageBaseUrl! + logoDocument.document.storagePath!;
                await createNewCompany(company, usersToImport, admins);
                toast.dismiss();
                toast.success(t('company:companyCreated'));
                toast('Copia argomenti in corso, attendere qualche minuto', { icon: '🚀' });
            }
            catch (e) {
                toast.dismiss();
                toast.error(t('company:companyCreationError'));
            }
        }
        setStep(step + 1);
        // scroll to top the mdoal content
        const modalContent = document.querySelector('.modal-body');
        if (modalContent) {
            modalContent.scrollTo(0, 0);
        }
    };



    const handlePreviousStep = () => {
        if (step === 1) {
            return;
        }
        setStep(step - 1);
        // scroll to top the mdoal content
        const modalContent = document.querySelector('.modal-body');
        if (modalContent) {
            modalContent.scrollTo(0, 0);
        }
    };

    const onNewUser = (newUsers: CompanyUserCreationData[]) => {
        setUsersToImport(newUsers);
    }

    const handleConfigChange = (e: any) => {
        // get the form element
        const form = e.target.form;
        // get form data
        const formData = new FormData(form);
        // convert form data to object
        let object: any = {};
        formData.forEach((value, key) => {
            object[key] = value;
        });
        // merge with company object
        setCompany({
            ...company,
            ...object
        });
    }
    const handleFeaturesChange = (feature: string) => {
        setCompany(currentCompany => ({
            ...currentCompany,
            enabledFeatures: {
                ...currentCompany.enabledFeatures,
                [feature]: !currentCompany.enabledFeatures[feature]
            }
        }));
    };
    const handleLanguageChange = (selectedOption: any) => {
        if (selectedOption.map((option: any) => option.value).length === 0) {
            alert(t('company:atLeastOneLanguage'));
            return;
        }
        setCompany({
            ...company,
            languages: selectedOption.map((option: any) => option.value)
        });
    };

    const handleNewAdmin = (newAdmin: CompanyUserCreationData) => {
        // add to admins
        setAdmins([...admins, newAdmin]);
    }
    const handleUrlChange = (language: string, value: string) => {
        setCompany({
            ...company,
            multiLanguageUrl: {
                ...company.multiLanguageUrl,
                [language]: value
            }
        });
    }
    const handleDashboardPreferencesChange = (items: string[]) => {
        setCompany({
            ...company,
            defaultDashboardPreferences: items
        });
    }
    return (
        <Modal id="createCompanyWizard"
            show={showModal}
            onHide={() => {
                useSystemModalStore.getState().showUnsavedDataModal().then((result) => {
                    if (result) {
                        onClose();
                        setStep(1);
                    }
                });
            }}
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title> {t('company:companyCreation')}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {step === 1 && (
                    <CompanyManageConfig
                        handleUrlChange={handleUrlChange}
                        company={company} handleConfigChange={handleConfigChange} handleLanguageChange={handleLanguageChange}
                        handleDashboardPreferencesChange={handleDashboardPreferencesChange}
                        setBannerImage={setBannerImage} partnerLogoImage={partnerLogoImage} />
                )}
                {step === 2 && (
                    <div>
                        <h5>{t('company:configureCompanyAdvanced')}</h5>
                        <Form>
                            <Alert variant="warning" className="d-flex align-items-center mb-3">
                                <i className="fas fa-warning me-3"></i>
                                <div>
                                    {t('company:changeHint')}
                                </div>
                            </Alert>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:API_URL')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="baseUrl" defaultValue={Config.baseUrl} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:file_storage_url')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="fileStorageBaseUrl" placeholder="http://www.example.com" defaultValue={Config.defaultFileStorageBaseUrl} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:passwordRegex')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="passwordRegex" defaultValue={Config.passwordRegex.toString()} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:emailRegex')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="emailRegex" defaultValue={Config.emailRegex.toString()} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:amplitude_url')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="amplitudeUrl" defaultValue={Config.amplitudeUrl} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('company:amplitude_key')}</Form.Label>
                                <Form.Control type="text" onChange={handleConfigChange} name="amplitudeApiKey" defaultValue={Config.amplitudeApiKey} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="url">
                                <Form.Label>{t('company:maxSlides')}</Form.Label>
                                <Form.Control type="number" name="maxSlidesPerUnit" defaultValue="8" min={0} max={20} />
                            </Form.Group>
                        </Form>
                    </div>
                )}
                {step === 3 && (
                    <CompanyManageFeatures company={company} handleFeaturesChange={handleFeaturesChange} />
                )}
                {step === 4 && (
                    <CompanyManageUsers onNewUser={onNewUser} />
                )}
                {
                    step === 5 && (
                        <CompanyManageUsersRecap usersToImport={usersToImport} />
                    )
                }
                {step === 6 &&
                    (
                        <CompanyManageAdmin admins={admins} handleNewAdmin={handleNewAdmin} />
                    )}
                {step === 7 &&
                    <CompanyManageStyle company={company} handleStyleChange={handleConfigChange} />
                }
                {step === 8 &&
                    (
                        <div>
                            <h5>{t('company:confirmCreation')}</h5>
                            <p>
                                {t('company:confirmCreationDescription')}
                            </p>
                            <Alert variant="info" className="d-flex align-items-center">
                                <i className="fas fa-info-circle me-3"></i>
                                <div>
                                    {t('company:confirmCreationHint')}
                                </div>
                            </Alert>
                            <Alert variant="danger" className="d-flex align-items-center">
                                <i className="fas fa-exclamation-triangle me-3"></i>
                                <div>
                                    {t('company:confirmCreationWarning')}
                                </div>
                            </Alert>
                        </div>
                    )}
            </ModalBody>
            <ModalFooter>
                <StepControl step={step} handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />
            </ModalFooter>
        </Modal>
    );
}





export default CreateCompanyWizard