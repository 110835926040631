import React from 'react'
import { createPortal } from 'react-dom';
import { useIsMobile } from 'stores/appStore';

function HeaderWidgetPortal({ children }) {
    const isMobile = useIsMobile();
    const portalTarget = isMobile ? document.getElementById('header-widget-portal') : document.getElementById('header-widget-portal-desktop');
    if (!portalTarget || !children) {
        return null;
    }
    return (
        <>{
            createPortal(<>{children}</>, portalTarget)
        }</>
    )
}

export default HeaderWidgetPortal