import Loader from "components/Loader";
import QuizProgressIcon from "components/QuizProgressIcon";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useEduquizStore } from "stores/eduquizStore";
import { useI18nStore } from "stores/i18nStore";
import Config, { appThemes } from 'utils/config';
import TutorialTour from "components/TutorialTour";
import { DiffBadge } from "components/DiffBadge";
import { Card, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SecondaryHeader from "components/SecondaryHeader";
import { useIsMobile } from "stores/appStore";
import toast from "react-hot-toast";
import FinLogo from "components/FinLogo";
import AppThemed from "components/AppThemed";
import XpCounter from "components/XpCounter";

export default function EduQuizModulePage() {
    let { moduleId } = useParams();
    const selectedModule = useEduquizStore(state => state.selectedModule);
    const getModule = useEduquizStore(state => state.getModule);
    const navigate = useNavigate();

    const getTranslation = useI18nStore(state => state.getTranslation);
    const { t } = useTranslation();

    // get the module from the store
    useEffect(() => {
        if (!selectedModule || selectedModule?.moduleId !== moduleId || !selectedModule?.moduleDetail?.units || selectedModule?.moduleDetail?.units?.length === 0) {
            getModule(moduleId!);
        }
    }, [selectedModule, getModule, moduleId]);
    const nextModule = useMemo(() => {
        const modules = selectedModule?.moduleDetail.argument?.argumentDetail.modules ?? [];
        const nextModuleIndex = modules.findIndex(module => module.moduleId === moduleId) + 1;
        return modules[nextModuleIndex] ?? null;
    }, [selectedModule, moduleId]);
    const previousModule = useMemo(() => {
        const modules = selectedModule?.moduleDetail.argument?.argumentDetail.modules ?? [];
        const previousModuleIndex = modules.findIndex(module => module.moduleId === moduleId) - 1;
        return modules[previousModuleIndex] ?? null;
    }, [selectedModule, moduleId]);
    const isMobile = useIsMobile();
    // if selectedModule is null, return Loader
    if (!selectedModule || selectedModule?.moduleId !== moduleId) { return (<Loader />); }
    // determine the color of the module
    const color = selectedModule.moduleDetail.argument?.argumentDetail?.color?.replace("0xFF", "#") ?? "#";
    // determine which unit has been completed last
    const lastCompletedUnitIndex = selectedModule?.moduleDetail?.userModule?.userModuleDetail?.unitCompleted;
    const onUnitClick = (quizUnit: any, index: any) => {
        if (index > lastCompletedUnitIndex!) {
            toast.dismiss();
            toast(t('eduquiz:unit_locked'), { icon: <FinLogo width="24" /> });
            return;
        }
        navigate('./unit/' + quizUnit.unitId);
    }
    const onStartCourse = () => {
        if (!selectedModule.moduleDetail.units) return;
        const quizUnit = (((lastCompletedUnitIndex ?? 0) >= (selectedModule.moduleDetail.units ?? []).length)) ? selectedModule.moduleDetail.units[0] : selectedModule.moduleDetail.units![lastCompletedUnitIndex ?? 0];
        navigate('./unit/' + quizUnit.unitId);
    }

    const onNextModuleClick = () => {
        if (nextModule) {
            navigate(`/welfare/eduquiz/module/${nextModule.moduleId}`);
        }
    }
    const onPreviousModuleClick = () => {
        if (previousModule) {
            navigate(`/welfare/eduquiz/module/${previousModule.moduleId}`);
        }
    }
    return (
        <section className="container-lg EduQuizModulePage d-flex flex-column pt-3">
            <SecondaryHeader title={""} backTarget="/welfare/eduquiz">
                <AppThemed themes={[appThemes.SCHOOL]}>
                    <div className="d-flex align-items-center ms-auto">
                        <XpCounter />
                    </div>
                </AppThemed>
            </SecondaryHeader>
            <div className="card ">
                <div className="card-body p-4">
                    <Row className="gx-0">
                        <div className="col-12 col-md-5  mb-4 mb-md-0 d-flex flex-column order-2 order-md-1">
                            <AppThemed themes={[appThemes.SCHOOL]}>
                                <button onClick={() => {
                                    navigate('/welfare/eduquiz')
                                }} style={{ color: "white", height: "2.5rem", backgroundColor: color }} className="btn btn-outline-light rounded-pill align-self-start">{getTranslation(selectedModule.moduleDetail.argument?.argumentDetail.title)}</button>

                            </AppThemed>
                            <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                                <button onClick={() => {
                                    navigate('/welfare/eduquiz')
                                }} style={{ borderColor: color, color: color, height: "2.5rem", backgroundColor: "white" }} className="btn btn-outline-light rounded-pill align-self-start">{getTranslation(selectedModule.moduleDetail.argument?.argumentDetail.title)}</button>

                            </AppThemed>

                            <h3 className="pt-3">
                                {getTranslation(selectedModule?.moduleDetail?.title)}
                            </h3>
                            <div className="mb-3 d-flex align-items-center ">
                                <span>
                                    {selectedModule && <div className='text-uppercase'>
                                        {selectedModule.moduleDetail.units!.length} {t('common:units')}
                                    </div>}
                                </span>
                                <span className="mx-2">
                                    |
                                </span>
                                <span > {t('common:difficulty')}</span>
                                <DiffBadge diff={selectedModule?.moduleDetail?.difficulty} className="ms-2 fs-6" />
                            </div>
                            <div>
                            </div>
                            <style>
                                {`.removePMargin p {
                                                        margin: 0;
                                                    }`}
                            </style>
                            <div className="removePMargin flex-fill flex-grow-1 mb-4 text-truncate-3" dangerouslySetInnerHTML={{ __html: getTranslation(selectedModule?.moduleDetail?.description) }}>
                            </div>
                            <button className="btn btn-primary rounded-pill align-self-baseline" onClick={() => onStartCourse()}>
                                {t('videos:start_course')}
                            </button>
                        </div>
                        <div className="d-none d-md-block col-1 order-2"></div>
                        <div className="col-12 col-md-6 order-1 order-md-3 mb-3 mb-md-0 d-flex justify-content-start justify-content-md-end">
                            <QuizProgressIcon width={isMobile ? 9 : 18} color={color} percentage={selectedModule.moduleDetail.userModule?.userModuleDetail.percentageCompletion ?? 0} icon={selectedModule.moduleDetail.fontIcon} iconUrl={selectedModule.moduleDetail.iconId} />
                        </div>
                    </Row>
                    <Card className="bg-body-tertiary mt-2 mt-md-5 border-0">
                        <Card.Body className="p-4">
                            <div className="text-uppercase pb-4">
                                {t('eduquiz:all_units')}
                            </div>
                            {/* UNIT LIST */}
                            <div className="d-flex flex-column remove-margin-bottom-last">
                                {selectedModule.moduleDetail.units?.map((quizUnit, index) => {
                                    return (
                                        <div key={quizUnit.unitId} onClick={() => onUnitClick(quizUnit, index)}
                                            className={"ModulePageUnitCard d-flex justify-content-between align-items-center mb-3 " +
                                                (index <= (lastCompletedUnitIndex ?? 0) ? "text-hoverable cursor-pointer" : "opacity-25")}>
                                            <div className="d-flex align-items-center pb-2 w-100">
                                                <div className="me-3 h5 mb-0 text-body-tertiary">
                                                    {index + 1}
                                                    {/*                                                 <QuizProgressIcon width={5} color={color} percentage={quizUnit.unitDetail.userUnit?.userUnitDetail.percentageCompletion ?? 0} icon={quizUnit.unitDetail.fontIcon} iconUrl={quizUnit.unitDetail.iconId} completed={quizUnit.unitDetail.userUnit?.userUnitDetail.finishedCorrectlyOnce} />
 */}                                            </div>
                                                <div className="d-flex w-100">
                                                    <h5 className="mb-0 cursor-pointer flex-fill">{getTranslation(quizUnit.unitDetail.title)}</h5>
                                                    <div className="ms-1">
                                                        <OverlayTrigger placement="left" overlay={<Tooltip title={t('eduquiz:unit_completed')} placement="top">
                                                            {
                                                                quizUnit.unitDetail.userUnit!.userUnitDetail!.finishedCorrectlyOnce ? t('eduquiz:perfect_score') : t('eduquiz:not_perfect_score')
                                                            }
                                                        </Tooltip>}>
                                                            <i data-bs-toggle="tooltip" data-bs-placement="top" className={"fas fa-star " + (quizUnit.unitDetail.userUnit!.userUnitDetail!.finishedCorrectlyOnce ? "text-primary" : "text-body-tertiary")}></i>
                                                        </OverlayTrigger>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>);
                                })}
                                {(!selectedModule.moduleDetail.units || selectedModule.moduleDetail.units.length === 0) && <div className="w-50 mx-auto"><Loader /></div>}
                                {selectedModule.moduleDetail.units && <TutorialTour tutorialName="modulePage" />}
                            </div>
                        </Card.Body>
                    </Card>

                </div>
            </div>
            <div className="d-md-flex my-5 d-none">
                {previousModule && (
                    <div className="d-flex flex-column me-auto cursor-pointer text-hoverable" onClick={onPreviousModuleClick}>
                        <div className="d-flex align-items-center text-body">
                            <i className="fas fa-arrow-left me-2"></i>
                            <span>
                                {t('common:back')}
                            </span>
                        </div>
                        <div className="text-truncate-3 h5 mt-2">
                            {getTranslation(previousModule?.moduleDetail?.title)}
                        </div>
                    </div>
                )}
                {/* Next */}
                {nextModule && (
                    <div className="d-flex flex-column ms-auto cursor-pointer text-hoverable" onClick={onNextModuleClick}>
                        <div className="d-flex align-items-center  text-body">
                            <span className="ms-auto">
                                {t('common:next')}
                            </span>
                            <i className="fas fa-arrow-right ms-2 "></i>
                        </div>
                        <div className="text-truncate-3 h5 mt-2">
                            {getTranslation(nextModule?.moduleDetail?.title)}
                        </div>
                    </div>
                )}
            </div>

            <div className="d-flex justify-content-between mb-4">
                <div className="d-flex flex-column align-items-center d-none" style={{
                    position: "absolute",
                    right: "8px",

                }}>
                    <small className="text-muted mb-1">{t('eduquiz:sponsered_by')}</small>
                    {selectedModule.moduleDetail.companyLogoId && <img src={selectedModule.moduleDetail.companyLogoId} style={{ width: "6rem" }} className="img-fluid" alt="sponsor"></img>}
                    {!selectedModule.moduleDetail.companyLogoId && <img src={Config.companyLogo} style={{ width: "6rem" }} className="img-fluid" alt="sponsorDefault"></img>}
                </div>
            </div>
        </section >
    )
}
