import React, { useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useShopStore } from 'stores/shopStore';
import { FixedLoader } from 'components/Loader';
import { useOrderStore } from 'stores/orderStore';
import { t } from 'i18next';
import AppThemed from 'components/AppThemed';
import { appThemes } from 'utils/config';
import FunnifinLogo from 'components/FunnifinLogo';
import lineloader from 'assets/lotti/lineloader.json';

import Lottie from "lottie-react";
import PartnerLogo from 'components/PartnerLogo';
import { routes } from 'Routes';
import { toast } from 'react-hot-toast';
function ExpertSuccessPage() {
    const navigate = useNavigate();

    const fetchAndCheckOrders = useCallback(async () => {
        console.log("Fetching orders...");
        await useOrderStore.getState().fetchOrders();
        const orders = useOrderStore.getState().orders;
        console.log("Orders fetched:", orders);

        if (orders) {
            const today = new Date().toISOString().split('T')[0];
            console.log("Today's date:", today);

            const latestOrder = orders.filter(order =>
                order.orderDetail.status === 'PAID' &&
                order.orderDetail.type === "CHARGEABLE" &&
                order.orderDetail.targetIds.some(targetId => targetId.type === 'EXPERT') /* &&
                order.orderDetail.dateLastUpdate.split('T')[0] === today */
            ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

            console.log("Latest order:", latestOrder);

            if (latestOrder) {
                console.log("Navigating to expert success page...");
                console.log("Latest order targetId:", latestOrder.orderDetail.targetIds[0].value);  
                toast.success(t('orders:payment_processed'));
                navigate(routes.expertBooking(latestOrder.orderDetail.targetIds[0].value));
            } else {
                console.log("No latest order found, retrying...");
                fetchAndCheckOrders();
            }
        } else {
            console.log("No orders found, retrying...");
            fetchAndCheckOrders();
        }
    }, [navigate]);

    useEffect(() => {
        console.log("Starting fetch and check orders process...");
        new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
            fetchAndCheckOrders();
        });
    }, [fetchAndCheckOrders]);

    return (
        <>
            <div className='d-flex flex-column justify-content-center h-100 w-100 align-items-center position-fixed z-5' style={{
                overflow: "hidden", top: "0",
                zIndex: "500000"
            }}>
                <style>
                    {`

                @keyframes fadeIn {
                from {
                    opacity: 0.01;
                }

                to {
                    opacity: 1;
                }
                }

                @keyframes fadeOut {
                from { 
                    opacity: 1;
                }
                
                to { 

                }
                }

                @keyframes slideIn {
                from {
                    transform: translateY(20px);
                }

                to {
                    transform: translateY(0);
                }
                }

                @keyframes slideOut {
                from { 
                    transform: translateY(0);
                
                }

                to { 
                    transform: translateY(-20px);
                }
                }

                #close-screen {
                    height: 100%;
                    width: 100%;
                }

                #root-loader {
                height: 100%;
                width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 500000;
                background-color: var(--bs-body-bg);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                }

                .root-loader-inner {
                position: relative;
                }

                #root-loader.out {
                animation: 400ms ease-in-out 400ms 1 forwards fadeOut;
                opacity: 1;
                }

                #loader-bar {
                height: auto;
                margin-top: 16px; 
                }

                #loader-logo {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                transform: translateY(20px);
                position: absolute;
                bottom: 20px;
                animation: 400ms ease-in-out 1 forwards slideIn;
                }

                #root-loader.out #loader-logo {
                animation: 300ms ease-in-out 400ms 1 forwards slideOut;
                transform: translateY(0);
                }

                .renderer-in {
                animation: 400ms ease-in-out 800ms 1 normal forwards running fadeIn;
                opacity: 1;
                }
  
                `}
                </style>

                {/* <Lottie animationData={loader} loop={true} className="fixed-loader" style={{width: "80px" , heigh:"80px" }} /> */}
                <div id="root-loader">
                    <div className="root-loader-inner mt-5  pt-5 d-flex flex-column align-items-center">
                        <AppThemed
                            themes={[
                                appThemes.CLASSIC,
                                appThemes.SCHOOL
                            ]}
                        >
                            <PartnerLogo className="loader-logo mt-5 mb-5" width="140px" />
                        </AppThemed>
                        <AppThemed
                            themes={[
                                appThemes.SIDEBARS
                            ]}
                        >
                            <FunnifinLogo className="loader-logo mt-5 mb-5" width="140px" />
                        </AppThemed>

                    </div>
                    <Lottie animationData={lineloader} loop={true} style={{
                        width: "190px",
                        marginTop: "-86px"
                    }} />
                    <br />
                    <span className='text-center fs-5 fw-bold text-primary  mb-3'>
                        {
                            t('orders:processing_payment')
                        }
                    </span>
                </div>
            </div>

        </>
    )
}

export default ExpertSuccessPage