import CircleButton from 'components/CircleButton';
import GemCounter from 'components/GemCounter';
import LanguageSelector from 'components/LanguageSelector';
import LifepointCounter from 'components/LifepointCounter';
import Loader from 'components/Loader';
import XpCounter from 'components/XpCounter';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'stores/authStore';
import { useUserStore } from 'stores/userStore';
import Config, { appThemes } from 'utils/config';
import { useTitle } from 'utils/functions';
import privacy from "assets/Privacy Policy FunniFin.pdf";
import cookies from "assets/Cookie Policy_FunniFin.pdf";
import privacyAI from "assets/Privacy Policy AI.pdf";
import { useI18nStore } from 'stores/i18nStore';
import { langs } from 'assets/langs';
import { useSurveyStore } from 'stores/surveyStore';
import { Badge, Collapse } from 'react-bootstrap';
import { useSystemModalStore } from 'stores/systemModalStore';
import DarkModeSelector from 'components/DarkModeSelector';
import UserDashboardMainHeader from 'pages/user_dashboard/UserDashboardMainHeader';
import { QuoteRow, RankingRow } from 'pages/user_dashboard/UserDashbordWidgetRows';
import TitleViewAll from 'components/TitleViewAll';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';
import AppThemed from 'components/AppThemed';
function ProfilePage() {
    const user = useUserStore(state => state.user);
    const userDetail = user?.userProfileDetail;
    const getUserQuizStatistics = useUserStore(state => state.getUserQuizStatistics);
    const eduquizStatistics = useUserStore(state => state.eduquizStatistics);
    const logout = useAuthStore(state => state.logout);
    const deleteAccount = useAuthStore(state => state.deleteAccount);
    const navigate = useNavigate();
    const currentLanguage = useI18nStore((state) => state.currentLanguage);
    const currentLanguageName = langs.find(lang => lang.code === currentLanguage)?.native ?? 'English';
    const hasDoneMainSurvey = useSurveyStore(state => state.hasDoneMainSurvey);
    const forceSurvey = useSurveyStore(state => state.forceSurvey);
    const { t } = useTranslation();
    const [showEditProfile, setShowEditProfile] = useState(false);
    useTitle(t('profile:title'));
    //useEffect for quizStatistics
    useEffect(() => {
        if (eduquizStatistics === null && useAuthStore.getState().isUser) {
            getUserQuizStatistics();
        }
    }, [eduquizStatistics, getUserQuizStatistics]);

    if (!userDetail) { return (<Loader />); }
    return (
        <section className="ProfilePage d-flex flex-column h-100 pt-2 pb-5">
            <AppThemed themes={[appThemes.CLASSIC]}>
                <div className="w-100 d-flex align-items-center mb-4 mt-3">
                    <CircleButton iconClassName={"fas fa-arrow-left"} className="text-white" onClick={() => {
   /*  if (window.confirm("Are you sure you want to log out?"))  */navigate(-1);
                    }} />
                    <div onClick={() => {
                        useSystemModalStore.getState().showComingSoonModal();
                    }}>
                        <i className="fa-duotone fa-bell text-warning ms-3 d-none" style={{ fontSize: "1.5rem" }} />
                    </div>
                    <div className="d-flex align-items-center ms-auto">
                        <LifepointCounter />
                        <XpCounter />
                        <GemCounter />
                    </div>
                </div>
            </AppThemed>
            <AppThemed themes={[appThemes.SCHOOL]}>

                <div className="w-100 d-flex align-items-center mb-4 mt-3">
                    <CircleButton iconClassName={"fas fa-arrow-left"} className="text-white" onClick={() => {
                   /*  if (window.confirm("Are you sure you want to log out?"))  */navigate(-1);
                    }} />
                    <div className="d-flex align-items-center ms-auto">
                        <XpCounter />
                    </div>
                </div>
            </AppThemed>
            <AppThemed themes={[appThemes.SIDEBARS]}>
                {useAuthStore.getState().isUser && <>
                    <UserDashboardMainHeader user={user} eduquizStatistics={eduquizStatistics} />
                    <div className='mt-5'></div>
                </>}
                <div className='d-flex flex-row justify-content-between align-items-center row mb-3'>
                    <h3 className="w-auto mb-0  d-md-none">{t('profile:title')}</h3>
                    <h5 className="w-auto mb-0  d-none d-md-block">{t('profile:subtitle')}</h5>
                    <button className="ms-auto small btn btn-link text-primary w-auto text text-decoration-none text-uppercase py-0" type="button"
                        onClick={() => setShowEditProfile(!showEditProfile)}
                    >
                        {t('common:edit')}
                    </button>
                </div>
            </AppThemed>
            {/* MAIN HEADER */}
            {showEditProfile &&
                <div className="card card-body mb-3">
                    <ProfileForm userDetail={userDetail} onSubmit={() => setShowEditProfile(false)}
                    />
                </div>
            }
            <div className="row mb-5">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="d-flex">
                                <div className="d-flex flex-column flex-grow-1 justify-content-center ">
                                    <p className="mb-0 fw-bolder">
                                        <i className="fas  fa-fw  fa-user me-2 text-primary"></i>
                                        {userDetail.name} {userDetail.surname} </p>
                                    <hr className="my-2" />
                                    <p className="mb-0  ">
                                        <i className="fas  fa-fw fa-envelope me-2 text-primary"></i>
                                        {userDetail.email} </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {useAuthStore.getState().isUser && <>
                <TitleViewAll className="h5" title={t('user_dashboard:ranking')} onClick={() => {
                    navigate('/welfare/ranking');
                }} />
                <RankingRow />
                <div className='mt-5'></div>
            </>}
            <div className=' d-flex flex-column mb-5'>
                <h5 className='mb-3'>{t('profile:settings')}</h5>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex row justify-content-between align-items-center'>
                            <span className='col'>{t('profile:language_settings')}</span>
                            <div className='col-auto d-flex align-items-center'>{currentLanguageName}&nbsp;<LanguageSelector /></div>
                        </div>
                        <div className='d-flex row justify-content-between align-items-center mt-3'>
                            <span className='col'>{t('profile:darkmode_settings')}</span>
                            <div className='col-auto d-flex align-items-center'>
                                <DarkModeSelector />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Legal Documents*/}
            <div className='d-flex flex-column mb-5'>
                <h5 className='mb-3'>{t('profile:legal_documents')}</h5>
                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <a href={privacy} target="_blank" rel="noreferrer" className='text-decoration-none d-flex align-items-center text-body fw-bold'>
                                    <i className="fas fa-shield-halved me-2 text-primary"></i>
                                    {t('profile:privacy_policy')}</a>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <a href={cookies} target="_blank" rel="noreferrer" className='text-decoration-none d-flex align-items-center  text-body fw-bold'>
                                    <i className="fas fa-cookie me-2 text-primary"></i>
                                    {t('profile:cookie_policy')}</a>
                            </li>
                            {
                                Config.enabledFeatures.chat && <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href={privacyAI} target="_blank" rel="noreferrer" className='text-decoration-none d-flex align-items-center text-body fw-bold'>
                                        <i className="fas fa-microchip-ai me-2 text-primary"></i>
                                        {t('profile:privacy_policy')} AI</a>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
            {/* Surveys */}
            {
                Config.enabledFeatures.initialSurvey && useAuthStore.getState().isUser &&
                <div className='d-flex flex-column mb-5'>
                    <h5 className='mb-3'>{t('profile:surveys')}</h5>
                    <div className='card'>
                        <div className='card-body'>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-center"

                                >
                                    <Link onClick={() => forceSurvey()} to="/survey?redirect=profile" className={'text-decoration-none d-flex align-items-center fw-bold ' + (hasDoneMainSurvey ? "text-primary" : "text-muted")}>
                                        <i className="fas fa-poll-people me-2"></i>
                                        {t('surveys:main_survey')}
                                        {!hasDoneMainSurvey && <Badge bg="warning-subtle" className="ms-2 d-flex align-items-center text-warning">
                                            <i className="fas fa-exclamation-triangle me-1"></i>
                                            {t('profile:to_do')}</Badge>}

                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>}
            {/* Account Operations */}
            <div className='d-flex flex-column mb-5'>
                <h5 className='mb-3'>{t('profile:account_operations')}</h5>
                <div className='card'>
                    <div className='card-body row g-3'>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className='btn btn-secondary rounded-pill' onClick={() => {
                                useSystemModalStore.getState().showConfirmModal(
                                    t('profile:logout'),
                                    t('profile:logout_confirm'),
                                    'fa-sign-out-alt',
                                ).then((result) => {
                                    if (result) {
                                        logout();
                                    }
                                });
                            }}>
                                {t('profile:logout')}
                            </button>
                            {!useAuthStore.getState().isSystem &&
                                <button className='btn btn-dark rounded-pill ' onClick={() => {
                                    useSystemModalStore.getState().showConfirmModal(
                                        t('profile:delete_account'),
                                        t('profile:delete_account_confirm'),
                                        'fa-times-circle text-danger',
                                    ).then((result) => {
                                        if (result) {
                                            setTimeout(() => {
                                                useSystemModalStore.getState().showConfirmModal(
                                                    t('profile:delete_account'),
                                                    t('profile:delete_account_warning'),
                                                    'fa-sad-tear text-primary',
                                                ).then((result) => {
                                                    if (result) {
                                                        toast.promise(deleteAccount(), {
                                                            loading: t('common:deleting'),
                                                            success: t('common:deleted'),
                                                            error: t('common:error')
                                                        });
                                                    }
                                                });
                                            }, 300);
                                        }
                                    });
                                    return;
                                }}>
                                    {t('profile:delete_account')}
                                </button>}
                        </div></div>
                </div>
            </div>
            <QuoteRow />
            <div className='pb-5'></div>
        </section >
    )
}

function ProfileForm({ userDetail, onSubmit }: { userDetail: any, onSubmit: any }) {
    const editUserProfileDetail = useUserStore(state => state.editUserProfileDetail);
    const [isModified, setIsModified] = useState(false);
    const { t } = useTranslation();
    const handleSubmit = (event: any) => {
        event.preventDefault();
        // get form values
        const formData = new FormData(event.target);
        const _userDetail = Object.fromEntries((formData as any).entries());
        onSubmit();
        toast.promise(editUserProfileDetail({ ..._userDetail }), {
            loading: t('common:saving'),
            success: t('common:saved'),
            error: t('common:error')
        });
    };
    const handleEnabledChange = (event: any) => {
        setIsModified(true);
    };
    return (
        <form onSubmit={handleSubmit} onChange={handleEnabledChange}>
            {/* NAME */}
            <div className="mb-3 form-group">
                <label htmlFor="name" className="form-label">
                    Nome
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    defaultValue={userDetail.name}
                    placeholder='Cambia il Nome'

                />

            </div>
            {/* SURNAME */}
            <div className="mb-3 form-group">
                <label htmlFor="surname" className="form-label">
                    Cognome
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="surname"
                    name="surname"
                    defaultValue={userDetail.surname}
                    placeholder='Cambia il Cognome'

                />

            </div>
            <button type="submit" className="btn btn-primary rounded-pill ms-auto d-block" disabled={!isModified}>
                {t('common:save')}
            </button>
        </form>
    );
}
export default ProfilePage