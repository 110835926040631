import Loader from 'components/Loader';
import { QuizIcon } from 'components/QuizProgressIcon';
import TitleViewAll from 'components/TitleViewAll';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useI18nStore } from 'stores/i18nStore';
import { useProgressionStore } from 'stores/progressionStore';
import xp from "assets/images/xp_default.png";
import { useExperiencePointsStore } from 'stores/experiencePointsStore';
import InfoButton from 'components/InfoButton';
import TutorialTour from 'components/TutorialTour';
import { useNavigate } from 'react-router-dom';
import LockWrapper from 'components/LockWrapper';
import PageTitle from 'components/PageTitle';
import { useTitle } from 'utils/functions';
import { DiffBadge } from 'components/DiffBadge';

function ProgressionPage() {
    const eduquizStatistics = useProgressionStore(state => state.eduquizStatistics);
    const getUserQuizStatistics = useProgressionStore(state => state.getUserQuizStatistics);
    const completedModules = useProgressionStore(state => state.completedModules);
    const goalList = useProgressionStore(state => state.goalList);
    const getGoalList = useProgressionStore(state => state.getGoalList);
    const currentXp = useExperiencePointsStore(state => state.currentExperiencePointData?.experiencePointDetail.currentExperiencePoints ?? 0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    //useEffect for quizStatistics
    useEffect(() => {
        if (eduquizStatistics === null) {
            console.log('🧑‍💼 ProfilePage: useEffect for quizStatistics');
            getUserQuizStatistics();
        }
    }, [eduquizStatistics, getUserQuizStatistics]);
    useEffect(() => {
        if (goalList === null) {
            getGoalList();
        }
    }
        , [goalList, getGoalList])
    useTitle(t('prog:title'));

    return (
        <section className='d-flex flex-column'>
            {goalList && eduquizStatistics && <TutorialTour tutorialName="progressionPage" />}
            <PageTitle title={t('prog:title')}>
                <InfoButton id={'progPageInfo'} title={t('prog:info_modal_title')}>
                    <p>{t('prog:info_modal_text')}</p>
                </InfoButton>
            </PageTitle>
            {/*             <div className='d-flex align-items-center justify-content-between mb-3'>
                <h3 className="w-auto mb-0">{t('prog:title')}</h3>
                
            </div> */}
            <ScrollContainer>
                <div className='d-flex mb-5 GoalList'>
                    {
                        goalList && goalList.map((goal, index) => <GoalTile key={goal.goalId} goal={goal} goalList={goalList} currentXp={currentXp} index={index} />)
                    }
                </div>
            </ScrollContainer>
            <div className='col-12 col-md-12'>

                {/* STUDY HOURS */}
                {/*                 <div className='card mb-3'>
                    <div className='card-body d-flex align-items-center'>
                        <div className={`d-flex align-items-center justify-content-center rounded-circle btn text-primary p-2 cursor-pointer bg-body`} style={{ width: '3rem', height: '3rem' }}>
                            <i className={`align-items-center d-flex justify-content-center fa-duotone text-primary fa-graduation-cap fs-4
                            `} aria-hidden="true" />
                        </div>
                        <div className='d-flex flex-column flex-fill ps-3 justify-content-center text-left'>
                            <p className="mb-0 h2 text-primary py-2" style={{ fontWeight: "800" }}>{eduquizStatistics?.modulesCompleted ?? '-'}</p>
                            <small style={{ fontWeight: "500", lineHeight: "0.7" }}>{t('prog:study_hours')} </small>
                        </div>
                        <span className='fw-bold text-primary'>
                            {t('prog:valuation.great')}
                        </span>
                    </div>
                </div> */}
                {/* ITEMS COMPLETED */}
                <div className='ProgItemsModules mb-5 row'>
                    <ProgressionCard title={t('common:arguments')} value={eduquizStatistics?.argumentsCompleted ?? '-'} />
                    <ProgressionCard title={t('common:modules')} value={eduquizStatistics?.modulesCompleted ?? '-'} />
                    <ProgressionCard title={t('common:units')} value={eduquizStatistics?.unitsCompleted ?? '-'} />
                </div>
            </div>
            {/* MODULE COMPLETED CARD LIST */}
            <TitleViewAll title={t('prog:progresses')} onClick={() => { navigate('completedModules') }} />
            <ScrollContainer className="scroll-container mb-5 CompletedModuleCards" vertical={false} horizontal={true} hideScrollbars={true} style={{}}>
                <div className="d-flex" style={{ "width": "fit-content" }}>
                    {completedModules
                        ?.map((quizModule) => <div className="d-flex align-items-stretch me-3" style={{ "width": "360px", "height": "auto" }}><ProgressionModuleCard quizModule={quizModule} /></div>)}
                    {
                        !completedModules && <div className="d-flex align-items-stretch me-3" style={{ "width": "360px", "height": "auto" }}>
                            <Loader />
                        </div>
                    }
                </div>
            </ScrollContainer>
            <LockWrapper    >
                <TitleViewAll title={t('prog:achievements')} onClick={() => { }} />
                <ScrollContainer className="scroll-container mb-5 AchievementCards" vertical={false} horizontal={true} hideScrollbars={true} style={{}}>
                    <div className="d-flex" style={{ "width": "fit-content" }}>
                        {completedModules
                            ?.map((quizModule) => {
                                /* const color = quizModule.moduleDetail.color.replace('0xFF', '#'); */
                                return (<ProgressionAchievmentCard quizModule={quizModule} />);
                            }
                            )}
                        {
                            !completedModules && <div className="d-flex align-items-stretch me-3" style={{ "width": "320px", "height": "auto" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </ScrollContainer>
            </LockWrapper  >
        </section >
    )
}

export function ProgressionCard({ title, value }) {
    return <div className='col-4'>
        <div className='card flex-grow-1'>
            <div className='card-body py-3 px-2'>
                <div className="d-flex justify-content-center align-items-center flex-row text-center rounded-3" >
                    <div className=''>
                        <p className="mb-2 h1 text-primary  display-4" style={{ fontWeight: "800" }}>{value ?? <Loader />}</p>
                        <p className="small mb-0">{title} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
function GoalTile({ goal, goalList, index, currentXp }) {
    const getTranslation = useI18nStore(state => state.getTranslation);
    let nextProgress = 0;
    if (index !== 0 && index !== goalList.length - 1) {
        // fetch next goal
        const nextGoal = goalList[index - 1];
        // calculate next progress by getting the percentage between the current and next goal
        nextProgress = ((currentXp - goal.goalDetail.totalXp) / (nextGoal.goalDetail.totalXp - goal.goalDetail.totalXp)) * 100;
        // if next progress is greater than 100, set it to 100
        nextProgress = nextProgress > 100 ? 100 : nextProgress;
        // if next progress is less than 0, set it to 0
        nextProgress = nextProgress < 0 ? 0 : nextProgress;
        // truncate progress to int
        nextProgress = Math.trunc(nextProgress);
        // if next progress is 0, set it to 100, if is 100, set it to 0
        nextProgress = nextProgress === 0 ? 100 : (nextProgress === 100 ? 0 : nextProgress);
    }
    const currentGoal = nextProgress > 0;
    const goalReached = currentXp >= goal.goalDetail.totalXp;

    return <>
        {index !== 0 && index !== goalList.length && <div className="progress h-100 my-auto bg-light mx-3" style={{ minWidth: "4rem", minHeight: "1rem" }}>
            <div className="progress-bar text-dark bg-primary" role="progressbar" style={{ width: nextProgress + '%' }} aria-valuemin={0} aria-valuemax={100}>
            </div>
        </div>}
        <div className={'d-flex align-items-center bg-light rounded rounded-3 p-3 ' + (currentGoal ? "" : (goalReached ? "" : "opacity-50"))}>
            {/* Linear progress bar */}

            <div className={'d-flex flex-column text-center align-items-center justify-content-center h-100'} style={{ width: "7rem" }}>
                <div className='bg-light pt-1 mb-2' style={{ width: '3rem', height: '3rem', borderWidth: "0.5px !important" }}>
                    <span className={`align-items-center  text-center d-flex justify-content-center fs-3`} aria-hidden="true">
                        {goal.goalDetail.fontIcon}
                    </span>
                </div>
                <div>
                    <span className='fw-bold my-2' style={{ maxHeight: "48px" }}>
                        {getTranslation(goal.goalDetail.title)}
                    </span>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img src={xp} className="img-fluid me-2  flex-shrink-0" alt="xp" style={{ height: "1rem" }}></img>
                        <div className='fw-bolder  flex-shrink-0'>
                            {/* {Math.max(goal.goalDetail.totalXp - currentXp}/ */}{goal.goalDetail.totalXp}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

        ;
}

export function ProgressionAchievmentCard({ quizModule }) {
    return <div key={quizModule.argumentId} className="d-flex align-items-stretch me-3" style={{ "width": "360px", "height": "auto" }}>
        <div className="card w-100 border-primary bg-primary-subtle">
            <div className="card-body">
                <div className="d-flex">
                    <div className="fs-3 me-3">
                        <div className={`d-flex align-items-center justify-content-center rounded-circle btn text-primary p-2 cursor-pointer bg-body`} style={{ width: '2.8rem', height: '2.8rem' }}>
                            <i className={`align-items-center d-flex justify-content-center fa-duotone text-primary fa-graduation-cap fs-4`} aria-hidden="true" />
                        </div>
                    </div>
                    <div className="flex-fill d-flex flex-column justify-content-center">
                        <h6 className="mb-0 col-11 text-truncate d-block text-primary">Achievement Title{/* {getTranslation(quizModule?.moduleDetail?.title)} */}</h6>
                        <small>Do something enough times to win this!</small>
                    </div>
                </div>
                {/* Linear progress bar */}
                <div className="progress mt-3 position-relative">
                    <div className="progress-bar text-dark" role="progressbar" style={{ width: (quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion) + '%', backgroundColor: "var(--bs-primary)" }} aria-valuenow={quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion} aria-valuemin={0} aria-valuemax={100}>
                    </div>
                    <div className="position-absolute text-center w-100 fw-bold text-white text-shadowed">
                        {Math.trunc(quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion)}%
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export function ProgressionModuleCard({ quizModule }) {
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const color = quizModule.moduleDetail.color.replace('0xFF', '#');
    return (<div key={quizModule.argumentId} className="card w-100" style={{
        backgroundColor: quizModule.moduleDetail.color,
        backgroundImage: `url(${quizModule.moduleDetail.bannerId})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
    }}>
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div className="fs-3 me-2">
                    <QuizIcon icon={quizModule.moduleDetail.fontIcon} iconUrl={quizModule.moduleDetail.iconId} width="2.8" />
                    {/*   {quizModule.moduleDetail.fontIcon} */}
                </div>
                <div className="flex-fill">
                    <h6 className="mb-0 col-11 text-truncate d-block mw-0">{getTranslation(quizModule?.moduleDetail?.title)}
                    </h6>
                    <DiffBadge diff={quizModule?.moduleDetail?.difficulty} />
                </div>
            </div>
            {/* Linear progress bar */}
            <small className="mt-3 text-muted">{t('eduquiz:units_completed', { currentUnits: quizModule.moduleDetail.userModule.userModuleDetail.unitCompleted, totalUnits: quizModule.moduleDetail.unitIds.length })}</small>
            <div className="progress position-relative">
                <div className="progress-bar text-dark" role="progressbar" style={{ width: (quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion) + '%', backgroundColor: color }} aria-valuenow={quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion} aria-valuemin={0} aria-valuemax={100}>
                </div>
                <div className="position-absolute w-100 fw-bold text-white text-shadowed text-right px-2" style={{ textAlign: "end" }}>
                    {Math.trunc(quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion)}%
                </div>
            </div>
            {/*                                             <button className="btn btn-outline-light btn-block w-100 rounded-pill mt-3 EduQuizMainPageButtonInactive" onClick={() => startModule(quizModule)} style={{ color: color, border: "3px solid " + color }}>{t(quizModule.moduleDetail.userModule.userModuleDetail.percentageCompletion !== 0 ? 'common:continue' : 'common:start')}</button>
*/}                                        </div>
    </div>
    );
}
export default ProgressionPage