import cloneDeep from "clone-deep";
import { Playlist, PlaylistDetail, PlaylistHistory, UserVideo, Video, VideoDetail, VideoHistory } from "models/videoModels";
import { ApiService } from "services/apiService";
import { create } from "./createStore";
import { useUserStore } from "./userStore";

export interface VideoStore {
    videos: Video[] | null;
    playlists: Playlist[] | null;
    userVideo: UserVideo | null;
    initializing: boolean;
    updatingVideoHistory: boolean;
    initVideoStore: () => Promise<void>;
    createVideo: (videoToCreate: VideoDetail) => Promise<void>;
    updateVideo: (videoToEdit: Video) => Promise<void>;
    deleteVideo: (videoId: string) => Promise<void>;
    createInitialVideos: () => Promise<void>;
    deletePlaylist: (playlistId: string) => Promise<void>;
    updatePlaylist: (playlist: Playlist) => Promise<void>;
    createPlaylist: (playlist: PlaylistDetail) => Promise<void>;
    updateVideoHistory: (videoHistory: VideoHistory) => Promise<void>;
    updatePlaylistHistory: (playlistHistory: PlaylistHistory) => Promise<void>;
    getVideoHistory: (videoId: string) => VideoHistory;
    getPlaylistHistory: (playlistId: string) => PlaylistHistory;
    getAllUserVideos: () => Promise<UserVideo[]>;
    usersVideos: UserVideo[];
}

export const useVideoStore = create<VideoStore>()((set, get, __) => ({
    initializing: false,
    updatingVideoHistory: false,
    videos: null,
    playlists: null,
    userVideo: null,
    usersVideos: [],
    initVideoStore: async () => {
        if (get().initializing) return;
        set({ initializing: true });
        try {
            /*             let response = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLdZa2wg1T4GSQ7eBwZIeTSLIRsSIPAtEn&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
                        //get response json
                        let videos = await response.json();
                        let response2 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL8bID_wYx3to15karoRQioNM0TUQaVmTQ&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
                        let videos2 = await response2.json();
                        let response3 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgGy8lH1AKT9JAQtHAvYmIAq&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
                        let videos3 = await response3.json();
                        let response4 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgEnnelCQKkqEvPNUbLmCItb&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
                        let videos4 = await response4.json();
                        let response5 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgGYuQ9Qa6frQ0Uc6XjW-e9T&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
                        let videos5 = await response5.json();
                        // if any video has no thumbnails in the snippet or thumbnails.standard does not exist, remove it from the array
                        videos.items = videos.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard); */
            const playlists = await ApiService.playlist.search();
            let userVideos = await ApiService.userVideos.search(useUserStore.getState().user?.userProfileId ?? "");
            if (!userVideos || userVideos.length === 0) {
                await ApiService.userVideos.create({
                    companyId: useUserStore.getState().user?.userProfileDetail.companyId ?? "",
                    dateLastUpdate: new Date().toISOString(),
                    playlistHistories: [],
                    userProfileId: useUserStore.getState().user?.userProfileId ?? "",
                    userVideoCreationDate: new Date().toISOString(),
                    videoHistories: []
                });
                userVideos = await ApiService.userVideos.search();
            } else {
                /* console.log("userVideos found", userVideos); */
            }

            set({
                playlists: playlists ?? [],
                userVideo: userVideos[0] ?? null,
                /*                 videos: videos ?? [] */
            });
        } catch (e) {
            console.log('Error initializing video store', e);
        } finally {
            set({ initializing: false });
        }
    },
    getVideoHistory: (videoId: string) => {
        const videoHistory = get().userVideo?.userVideoDetail.videoHistories.find((videoHistory) => videoHistory.videoId === videoId);
        return videoHistory ?? {
            alreadyCompleted: false,
            percentage: 0,
            seconds: 0,
            videoId,
            watching: false,
            totalWatchTime: 0
        };
    },
    getPlaylistHistory: (playlistId: string) => {
        const playlistHistory = get().userVideo?.userVideoDetail.playlistHistories.find((playlistHistory) => playlistHistory.playlistId === playlistId);
        return playlistHistory ?? {
            alreadyCompleted: false,
            percentage: 0,
            playlistId,
            seconds: 0,
            videoIds: [],
            watching: false
        };
    },
    getAllUserVideos: async () => {
        const videoHistories = await ApiService.userVideos.search();
        set({ usersVideos: videoHistories ?? [] });
        return videoHistories ?? [];
    },
    updateVideoHistory: async (videoHistory) => {
        if (get().updatingVideoHistory) return;
        set({ updatingVideoHistory: true });
        try {
            const userVideo = get().userVideo;
            if (userVideo) {
                const videoIndex = userVideo.userVideoDetail.videoHistories.findIndex((vh) => vh.videoId === videoHistory.videoId);
                if (videoIndex !== -1) {
                    userVideo.userVideoDetail.videoHistories[videoIndex] = videoHistory;
                } else {
                    userVideo.userVideoDetail.videoHistories.push(videoHistory);
                }
                userVideo.userVideoDetail.videoHistories = [videoHistory, ...userVideo.userVideoDetail.videoHistories.filter((vh) => vh.videoId !== videoHistory.videoId)];
                await ApiService.userVideos.update(userVideo);
                set({ userVideo: { ...userVideo } });
            }
        } catch (e) {
            console.log('Error updating video history', e);
        }
        finally {
            set({ updatingVideoHistory: false });
        }
    },
    updatePlaylistHistory: async (playlistHistory: any) => {
        const userVideos = get().userVideo;
        if (userVideos) {
            const playlistIndex = userVideos.userVideoDetail.playlistHistories.findIndex((ph) => playlistHistory.playlistId === ph.playlistId);
            if (playlistIndex !== -1) {
                userVideos.userVideoDetail.playlistHistories = [playlistHistory, ...userVideos.userVideoDetail.playlistHistories.filter((ph) => ph.playlistId !== playlistHistory.playlistId)];
                await ApiService.userVideos.update(userVideos);
                set({ userVideo: cloneDeep(userVideos) });
            } else {
                userVideos.userVideoDetail.playlistHistories.push(playlistHistory);
                await ApiService.userVideos.update(userVideos);
                set({ userVideo: cloneDeep(userVideos) });
            }
        } else {
            alert("no user video found")
        }
    },
    createInitialVideos: async () => {
        let response = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLdZa2wg1T4GSQ7eBwZIeTSLIRsSIPAtEn&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        //get response json
        let videos = await response.json();
        let response2 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL8bID_wYx3to15karoRQioNM0TUQaVmTQ&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        let videos2 = await response2.json();
        let response3 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgGy8lH1AKT9JAQtHAvYmIAq&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        let videos3 = await response3.json();
        let response4 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgEnnelCQKkqEvPNUbLmCItb&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        let videos4 = await response4.json();
        let response5 = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLSWqOYiH0DgGYuQ9Qa6frQ0Uc6XjW-e9T&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        let videos5 = await response5.json();
        // if any video has no thumbnails in the snippet or thumbnails.standard does not exist, remove it from the array
        videos.items = videos.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        videos2.items = videos2.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        videos3.items = videos3.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        videos4.items = videos4.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        videos5.items = videos5.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        let playlistTitles = [
            "rizzo",
            "elsa",
            "criptovalute",
            "redditi",
            "coaching"
        ];
        // for each set of videos, create a new video for each video in the set
        for (let i = 0; i < videos.items.length; i++) {
            let video = videos.items[i];
            let videoDetail: VideoDetail = {
                category: [],
                minutes: video.contentDetails.duration,

                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistTitles[0],
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
        for (let i = 0; i < videos2.items.length; i++) {
            let video = videos2.items[i];
            let videoDetail: VideoDetail = {
                category: [],
                minutes: video.contentDetails.duration,

                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistTitles[1],
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
        for (let i = 0; i < videos3.items.length; i++) {
            let video = videos3.items[i];
            let videoDetail: VideoDetail = {
                category: [],
                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                minutes: video.contentDetails.duration,
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistTitles[2],
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
        for (let i = 0; i < videos4.items.length; i++) {
            let video = videos4.items[i];
            let videoDetail: VideoDetail = {
                minutes: video.contentDetails.duration,

                category: [],
                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistTitles[3],
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
        for (let i = 0; i < videos5.items.length; i++) {
            let video = videos5.items[i];
            let videoDetail: VideoDetail = {
                category: [],
                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistTitles[4],
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                minutes: video.contentDetails.duration,
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
        // create video objects
    },
    uploadYoutubeVideoPlaylist: async (playlistId: string, playlistName: string) => {
        let response = await fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=" + playlistId + "&maxResults=50&key=AIzaSyDcctlCCEIun2w3_Nrf0cpR_lrmf0OjNHA");
        //get response json
        let videos = await response.json();
        // if any video has no thumbnails in the snippet or thumbnails.standard does not exist, remove it from the array
        videos.items = videos.items.filter((video: any) => video.snippet.thumbnails && video.snippet.thumbnails.standard);
        // upload videos to the database
        for (let i = 0; i < videos.items.length; i++) {
            let video = videos.items[i];
            let videoDetail: VideoDetail = {
                category: [],
                dateLastUpdate: new Date().toISOString(),
                description: { en: video.snippet.description },
                documentId: "",
                expirationDate: null,
                metadata: { en: video.snippet.title, it: video.snippet.title },
                order: i,
                playlist: playlistName,
                title: {
                    en: video.snippet.title,
                    it: video.snippet.title
                },
                type: "youtube",
                minutes: video.contentDetails.duration,
                uri: video.snippet.resourceId.videoId,
                videoCreationDate: new Date().toISOString(),
                thumbnail: video.snippet.thumbnails.standard.url
            };
            await ApiService.video.create(videoDetail);
        }
    },
    createVideo: async (videoToCreate: VideoDetail) => {
        try {
            const newVideo = await ApiService.video.create(videoToCreate);
            if (newVideo) {
                // add it to playlist
                const playlist = get().playlists?.find((playlist) => playlist.playlistId === videoToCreate.playlist);
                if (playlist) {
                    playlist.playlistDetail.videoIds.push(newVideo.videoId);
                    await get().updatePlaylist(playlist);
                }
            }
        } catch (e) {
            console.log('Error creating video', e);
            throw new Error("Failed to create video");
        }
    },
    updateVideo: async (videoToEdit: Video) => {
        try {
            await ApiService.video.update(videoToEdit);
            // then update it in playlist, by swapping the old video with the new one
            const playlist = get().playlists?.find((playlist) => playlist.playlistId === videoToEdit.videoDetail.playlist);
            if (playlist) {
                const videoIndex = playlist.playlistDetail.videos.findIndex((vid) => vid.videoId === videoToEdit.videoId);
                if (videoIndex !== -1) {
                    playlist.playlistDetail.videos[videoIndex] = videoToEdit;
                    set({ playlists: cloneDeep(get().playlists) });
                } else {
                    alert("no video found in playlist")
                }
            } else {
                alert("no playlist found for video")
            }

        } catch (e) {
            console.log('Error editing video', e);
            throw new Error("Failed to edit video");
        }
    },
    deleteVideo: async (videoId: string) => {
        try {
            await ApiService.video.delete(videoId);
            //then remove it from playlist
            const playlist = get().playlists?.find((playlist) => playlist.playlistDetail.videoIds.includes(videoId));
            if (playlist) {
                playlist.playlistDetail.videoIds = playlist.playlistDetail.videoIds.filter((id) => id !== videoId);
                await get().updatePlaylist(playlist);
            } else {
                console.log("no playlist found for video");
            }
        } catch (e) {
            console.log('Error deleting video', e);
            throw new Error("Failed to delete video");
        }
    },
    createPlaylist: async (playlist: PlaylistDetail) => {
        try {
            const newplaylist = await ApiService.playlist.create(playlist);
            if (newplaylist) {
                set({
                    playlists: [newplaylist, ...get().playlists!]
                });
            }
        } catch (e) {
            console.log('Error creating playlist', e);
            throw new Error("Failed to create playlist");
        }
    },
    updatePlaylist: async (newplaylist: Playlist) => {
        try {
            await ApiService.playlist.update(newplaylist);
            const updatedPlaylist = await ApiService.playlist.get(newplaylist.playlistId)
            // ensure state update
            set({
                playlists: [...(get().playlists ?? []).map((playlist) => playlist.playlistId === updatedPlaylist.playlistId ? updatedPlaylist : playlist)]
            });
        } catch (e) {
            console.log('Error editing playlist', e);
            throw new Error("Failed to edit playlist");
        }
    },
    deletePlaylist: async (playlistId: string) => {
        try {
            await ApiService.playlist.delete(playlistId);
            set({
                playlists: [...(get().playlists ?? []).filter((playlist) => playlist.playlistId !== playlistId)]
            });
        } catch (e) {
            console.log('Error deleting playlist', e);
            throw new Error("Failed to delete playlist");
        }
    }
}));
