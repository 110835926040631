import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useLearningMaterialsManagerStore } from "./learningMaterialsManagerStore";
import { useDocumentStore } from "./documentStore";
import { useUserStore } from "./userStore";
import { generateImageUrl } from "utils/functions";

export interface LearningMaterial {
    id: string;
    title: string;
    multiLanguageTitle?: {
        [key: string]: string;
    };
    description: {
        [key: string]: string;
    };
    url: string;
    category: string;
    img: string;
    icon: string;
    format?: string;
    storagePath?: string;
    companyId?: string;
}


const initialLearningMaterials = [
    {
        "id": "1",
        "title": {
            "en": "What does the Head of Household Insurance cover?",
            "it": "Cosa copre l'Assicurazione del Capofamiglia?"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/what-does-the-head-of-household-insurance-cover",
            "it": "https://blog.funnifin.com/it-it/blog/cosa-copre-lassicurazione-del-capofamiglia"
        },
        "category": {
            "en": "Home Insurance",
            "it": "Assicurazionecasa"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-08-05%2013.25.12%20-%20A%20warm%20family%20scene%20depicting%20parents%20and%20children%20in%20a%20cozy%20living%20room%2c%20symbolizing%20daily%20protection%20and%20safety.%20The%20background%20shows%20a%20modern%2c%20well.webp",
        "icon": ""
    },
    {
        "id": "2",
        "title": {
            "en": "Starting to Invest",
            "it": "Iniziare a investire"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/starting-to-invest",
            "it": "https://blog.funnifin.com/it-it/blog/iniziare-a-investire"
        },
        "category": {
            "en": "Investment",
            "it": "Investimento"
        },
        "img": "https://blog.funnifin.com/hubfs/morgan-housel-PcDGGex9-jA-unsplash.jpg",
        "icon": ""
    },
    {
        "id": "3",
        "title": {
            "en": "Why Invest",
            "it": "Perché investire"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/why-invest",
            "it": "https://blog.funnifin.com/it-it/blog/perch%C3%A9-investire-i-propri-soldi-i-benefici-dellinvestimento-finanziario"
        },
        "category": {
            "en": "Investment",
            "it": "Investimento"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-31%2015.28.22%20-%20A%20stylized%20image%20of%20a%20plant%20growing%20from%20a%20pile%20of%20coins.%20The%20coins%20are%20artistically%20arranged%2c%20and%20from%20the%20center%2c%20a%20stylized%20plant%20with%20leaves%20and%20s.webp",
        "icon": ""
    },
    {
        "id": "4",
        "title": {
            "en": "Financial Planning",
            "it": "La pianificazione finanziaria"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/financial-planning",
            "it": "https://blog.funnifin.com/it-it/blog/la-pianificazione-finanziaria-come-gestire-al-meglio-le-tue-finanze"
        },
        "category": {
            "en": "Financial Planning",
            "it": "Pianificazione"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-31%2015.00.47%20-%20A%20serene%20and%20tranquil%20sea%20scene.%20The%20water%20is%20calm%2c%20reflecting%20the%20soft%20colors%20of%20a%20clear%20sky%20at%20sunset.%20Gentle%20waves%20ripple%20across%20the%20surface%2c%20creat.webp",
        "icon": ""
    },
    {
        "id": "5",
        "title": {
            "en": "The 50/30/20 Rule",
            "it": "La regola del 50/30/20"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/the-50-30-20-rule",
            "it": "https://blog.funnifin.com/it-it/blog/la-regola-del-50/30/20"
        },
        "category": {
            "en": "Budget Management",
            "it": "Gestione spese"
        },
        "img": "https://blog.funnifin.com/hubfs/john-vid-SvM0d261l38-unsplash.jpg",
        "icon": ""
    },
    {
        "id": "6",
        "title": {
            "en": "Reducing Expenses",
            "it": "Ridurre le spese"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/reducing-expenses",
            "it": "https://blog.funnifin.com/it-it/blog/ridurre-le-spese"
        },
        "category": {
            "en": "Cost Saving",
            "it": "Risparmio"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-30%2011.27.45%20-%20A%20simple%20illustration%20of%20saving%20money%20featuring%20a%20single%20piggy%20bank%20with%20euro%20coins%20being%20inserted.%20The%20background%20is%20minimalistic%20with%20a%20few%20euro%20coi.webp",
        "icon": ""
    },
    {
        "id": "7",
        "title": {
            "en": "How to Save on Household Expenses",
            "it": "Come risparmiare sulle spese di casa"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/how-to-save-on-household-expenses",
            "it": "https://blog.funnifin.com/it-it/blog/come-risparmiare-sulle-spese-di-casa"
        },
        "category": {
            "en": "Home Savings",
            "it": "Casa"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-30%2011.05.10%20-%20A%20sustainable%2c%20eco-friendly%20house.%20The%20house%20features%20energy-efficient%20appliances%20and%20LED%20lights%2c%20a%20water-saving%20showerhead%2c%20and%20a%20programmable%20thermo.webp",
        "icon": ""
    },
    {
        "id": "8",
        "title": {
            "en": "Google: An Example of Excellent Corporate Welfare",
            "it": "Google: Un Esempio di Welfare Aziendale di Eccellenza"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/google-excellent-corporate-welfare",
            "it": "https://blog.funnifin.com/it-it/blog/investire-nel-benessere-finanziario-dei-dipendenti-un-investimento-per-il-successo-aziendale-0-0"
        },
        "category": {
            "en": "Corporate Welfare",
            "it": "Welfare aziendale"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-21%2003.00.01%20-%20An%20innovative%20workspace%20at%20Google%20headquarters%20featuring%20employees%20collaborating%20in%20a%20casual%20setting.%20There%20are%20large%20windows%20letting%20in%20natural%20light.webp",
        "icon": ""
    },
    {
        "id": "9",
        "title": {
            "en": "The Importance of Mental Health at Work: A Priority for Companies",
            "it": "L'Importanza della Salute Mentale sul Lavoro: Priorità per le Aziende"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/importance-of-mental-health-at-work",
            "it": "https://blog.funnifin.com/it-it/blog/investire-nel-benessere-finanziario-dei-dipendenti-un-investimento-per-il-successo-aziendale-0"
        },
        "category": {
            "en": "Mental Health",
            "it": "Salute Mentale"
        },
        "img": "https://blog.funnifin.com/hubfs/DALL%C2%B7E%202024-07-21%2002.30.14%20-%20A%20diverse%20group%20of%20employees%20happily%20collaborating%20in%20a%20modern%2c%20well-lit%20office%20space.%20They%20are%20gathered%20around%20a%20table%20with%20laptops%2c%20notebooks%2c%20and%20c.webp",
        "icon": ""
    },
    {
        "id": "10",
        "title": {
            "en": "Investing in Employee Financial Well-being: A Key to Business Success",
            "it": "Investire nel benessere finanziario dei dipendenti: un investimento per il successo aziendale"
        },
        "url": {
            "en": "https://blog.funnifin.com/en/blog/investing-in-employee-financial-wellbeing",
            "it": "https://blog.funnifin.com/it-it/blog/investire-nel-benessere-finanziario-dei-dipendenti-un-investimento-per-il-successo-aziendale"
        },
        "category": {
            "en": "Employee Benefits",
            "it": "Benessere finanziario"
        },
        "img": "https://blog.funnifin.com/hubfs/Screenshot%202024-07-19%20alle%2022.26.21.png",
        "icon": ""
    }
];
export interface LearningMaterialsStore {
    learningMaterialsList: LearningMaterial[] | null;
    initLearningMaterialsStore: () => Promise<void>;
    createInitialLearningMaterials: () => Promise<void>;
}

export const useLearningMaterialsStore = create<LearningMaterialsStore>()((set) => ({
    learningMaterialsList: null,
    initLearningMaterialsStore: async () => {
        try {
            const documents = await ApiService.documents.search({ type: "learning_material" });
            const learningMaterials = documents.map((doc: any) => ({
                id: doc.documentId,
                multiLanguageTitle: doc.document.multiLanguageTitle,
                title: doc.document.title,
                url: doc.document.url,
                category: doc.document.category,
                img: doc.document.image,
                icon: doc.document.icon,
                storagePath: doc.document.storagePath,
                companyId: doc.document.companyId
            }));
            set({
                learningMaterialsList: learningMaterials.sort((a: any, b: any) => {
                    return 0.5 - Math.random();
                })
            });
        } catch (e) {
            console.log('Error fetching learning materials', e);
        }
    },
    createInitialLearningMaterials: async () => {
        initialLearningMaterials.map((l) => ({
            ...l,
            url: l.url.it,
            category: l.category.en,
            title: l.title.it,
            description: {
                en: "Description",
                it: "Descrizione"
            },
            multiLanguageTitle: {
                en: l.title.en,
                it: l.title.it
            }
        })).forEach(async (material) => {
            const file = await fetch(material.img)
                .then(res => res.blob())
                .then(blob => new File([blob], "image.jpg", { type: blob.type }));
            // uplaod the image and add it to the material
            let newImage = null;

            newImage = await useDocumentStore.getState().uploadDocument({
                format: file.type,
                title: file.name,
                ownerId: useUserStore.getState().user?.userProfileId ?? "",
                type: "learning_material_image",
                file: file as File,
            });
            if (newImage != null) {
                material.img = generateImageUrl(newImage)!;
            }
            useLearningMaterialsManagerStore.getState().uploadLearningMaterial(material);
        });
    }
}));

