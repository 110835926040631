import CircleButton from "components/CircleButton";
import HeaderAdmin from "components/HeaderAdmin"
import Loader from "components/Loader";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Accordion, Badge, Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { useDocumentStore } from "stores/documentStore";
import VideoEditor from "./VideoEditor";
import { useI18nStore } from "stores/i18nStore";
import { generateImageUrl, useTitle } from "utils/functions";
import { useVideoStore } from "stores/videoStore";
import { Playlist, PlaylistDetail, Video, VideoDetail } from "models/videoModels";
import PlaylistEditor from "./PlaylistEditor";
import SectionTitle from "components/SectionTitle";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";

const videoInitialData: VideoDetail = {
    category: [],
    dateLastUpdate: new Date().toISOString(),
    description: {},
    documentId: "",
    expirationDate: null,
    metadata: {
        "it": "",
        "en": "",
    },
    order: 0,
    playlist: "",
    title: {
        "it": "",
        "en": "",
    },
    type: "",
    uri: "",
    videoCreationDate: new Date().toISOString(),
    thumbnail: "",

    minutes: 0
};

const playlistInitialData = {
    category: [],
    dateLastUpdate: new Date().toISOString(),
    description: {},
    documentId: "",
    expirationDate: null,
    metadata: {
        "it": "",
        "en": "",
    },
    order: 0,
    title: {
        "it": "",
        "en": "",
    },
    type: "",
    videos: [],
    videoIds: [],
    videoCreationDate: new Date().toISOString(),
    thumbnail: "",
};

function VideoManagerPage() {
    const playlists = useVideoStore(state => state.playlists);
    const { t } = useTranslation();
    const [showCreatePlaylist, setShowCreatePlaylist] = React.useState(false);
    const [newPlaylist, setNewPlaylist] = React.useState<PlaylistDetail>(playlistInitialData);
    const [thumbnailPlaylist, setThumbnailPlaylist] = useState<File | null>(null);
    useTitle(t('main_menu:video_manager'));
    React.useEffect(() => {
        if (playlists === null) {
            useVideoStore.getState().initVideoStore();
        }
    }, [playlists]);

    return (
        <section className='EduQuizPage d-flex flex-column'>
            <HeaderWidgetPortal>
                <CircleButton className='ms-auto' iconClassName={"fas fa-plus"} onClick={
                    () => {
                        setShowCreatePlaylist(true);
                    }
                } />
            </HeaderWidgetPortal>
            {
                playlists === null ? <Loader /> :
                    playlists.length === 0 ? <div className='text-center'>{t('video_manager:no_playlists')}</div> :
                        <div className=' mb-4 card'>
                            <div className="card-body d-flex flex-column">
                                <ul className="list-group list-group-flush">
                                    <Accordion defaultActiveKey="0">
                                        {Array.isArray(playlists) && playlists.map((playlist) => <PlaylistTile key={playlist.playlistId} playlist={playlist} />)}
                                    </Accordion>
                                </ul>
                            </div>
                        </div>
            }
            <Modal id="createPlaylistModal" onHide={() => setShowCreatePlaylist(false)} show={showCreatePlaylist} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('video_manager:create_playlist')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <PlaylistEditor playlist={newPlaylist} handlePlaylistChange={(playlist) => setNewPlaylist(playlist)} thumbnail={thumbnailPlaylist} setPlaylistThumbnail={setThumbnailPlaylist} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={async () => {
                        setShowCreatePlaylist(false);
                        toast.loading(t('common:creating'));
                        try {
                            if (thumbnailPlaylist != null) {
                                const logoDocument = await useDocumentStore.getState().uploadDocument({
                                    format: "image",
                                    title: "Playlist cover",
                                    ownerId: "",
                                    type: "playlistCover",
                                    file: thumbnailPlaylist as File
                                });
                                await useVideoStore.getState().createPlaylist({
                                    ...newPlaylist,
                                    thumbnail: generateImageUrl(logoDocument!)!
                                });
                                toast.success(t('common:created'));
                            } else {
                                await useVideoStore.getState().createPlaylist(newPlaylist);
                                toast.success(t('common:created'));
                            }
                            setThumbnailPlaylist(null);
                            setNewPlaylist(playlistInitialData);
                        }
                        catch (e) {
                            toast.error(t('video_manager:videoCreateError'));
                        }
                        toast.dismiss();
                    }
                    }>{t('common:save')}</button>
                </ModalFooter>
            </Modal>

        </section>
    )
}

/* -------------------------------------------------------------------------- */
/*                            Video List Tile                                 */
/* -------------------------------------------------------------------------- */

function VideoListTile(props: { video: Video }) {
    const video = props.video;
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [thumbnail, setThumbnail] = useState<File | null>(null); const uploadDocument = useDocumentStore(state => state.uploadDocument);

    useEffect(() => {
        setVideoEdit(video.videoDetail);
    }, [video]);
    const getTranslation = useI18nStore(state => state.getTranslation);

    const [videoEdit, setVideoEdit] = useState(video.videoDetail);

    if (!video) return null;

    return (
        <>
            <li className="VideoListTile list-group-item d-flex flex-row align-items-center ps-0" >
                <img src={video.videoDetail?.thumbnail} alt="" className="rounded-2 object-fit-cover me-3" style={{ width: "240px", height: "120px" }} />
                <div className='mb-0 h6 d-flex flex-column align-items-start' style={{ width: "250px" }}>
                    <strong>{getTranslation(video.videoDetail.title)}</strong>
                    <span className="small text-muted" dangerouslySetInnerHTML={{ __html: getTranslation(video.videoDetail.description) }}></span>
                    {video.videoDetail.category.length > 0 && <div className="border-top mt-2 pt-2 d-flex flex-column" key={video.videoId}>
                        {video.videoDetail.category.map((cat, i) => <small key={video.videoId + "-" + cat} className="text-muted">-{cat}</small>)}
                    </div>}
                </div>
                <Badge bg="primary-subtle" className="ms-auto text-uppercase text-primary">{video.videoDetail.minutes} {t('common:minutes')}
                </Badge>

                <div className='ms-auto d-flex align-items-center'>
                    <button className="btn btn-sm btn-outline-primary"
                        onClick={() => {
                            setShowEditModal(true);
                        }}
                    ><i className="fa-duotone fa-pencil"></i></button>
                    <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => {
                        if (window.confirm(t('video_manager:delete_video_confirm'))) {
                            toast.promise(useVideoStore.getState().deleteVideo(video.videoId), {
                                loading: t('common:deleting'),
                                success: t('common:deleted'),
                                error: (e) => t('common:error') + e.message
                            });
                        }
                    }}><i className="fa-duotone fa-trash"></i></button>
                </div>
            </li>
            {/* EDIT MODAL */}
            <Modal id="editVideoModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('video_manager:edit_video')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <VideoEditor video={videoEdit} handleVideoChange={(video) => {
                        setVideoEdit(video)
                    }}
                        thumbnail={thumbnail} setVideoThumbnail={setThumbnail} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={async () => {
                        setShowEditModal(false);
                        try {
                            let logoDocument;
                            if (thumbnail != null) {
                                logoDocument = await uploadDocument({
                                    format: "image",
                                    title: "Company logo",
                                    ownerId: "",
                                    type: "companyLogo",
                                    file: thumbnail as File
                                });
                            }
                            toast.promise(useVideoStore.getState().updateVideo({
                                videoId: video.videoId,
                                videoDetail: {
                                    ...videoEdit,
                                    thumbnail: logoDocument ? (generateImageUrl(logoDocument) ?? "") : (videoEdit.thumbnail ?? "")
                                }
                            }), {
                                loading: t('common:loading'),
                                success: t('common:saved'),
                                error: (e) => t('common:error') + e.message
                            });
                            toast.dismiss();
                        }
                        catch (e) {
                            toast.dismiss();
                            toast.error(t('common:error'));
                        }
                    }
                    }>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

/* -------------------------------------------------------------------------- */
/*                            Playlist container                              */
/* -------------------------------------------------------------------------- */

function PlaylistTile({ playlist }: { playlist: Playlist }) {
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [thumbnail, setThumbnail] = useState<File | null>(null); const uploadDocument = useDocumentStore(state => state.uploadDocument);
    const getTranslation = useI18nStore(state => state.getTranslation);
    const [showCreateVideo, setShowCreateVideo] = React.useState(false);
    const [newVideo, setNewVideo] = React.useState<VideoDetail>(videoInitialData);
    const [thumbnailVideo, setThumbnailVideo] = useState<File | null>(null);
    const [playlistEdit, setPlaylistEdit] = useState<PlaylistDetail>(playlist.playlistDetail);
/*     const userPlaylistHistory = useVideoStore.getState().getPlaylistHistory(playlist.playlistId);
 */    useEffect(() => {
        setPlaylistEdit(playlist.playlistDetail);
    }, [playlist]);
    return (<>
        <Accordion.Item eventKey={playlist.playlistId}>
            <Accordion.Header className="border-0 py-2">
                <div className="PlaylistTile d-flex flex-column ps-0 w-100" >
                    <div className="d-flex w-100 align-items-center">

                        <img src={playlist.playlistDetail?.thumbnail} alt="" className="rounded-2 object-fit-cover me-3" style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain"
                        }} />
                        <div className='mb-0 h6 d-flex flex-column align-items-start' >
                            <strong className="text-body-emphasis">{getTranslation(playlist.playlistDetail.title)}</strong>
                            {/* author */}
                            <small className="font-fancy">
                                by {playlist.playlistDetail.metadata.it}
                            </small>
                            <span className="small text-muted mt-2 mb-0" dangerouslySetInnerHTML={{ __html: getTranslation(playlist.playlistDetail.description) }}></span>
                            {playlist.playlistDetail.category.length > 0 && <div className="border-top pt-2 d-flex flex-column">
                                {playlist.playlistDetail.category.map((cat, i) => <small key={playlist.playlistId + "-" + cat} className="text-muted">- {cat}</small>)}
                            </div>}
                        </div>
                        <div>
                            <span>
                                Visualizzazion
                            </span>
                        </div>
                        {/*             <Badge bg="primary-subtle" className="ms-auto text-uppercase text-primary">{playlist.playlistDetail.playlist}  -  {playlist.playlistDetail.order}</Badge>
 */}
                        <div className='ms-auto d-flex align-items-center me-3' onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <button className="btn btn-sm btn-outline-primary"
                                onClick={() => {
                                    setShowEditModal(true);
                                }}
                            ><i className="fa-duotone fa-pencil"></i></button>
                            <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => {
                                if (window.confirm(t('video_manager:delete_video_confirm'))) {
                                    toast.promise(useVideoStore.getState().deletePlaylist(playlist.playlistId), {
                                        loading: t('common:deleting'),
                                        success: t('common:deleted'),
                                        error: (e) => t('common:error') + e.message
                                    });
                                }
                            }}><i className="fa-duotone fa-trash"></i></button>
                        </div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="pb-3">


                <div className="d-flex flex-column">
                    {/*                     <SectionTitle title={t('video_manager:video_list')} /> */}
                    <ul className="list-group list-group-flush">
                        {playlist.playlistDetail.videos && playlist.playlistDetail.videos.length > 0 ?
                            playlist.playlistDetail.videos.map((video) => <VideoListTile key={video.videoId} video={video} />) :
                            <div className='text-center'>{t('video_manager:no_videos')}</div>}

                        <Button variant="primary" className="mt-2" onClick={() => {
                            videoInitialData.order = playlist.playlistDetail?.videos?.length ?? 0;
                            setNewVideo(videoInitialData);
                            setShowCreateVideo(true);
                        }}>{t('video_manager:add_video_to_playlist', {
                            playlist: getTranslation(playlist.playlistDetail.title)
                        })}</Button>
                    </ul>
                </div>
            </Accordion.Body>

        </Accordion.Item>

        {/* EDIT PLAYLIST MODAL */}
        <Modal id="editPlaylistModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
            <ModalHeader closeButton>
                <ModalTitle>{t('video_manager:edit_video')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <PlaylistEditor playlist={playlistEdit} handlePlaylistChange={(playlistDet) => {
                    setPlaylistEdit({
                        ...playlistEdit,
                        ...playlistDet
                    });
                }}
                    thumbnail={thumbnail} setPlaylistThumbnail={setThumbnail} />
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" onClick={async () => {
                    setShowEditModal(false);
                    try {
                        let logoDocument;
                        if (thumbnail != null) {
                            logoDocument = await uploadDocument({
                                format: "image",
                                title: "Company logo",
                                ownerId: "",
                                type: "companyLogo",
                                file: thumbnail as File
                            });
                        }
                        console.log(playlistEdit);
                        toast.promise(useVideoStore.getState().updatePlaylist({
                            playlistId: playlist.playlistId,
                            playlistDetail: {
                                ...playlistEdit,
                                thumbnail: logoDocument ? generateImageUrl(logoDocument)! : (playlistEdit.thumbnail ?? "")
                            }
                        }), {
                            loading: t('common:loading'),
                            success: t('common:saved'),
                            error: (e) => t('common:error') + e.message
                        });
                        toast.dismiss();
                    }
                    catch (e) {
                        toast.dismiss();
                        toast.error(t('common:error'));
                    }
                }
                }>{t('common:save')}</button>
            </ModalFooter>
        </Modal>
        {/* VIDEO CREATE MODAL FOR PLAYLIST */}
        {/* VIDEO MODAL */}
        <Modal id="createVideoModal" onHide={() => setShowCreateVideo(false)} show={showCreateVideo} scrollable>
            <ModalHeader closeButton>
                <ModalTitle>{t('video_manager:add_video_to_playlist', {
                    playlist: getTranslation(playlist.playlistDetail.title)
                })}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <VideoEditor video={newVideo} handleVideoChange={setNewVideo} thumbnail={thumbnailVideo} setVideoThumbnail={setThumbnailVideo} />
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" onClick={async () => {
                    setShowCreateVideo(false);
                    toast.loading(t('common:creating'));
                    try {
                        if (thumbnailVideo != null) {
                            const logoDocument = await useDocumentStore.getState().uploadDocument({
                                format: "image",
                                title: "Video thmubnail",
                                ownerId: "",
                                type: "videoThumbnail",
                                file: thumbnailVideo as File
                            });
                            await useVideoStore.getState().createVideo({
                                ...newVideo,
                                thumbnail: generateImageUrl(logoDocument!)!,
                                playlist: playlist.playlistId
                            });
                            toast.success(t('common:created'));
                        } else {
                            await useVideoStore.getState().createVideo({
                                ...newVideo,

                            },

                            );
                            toast.success(t('common:created'));
                        }
                        setThumbnailVideo(null);
                        setNewVideo(videoInitialData);
                    }
                    catch (e) {
                        toast.error(t('video_manager:videoCreateError'));
                    }
                    toast.dismiss();
                }
                }>{t('common:save')}</button>
            </ModalFooter>
        </Modal>

    </>
    )
}
export default VideoManagerPage