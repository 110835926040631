import React from 'react'
import ForgotPasswordDialog from './forgot_password/ForgotPasswordDialog';
import LoginComponents from './login/LoginComponents';

function AuthBlock({ useModal = true }) {
    return (
        <>
            <LoginComponents useModal={useModal}></LoginComponents>
            {/* <ForgotPasswordDialog></ForgotPasswordDialog> */}
        </>
    )
}

export default AuthBlock