import React from 'react'
import { Outlet } from 'react-router-dom'
import AppThemed from 'components/AppThemed'
import { appThemes } from 'utils/config'
import PartnerLogo from 'components/PartnerLogo'

function MainLayout(props) {
    return (
        <div className='d-flex flex-column mh-100'>
            <div className="MainLayout main-layout container-fluid container-md flex-fill">
                <div className="row h-100 pt-3">
                    <div className="col-12 h-100">
                        <Outlet />
                    </div>
                </div>

            </div>
            <AppThemed themes={[appThemes.SCHOOL]}>
                <footer className="footer mt-auto py-3 bg-body-tertiary">
                    <div className="container d-flex ">
                        <PartnerLogo width="250px" className="ms-auto" />
                    </div>
                </footer>
            </AppThemed>
        </div>

    )
}

export default MainLayout   