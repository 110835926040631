import BeeWiseLandingPage from "./BeeWiseLandingPage";
import DefaultLandingPage from "./DefaultLandingPage";
import WelfareLandingPage from "./WelfareLandingPage";
import SplitLandingPage from "./SplitLandingPage";
import SchoolLandingPage from "./SchoolLandingPage";
export const landingPagesLibrary = {
        "welfare": WelfareLandingPage,
        "default": DefaultLandingPage,
        "beewise": BeeWiseLandingPage,
        "split": SplitLandingPage,
        "school": SchoolLandingPage
};