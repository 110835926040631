import { BreadcrumbIcon } from 'components/BreadcrumbIcon';
import CircleButton from 'components/CircleButton';
import ExpandingInput from 'components/ExpInput';
import Loader from 'components/Loader';
import ScrollToTop from 'components/ScrollToTop';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useI18nStore } from 'stores/i18nStore';
import { useQuizConfiguratorStore } from 'stores/quizConfiguratorStore';
import Config from 'utils/config';
import { getFlagEmoji } from 'utils/functions';

/// Shows a configurator for a specific eduquiz unit
export default function EduQuizConfiguratorPage() {
    const { unitId } = useParams();
    const quizUnit = useQuizConfiguratorStore((state) => state.quizUnit);
    const getUnit = useQuizConfiguratorStore((state) => state.getUnit);
    const getSelectedQuestion = useQuizConfiguratorStore((state) => state.getSelectedQuestion);
    const selectedQuestionIndex = useQuizConfiguratorStore((state) => state.selectedQuestionIndex);
    const changeSelectedQuestionIndex = useQuizConfiguratorStore((state) => state.changeSelectedQuestionIndex);
    const isAnswerCorrect = useQuizConfiguratorStore((state) => state.isAnswerCorrect);
    const setAnswerAsCorrect = useQuizConfiguratorStore((state) => state.setAnswerAsCorrect);
    const setAnswerAsWrong = useQuizConfiguratorStore((state) => state.setAnswerAsWrong);
    const editQuestionTitle = useQuizConfiguratorStore((state) => state.editQuestionTitle);
    const editQuestionSuggestion = useQuizConfiguratorStore((state) => state.editQuestionSuggestion);
    const save = useQuizConfiguratorStore((state) => state.save);
    const isUnitModified = useQuizConfiguratorStore((state) => state.isUnitModified);
    const addAnswer = useQuizConfiguratorStore((state) => state.addAnswer);
    const editAnswerTitle = useQuizConfiguratorStore((state) => state.editAnswerTitle);
    const addQuestion = useQuizConfiguratorStore((state) => state.addQuestion);
    const isQuestionModified = useQuizConfiguratorStore((state) => state.isQuestionModified);
    const deleteQuestion = useQuizConfiguratorStore((state) => state.deleteQuestion);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
    useEffect(() => {
        // if no unitId is provided return to the eduquiz page
        if (!unitId) {
            navigate('/admin/eduquiz');
        }
        // if the unitId is provided and the quizUnit is not initialized, we initialize it
        if (unitId && (!quizUnit || quizUnit.unitId !== unitId)) {
            console.log('⬅️ EduQuizUnitPage: useEffect: initEduquiz');
            getUnit(unitId);
        }
    }, [unitId, quizUnit, getUnit, navigate]);
    // If the quizUnit is not initialized yet, we show a loading indicator
    if (!quizUnit || (quizUnit.unitId !== unitId)) return <Loader></Loader>;
    // Determine the color of the unit 
    let color = quizUnit.unitDetail.color;
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }


    return (
        <section className='EduQuizConfiguratorPage d-flex pt-2 flex-column'>
            <ScrollToTop />
            {/* TITOLO */}
            <div className='d-flex flex-row align-items-center mb-3'>
                <CircleButton className='me-3' variant="light" onClick={() => navigate(-1)} iconClassName="fas fa-angle-left text-body" />
                <span className='fw-bold fs-4'>
                    {t('eduquiz_config:title')}
                </span>
            </div>
            {/* BREADCRUMBS */}
            <div className='d-flex align-items-center mb-3'>
                <button style={{ 'border': `2px solid ${color}`, color: color }} className='btn bg-light me-2 rounded-pill fw-bold' >
                    {getTranslation(quizUnit.unitDetail.module?.moduleDetail.argument?.argumentDetail.title)}
                </button>
                <BreadcrumbIcon />
                <h6 className='card-title fw-bold me-2'>
                    {getTranslation(quizUnit.unitDetail.module?.moduleDetail.title)}
                </h6>
                <BreadcrumbIcon />
                <h6 className='card-title fw-bold me-2'>
                    {getTranslation(quizUnit.unitDetail.title)}
                </h6>
                <button className={'ms-auto btn btn-outline-primary btn-outline-big  rounded-pill fw-bold ' + (!isUnitModified() ? "invisible" : "")} onClick={() => {
                    save();
                }}>
                    <i className='fas fa-save me-2' />
                    {t('common:save_all_changes')}
                </button>
            </div>
            {/* CONTENUTO */}
            <div className='row'>
                {/* Index card */}
                <div className='col-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5 className='card-title'>
                                {t('eduquiz_config:index')}
                            </h5>
                            <div>
                                {quizUnit.unitDetail.questions && quizUnit.unitDetail.questions.map((question, index) => (
                                    <li
                                        key={index}
                                        title={getTranslation(question.questionDetail.title.it)}
                                        className={`list-group-item mb-1 hoverable cursor-pointer d-flex justify-content-between align-items-center ${selectedQuestionIndex === index ? 'fw-bold' : ''}`}
                                        onClick={() => changeSelectedQuestionIndex(index)}
                                    >
                                        <div className=' text-truncate'>
                                            {index + 1}. {getTranslation(question.questionDetail.title)}
                                        </div>
                                        <div className='text-secondary opacity-25'>
                                            {isQuestionModified(question) && <i className='fas fa-file-pen ms-2' />}{question.isNew && <i className='fas fa-file-circle-plus ms-2' />}
                                        </div>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content card */}
                {getSelectedQuestion() && <div className='col-9'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='card-title fw-bold mb-3'>
                                    {t('common:question')} {selectedQuestionIndex + 1}
                                </h5>
                                <CircleButton variant='danger' onClick={() => {
                                    if (window.confirm(t('eduquiz_config:delete_question_confirm') + getSelectedQuestion()!.questionId + '?')) {
                                        deleteQuestion(getSelectedQuestion()!);
                                    }
                                }} iconClassName="fas fa-trash" />
                            </div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    {quizLanguages.map((language) =>
                                        <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center justify-content-center" + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                            <div>{language.toUpperCase()}</div>
                                            <div>{getFlagEmoji(language)}</div>
                                        </button>
                                    )}
                                </div>
                            </nav>
                            <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                                {quizLanguages.map((lang) => <div key={lang + "tab"} className={`tab-pane fade ${activeLanguage === lang ? 'show active' : ''}`} role="tabpanel">
                                    {/* QUESTION TITLE EDITOR */}
                                    <ExpandingInput
                                        key={getSelectedQuestion()!.questionId + 'title'}
                                        className="border-0 p-3 rounded w-100 mb-1 fw-bold"
                                        onChange={(e: any) => {
                                            editQuestionTitle(e.target.value, lang);
                                        }}
                                        defaultValue={getTranslationWithLanguage(getSelectedQuestion()!.questionDetail.title, lang)}
                                        style={{ background: '#ECEEF1' }}
                                    />
                                    <div className='d-flex flex-column'>
                                        {getSelectedQuestion()!.questionDetail.answers!.map((answer, index) => {
                                            return (
                                                <div key={getSelectedQuestion()!.questionId + ' - ' + answer.answerId} className='d-flex flex-column mt-3'>
                                                    <h6>{t('common:answer')} {index + 1}</h6>
                                                    <div className='d-flex align-items-center'>
                                                        {(isAnswerCorrect(answer.answerId) &&
                                                            <button className='btn btn-primary rounded-circle d-flex align-items-center justify-content-center rounded-circle me-2' style={{ width: '1.2rem', height: '1.75rem' }} onClick={() => setAnswerAsWrong(answer.answerId)} >
                                                                <i className='fas fa-check text-secondary text-white' />
                                                            </button>) || <button onClick={() => setAnswerAsCorrect(answer.answerId)} className='btn btn-secondary bg-light rounded-circle d-flex align-items-center justify-content-center rounded-circle me-2' style={{ width: '1.3rem', height: '1.75rem' }}>
                                                            </button>
                                                        }
                                                        <ExpandingInput
                                                            key={answer.answerId + 'title'}
                                                            defaultValue={getTranslationWithLanguage(answer.answerDetail.title, lang)}
                                                            onChange={(e: any) => {
                                                                editAnswerTitle(answer.answerId, e.target.value, lang);
                                                            }}
                                                            className={`form-check-label border-0 fw-bold cursor-pointer p-3 w-100 rounded ${isAnswerCorrect(answer.answerId) ? 'bg-success-solid text-success' : 'bg-danger-solid text-danger'}`}
                                                        />
                                                    </div>
                                                </div>);
                                        })}
                                        {/* Add ANSWER BTN */}
                                        <button className='mt-4 btn btn-outline-secondary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={addAnswer}>
                                            <i className='fas fa-plus me-2' />
                                            {t('common:add')} {t('common:answer')}
                                        </button>
                                    </div>
                                    <h6 className='mt-3'>{t('eduquiz_config:solution')}</h6>
                                    <ExpandingInput
                                        key={getSelectedQuestion()!.questionId + 'solution'}
                                        className="border-0 p-3 rounded w-100 mb-1 fw-bold"
                                        defaultValue={getTranslationWithLanguage(getSelectedQuestion()!.questionDetail.suggestionText, lang)}
                                        onChange={(e: any) => {
                                            editQuestionSuggestion(e.target.value, lang);
                                        }}
                                        style={{ background: '#ECEEF1' }}
                                    />
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 d-flex my-3 mb-5'>
                        <CircleButton className='light' variant="light" onClick={() => {
                            if (selectedQuestionIndex > 0) {
                                changeSelectedQuestionIndex(selectedQuestionIndex - 1)
                            }
                        }} iconClassName="fas fa-angle-left" />
                        <CircleButton className='ms-2 me-3' variant="light" onClick={() => {
                            if (selectedQuestionIndex < quizUnit.unitDetail.questions!.length - 1) {
                                changeSelectedQuestionIndex(selectedQuestionIndex + 1)
                            }
                        }} iconClassName="fas fa-angle-right" />

                        <div className="pagination-row d-flex justify-content-center align-items-center">
                            {quizUnit.unitDetail.questions!.map((question, index) => (
                                <button
                                    key={index}
                                    className={`pagination-circle rounded-circle me-3 ${selectedQuestionIndex === index ? 'bg-primary' : 'bg-light'}`}
                                    style={{ width: '1rem', height: '1rem', border: '1px solid #ccc', backgroundColor: selectedQuestionIndex === index ? '#007bff' : '#fff' }}
                                    onClick={() => changeSelectedQuestionIndex(index)}
                                />
                            ))}
                        </div>
                        <button className='ms-auto btn btn-outline-secondary btn-outline-big rounded-pill fw-bold me-2' onClick={() => {
                            if (quizUnit.unitDetail.questions!.length > Config.maxSlidesPerUnit - 1) {
                                toast(t('eduquiz_config:max_questions_alert', { questions: Config.maxSlidesPerUnit }), { icon: '🚫' });
                                return;
                            }
                            addQuestion();
                            toast(t('eduquiz_config:question_added'), { icon: '📋➕' });
                        }}>
                            <i className='fas fa-plus me-2' />
                            {t('common:add')} {t('common:question')}
                        </button>
                    </div>
                </div>
                }
            </div>
        </section>
    );
}