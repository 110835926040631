import React from 'react'
import Config from 'utils/config'

function AppThemed({ children, themes }: { children: React.ReactNode, themes?: string[] }) {
    if (!themes || !themes.includes(Config.appTheme)) {
        return <></>;
    }
    return (
        <>
            {children}
        </>
    )
}

export default AppThemed